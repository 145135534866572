// Standard React Components
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CloneObj } from '../../Libs/EasyFnc';

// Map Features
import { FeatureCollectionHaveFeatures } from "../../Map/mapUtil";

// App components
import StatusCodeSwitcher from "./StatusCodeSwitcher";


export const FeatureFromFeatureCollectionUsingIndex = ({ThisFeatureCollection, Child200, setThisFeature}) => {
	const { index } = useParams();
	const [StatusCode,setStatusCode] = useState(100);

	useEffect(() => {
		if (index) {
			if (FeatureCollectionHaveFeatures(ThisFeatureCollection)) {
				let ReturnFeature = CloneObj(ThisFeatureCollection.features[index]);
				ReturnFeature.properties.me_index = index;
				setThisFeature(ReturnFeature);
				setStatusCode(200);
			}
			else
			{
				setThisFeature();
				setStatusCode(100);
			}
		} else {
			setThisFeature();
			setStatusCode(400);
		}

		return () => {
			setThisFeature()
			setStatusCode(100)
		}
	}, [ThisFeatureCollection,index,setThisFeature])

	return (
		<StatusCodeSwitcher
			StatusCode = {StatusCode}
			Child200 = {Child200}
		/>
	)
}