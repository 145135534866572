// Standard React Components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// React PDF
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'

// App components
import { LoadingDataScreen } from '../../Components/PageContent/ProcessingRequestScreens';
import { ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { AppDebugger, MyAPIFetcher } from '../../App';

const url = '/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = url;

export const ViewPDFPageUsingId = ({setID}) => {
	const { id } = useParams();
	const [GetDocmanIdStatusCode, setGetDocmanIdStatusCode] = useState(100);
	const [PDFPath, setPDFPath] = useState()

	const GetDocmanId = async (DocumentID) => {
		const response = await MyAPIFetcher.get(`document/properties/docman_id/${DocumentID}`);
		if (response[0]) {
			if (response[1].data){
				var output = response[1].data.payload;
				console.log(output)

				setPDFPath({
					url: process.env.REACT_APP_JC2Docman_URL + 'action/download/' + output['id'],
					httpHeaders: { 'Token': output['token'] }
				})

				setGetDocmanIdStatusCode(200);
			} else {
				setGetDocmanIdStatusCode(400);
			}
		} else {
			AppDebugger.log('Failed to get Docman ID')
			setGetDocmanIdStatusCode(400);
		}
	}

	useEffect(() => {
		GetDocmanId(id);
		{ setID && setID(id)} //eslint-disable-line no-lone-blocks

		return () => {
			setGetDocmanIdStatusCode(100)
			{ setID && setID()} //eslint-disable-line no-lone-blocks
		}
	}, [id,setID])

	return (
		<APICallStatusCodeSwitcher
			StatusCode={GetDocmanIdStatusCode}
			Child200={
				<AllPagesPDFViewer
					Filename={PDFPath}
				/>
			}
		/>

	);
}

const AllPagesPDFViewer = ({Filename}) => {
	const [file, setFile] = useState(Filename); // eslint-disable-line no-unused-vars
	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

	function onDocumentLoadError(error) {
		console.log('doc load failed')
		console.log(error)
	}

	return (
		<div>
		<Document
			file={file}
			onLoadSuccess={onDocumentLoadSuccess}
			onLoadError={onDocumentLoadError}
			error={<ErrorScreen/>}
			loading={<LoadingDataScreen/>}
		>
			{
				Array.from(
					new Array(numPages),
					(el, index) => (
						<Page
							key={`page_${index + 1}`}
							pageNumber={index + 1}
							width = {document.getElementById('root').clientWidth*0.98}
						/>
					),
				)
			}
		</Document>
	</div>
  );
}