export class WebCompass {
	constructor() {
		this._coord_array = [];
		this._heading_array = [];
		this._direction_array = [];
		this._max_coords = 10;
		this._prev_angle = null;
	}

	UpdateWithGeolocate(geolocate){
		if (geolocate.coords.longitude & geolocate.coords.latitude){
			this.AddCoord([geolocate.coords.longitude & geolocate.coords.latitude]);
		}
		if (geolocate.coords.heading){
			this.AddHeading(geolocate.coords.heading)
		}
	}

	AddCoord(NewCoords){
		this._coord_array.push(NewCoords);
		if (this._coord_array.length > this._max_coords ){
			this._coord_array.shift();
		}
	}

	AddHeading(NewHeading){
		this._heading_array.push(NewHeading.toFixed(2))
		this._direction_array.push(this._angle2direction(NewHeading))
		if (this._heading_array.length > this._max_coords ){
			this._heading_array.shift();
		}
		if (this._direction_array.length > this._max_coords ){
			this._direction_array.shift();
		}
	}

	GetLatestCoord(){
		return this._coord_array[this._coord_array.length-1];
	}

	DumpCoords(){
		return this._coord_array
	}
	DumpHeadings(){
		return this._heading_array
	}
	DumpDirections(){
		return this._direction_array
	}

	GetAngle(){
		var output = null;
		if (this._heading_array.length !== 0){
			if (this._prev_angle === null){
				this._prev_angle = this._heading_array[0];
				output = this._heading_array[0];
				this._prev_angle = output;
			} else {
				let angle_change = Math.abs(this._heading_array[0] - this._prev_angle);
				console.log(angle_change.toFixed(0));
				if (angle_change < 120){
					output = this._heading_array[0];
					this._prev_angle = output;
				} else {
					output = this._prev_angle;
				}
			}
		}
		return output
	}

	_angle2direction(angle){
		var ReturnValue
		switch (true) {
			case (angle < 0):
				alert("less than Zero");
				ReturnValue = "negative";
				break;
			case (angle < 22.5):
				ReturnValue = 'N';
				break;
			case (angle < 67.5):
				ReturnValue = 'NE';
				break;
			case (angle < 112.5):
				ReturnValue = 'E';
				break;
			case (angle < 157.5):
				ReturnValue = 'SE';
				break;
			case (angle < 202.5):
				ReturnValue = 'S';
				break;
			case (angle < 247.5):
				ReturnValue = 'SW';
				break;
			case (angle < 337.5):
				ReturnValue = 'W';
				break;
			case (angle < 382.5):
				ReturnValue = 'NW';
				break;
			default:
				ReturnValue = 'N';
				break;
		}
		return ReturnValue
	}
}