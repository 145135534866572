// Standard React Components
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectAsync } from '../../reactHelper';


// General Functions
import { MyAPIFetcher } from '../../App';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { LoadingDataScreen } from './ProcessingRequestScreens';

export const DOMUsingAPIFetcherUsingID = ({path, Child200, setFetchedObject, setID}) => {
	const { id } = useParams();
	const [StatusCode,setStatusCode] = useState(100);

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.get(`${path}/${id}`);
		if (response[0]) {
			if (response[1].data){
				setFetchedObject(response[1].data.payload);
				setID(id)
				setStatusCode(200)
			} else {
				setStatusCode(400);
			}
		} else {
			setStatusCode(400);
		}
	}, [id]);

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {StatusCode}
				Child100 = {<LoadingDataScreen/>}
				Child200 = {Child200}
		/>
	)
}
