import axios from "axios";
import {AppDebugger} from '../App';

export class APIFetcher {
	constructor() {
		this._access_token = null
		this._access_token_expire = null
		this._refresh_token = null
		this._refresh_token_expire = null
		this._token_type = 'Bearer'
		this._user = null
		this._auth_headers = {
			'Content-Type': 'application/json',
			'Authorization': this._token_type + " " + this._access_token
		}
		this._baseURL = process.env.REACT_APP_API_URL
		this._axios_client = axios.create({
			baseURL: this._baseURL
		});
		this.LogoutFnc = () => {}
	}

	SetAccessToken(Token,Expire = null){
		this._access_token = Token
		this._access_token_expire = Expire
		this._update_headers()
	}

	SetRefreshToken(Token,Expire = null){
		this._refresh_token = Token
		this._refresh_token_expire = Expire
	}

	SetTokens(TokenObj){
		if (TokenObj.hasOwnProperty('access_token')){
			this._access_token = TokenObj['access_token']
		}
		if (TokenObj.hasOwnProperty('access_token_expire')){
      this._access_token_expire = TokenObj['access_token_expire']
    }
		if (TokenObj.hasOwnProperty('refresh_token')){
      this._refresh_token = TokenObj['refresh_token']
    }
		if (TokenObj.hasOwnProperty('refresh_token_expire')){
      this._refresh_token_expire = TokenObj['refresh_token_expire']
    }
		this._update_headers()
	}
	ClearTokens(){
		this.SetAccessToken(null,null)
		this.SetRefreshToken(null,null)
		this._update_headers()
	}

	SetLogoutFnc(InputFnc){
		this.LogoutFnc = InputFnc;
	}

	_update_headers(){
		this._auth_headers = {
			'Content-Type': 'application/json',
			'Authorization': this._token_type + " " + this._access_token
		}
	}

	DumpTokenInfo(){
		console.log(this._access_token)
		console.log(this._access_token_expire)
		console.log(this._refresh_token)
		console.log(this._refresh_token_expire)
	}

	_api_url(api_path){
		return this._baseURL + api_path
	}

	GetAPI = async (api_path) => {
		var output = null
		const [isValid,response] = await this._axios_get(api_path)
		if (isValid){
			if (response['status'] === 200){
				if (response.data.hasOwnProperty('payload')){
					output = response.data.payload
				} else {
					output = response.data
				}
			}
		}
		return output
	}

	get = async (api_path,params = {}) => {
		var AxiosOptions = {}
		AxiosOptions.params = params
		AxiosOptions.headers = this._auth_headers
		const response = await this._axios_get(api_path,AxiosOptions)
		return response
	}

	PostAPI = async (api_path,PostObj) => {
		var output = null
		const [isValid,response] = await this._axios_post(api_path,PostObj)
		if (isValid){
			if (response.data.hasOwnProperty('payload')){
				output = response.data.payload
			} else {
				output = response.data
			}
		}
		return output
	}

	post = async (api_path,PostObj) => {
		var AxiosOptions = {}
		AxiosOptions.headers = this._auth_headers
		const response = await this._axios_post(api_path,PostObj,AxiosOptions)
		return response
	}

	delete = async (api_path) => {
		var AxiosOptions = {}
		AxiosOptions.headers = this._auth_headers
		const response = await this._axios_delete(api_path,AxiosOptions)
		return response
	}

	patch = async (api_path,PostObj) => {
		var AxiosOptions = {}
		AxiosOptions.headers = this._auth_headers
		const response = await this._axios_patch(api_path,PostObj,AxiosOptions)
		return response
	}

	_handle_axios_error = (error) => {
		// Error 😨
		var output = {}
		output.status = 404;
		output.data = {success: false, detail: ""};
		if (error.response) {
				/*
				* The request was made and the server responded with a
				* status code that falls out of the range of 2xx
				*/
				output = error.response;
		} else if (error.request) {
				/*
				* The request was made but no response was received, `error.request`
				* is an instance of XMLHttpRequest in the browser and an instance
				* of http.ClientRequest in Node.js
				*/
				output.data.detail = error.request;
		} else {
				// Something happened in setting up the request and triggered an Error
				output.data.detail = error.message;
		}
		AppDebugger.error("_axios_get failed with error:");
		AppDebugger.dir(output);

		if (this._check_404(output)){
			console.log('Not authenticated');
			this._handle_logout();
		}

		return output
	}

	_check_404 = (error) => {
			let StatusCode = error.status || null;
			if (StatusCode === 401){
				return true;
			} else {
				return false;
			}
	 }

	 _handle_logout = () => {
		this._access_token = null
		this.LogoutFnc();
	 }

	_axios_get = async (api_path,options = {}) => {
		var AxiosOptions = options
		!options.hasOwnProperty('headers') && (AxiosOptions.headers = this._auth_headers)
		let output = null;
		try {
			const response = await axios.get(this._api_url(api_path),AxiosOptions);
			output = [true, response];
		} catch (error) {
			let ReturnError = this._handle_axios_error(error);
			output = [false, ReturnError];
		}

		return output;
	}

	_axios_post = async (api_path,PostObj,options = {}) => {
		var AxiosOptions = options
		!options.hasOwnProperty('headers') && (AxiosOptions.headers = this._auth_headers)
		try {
			const response = await axios.post(
				this._api_url(api_path),
				PostObj,
				AxiosOptions
			);
			return [true, response]
		} catch (error) {
			let ReturnError = this._handle_axios_error(error);
			return [false, ReturnError]
		}
	}

	_axios_delete = async (api_path,options = {}) => {
		try {
			const response = await axios.delete(this._api_url(api_path),options);
			return response;
		} catch (error) {
			let ReturnError = this._handle_axios_error(error);
			return ReturnError;
		}
	}

	_axios_patch = async (api_path,PostObj,options = {}) => {
		try {
			const response = await axios.patch(
				this._api_url(api_path),
				PostObj,
				options
			);
			return [true, response]
		} catch (error) {
			let ReturnError = this._handle_axios_error(error);
			return [false, ReturnError]
		}
	}

	CheckTokenValid = async () => {
		var isAuth = null;
		const [isValid,response] = await this._axios_get('oauth/check_auth') // eslint-disable-line no-unused-vars
		if (response.hasOwnProperty('status')){
			if (response['status'] === 200){
				isAuth = true;
			} else {
				isAuth = false;
			}
		}
		return isAuth
	}

	GetProjectConfig = async (hostname) => {
		const url = 'project/hostname/'+ hostname +'/config';
		const response = await this._axios_get(url);
		return response
	}

	async DoLogin(PostObj){
		const options = {
			headers : {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		};
		try {
			const response = await axios.post(
				this._api_url('oauth/token'),
				PostObj,
				options
			);
			return response.data
		} catch (error) {
			return null
		}
	}

	async UploadDocument(PostObj,DocmanJSON){
		const options = {
			headers : {
				'Accept': 'application/json',
				'Content-Type': 'multipart/form-data',
				'Token': DocmanJSON['token']
			}
		};
		var AxiosOptions = options
		try {
			const response = await axios.post(
				process.env.REACT_APP_JC2Docman_URL + 'upload/' + DocmanJSON['id'],
				PostObj,
				AxiosOptions
			);
			return [true, response]
		} catch (error) {
			let ReturnError = this._handle_axios_error(error);
			return [false, ReturnError]
		}
	}

	GetPayloadFromResp(RespObj){
		var output = null
		if (RespObj){
			if (typeof RespObj === 'object'){
				if ('data' in RespObj){
					if ('payload' in RespObj.data){
						output = RespObj.data.payload
					} else {
						output = RespObj.data
					}
				}
			}
		}
		return output
	}
}