// Standard React components
import React from 'react';

// Redux
import { useHistory } from 'react-router-dom';

// Material UI components
import {
	Container, Paper, Button
} from '@material-ui/core';

// General Functions
import { useAppStyles } from '../../Libs/AppStyleClasses'

// General Functions
import t from '../../Libs/localization';
import { isSetNotBlank } from '../../Libs/EasyFnc';

export const SuccessScreen = ({message,buttonMessage,buttonText,buttonPath}) => {
	const history = useHistory();
	return (
		<ScreenBase>
			<>
				{ isSetNotBlank(message) ? {message} : <>
					<h3>{t('sharedSuccess')}</h3>
					<p>{t('str_wb2ucg3n3b')}</p>
					{ !isSetNotBlank(buttonText) && (
						<p>{buttonMessage || t('str_m3fa15hess')}</p>
					)}
				</>}

				<Button
					onClick={() => history.push( isSetNotBlank(buttonPath) ? buttonPath : '/')}
					variant='contained'
					color='secondary'
					fullWidth>
					{ isSetNotBlank(buttonText) ? buttonText : t('homeTitle')}
				</Button>
			</>
		</ScreenBase>
	)
}

export const ErrorScreen = ({handleClick}) => {
	const history = useHistory();
	return (
		<ScreenBase>
			<>
				<h3>{t('errorTitle')}</h3>
				<p>{t('str_da4tmzqy27')}</p>
				<p>{t('sharedCheckInternetAndTryAgain')}</p>
				<Button
					onClick={handleClick || history.goBack}
					variant='contained'
					color='secondary'
					fullWidth>
					{t('sharedBack')}
				</Button>
			</>
		</ScreenBase>
	)
}

export const NoDataForUserScreen = ({handleClick}) => {
	const history = useHistory();
	return (
		<ScreenBase>
			<>
				<h3>{t('str_y4z0wpdwrs')}</h3>
				<p>{t('str_mkpxpc40qf')}</p>
				<p>{t('str_nc1979nvgw')}</p>
				<Button
					onClick={handleClick || history.goBack}
					variant='contained'
					color='secondary'
					fullWidth>
					{t('sharedBack')}
				</Button>
			</>
		</ScreenBase>
	)
}

const ScreenBase = ({children}) => {
	const classes = useAppStyles()
	return (
		<Container maxWidth='sm' className={classes.root}>
			<Paper style={{
				paddingTop: "10px",
				paddingBottom: "20px",
				paddingLeft: "15px",
				paddingRight: "15px",
			}}>
				{children}
			</Paper>
		</Container>
	)
}

