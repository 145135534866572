// Standard React components
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Pages
import { PrimaryPageWrapper } from '../'

// App Components
import {
	MyGreenZoneSubdivideBody, MyPropertySubdivideEditBody, BlueZoneSubdivideEditBody
} from './SubdivideFeature';
import { AddAlphaZoneBody } from './AddFeature';
import NavigateBody from './NavigateBody';
import StatusCodeSwitcher from '../../Components/PageContent/StatusCodeSwitcher';
import { EditAlphaZone, EditMyProperty, EditBlueZone } from './EditFeature';

// Map & Map Features
import {
	GetFeatureFromFeatureCollectionByIndex, Features2FeatureCollection, CreatePointFeatureFromPolygonFeature
} from '../../Map/mapUtil';
import { DOMUsingAPIFetcherUsingID } from '../../Components/PageContent/APIFetcher';


export const SubdividePageMyGreenZone = () => {
	return (
		<PrimaryPageWrapper>
			<MyGreenZoneSubdivideBody/>
		</PrimaryPageWrapper>
	);
}

export const MyPropertySubdivideEditByIndex = () => {
	const { index } = useParams();
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	const [InputFeatureCollection, setInputFeatureCollection] = useState(null);
	const [UniqueID, setUniqueID] = useState(null);
	const [ParentUniqueID, setParentUniqueID] = useState(null);

	useEffect(() => {
		let PolygonFeature = GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,index);
		if (PolygonFeature){
			let PointFeatures = CreatePointFeatureFromPolygonFeature(PolygonFeature);
			setInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			setUniqueID(PolygonFeature.properties.unique_id);
			setParentUniqueID("61222e311305fa1a0052cba8");
		}
	}, [index,ThisFeatureCollection]);

	return (
		<PrimaryPageWrapper>
			<MyPropertySubdivideEditBody
				InputFeatureCollection={InputFeatureCollection}
				UpdateID={UniqueID}
				ParentUniqueID={ParentUniqueID}
			/>
		</PrimaryPageWrapper>
	);
}

export const BlueZoneSubdivideEdit = () => {
	const { id } = useParams();
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	const [InputFeatureCollection, setInputFeatureCollection] = useState(null);
	const [UniqueID, setUniqueID] = useState(null);
	const [ParentUniqueID, setParentUniqueID] = useState(null);

	useEffect(() => {
		let PolygonFeature = GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,id);
		if (PolygonFeature){
			let PointFeatures = CreatePointFeatureFromPolygonFeature(PolygonFeature);
			setInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			setUniqueID(PolygonFeature.properties.unique_id);
			setParentUniqueID("61222e311305fa1a0052cba8");
		}
	}, [id,ThisFeatureCollection]);

	return (
		<PrimaryPageWrapper>
			<BlueZoneSubdivideEditBody
				InputFeatureCollection={InputFeatureCollection}
				UpdateID={UniqueID}
				ParentUniqueID={ParentUniqueID}
			/>
		</PrimaryPageWrapper>
	);
}

export const NavigatePage = () => {
	return (
		<PrimaryPageWrapper>
			<NavigateBody/>
		</PrimaryPageWrapper>
	);
}

export const NavigatePropertyPointPage = () => {
	const { id } = useParams();
	const StatusCode = useSelector(state => state.MyProperty.status_code);
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	const [InputFeature, setInputFeature] = useState(null)

	useEffect(() => {
		const ThisFeature = GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,id);
		if (ThisFeature){
			setInputFeature(ThisFeature);
		}
	}, [ThisFeatureCollection,id]);

	return (
		<PrimaryPageWrapper>
			<StatusCodeSwitcher
				StatusCode = {StatusCode}
				Child200 = {
					<NavigateBody
						InputFeature={InputFeature}
					/>
				}
			/>
		</PrimaryPageWrapper>
	);
}

export const AddAlphaZone = () => {
	return (
		<PrimaryPageWrapper>
			<AddAlphaZoneBody/>
		</PrimaryPageWrapper>
	);
}

export const EditAlphaZoneByIndex = () => {
	const { index } = useParams();
	const ThisFeatureCollection = useSelector(state => state.AlphaZone.FeatureCollection);
	const [InputFeatureCollection, setInputFeatureCollection] = useState(null);
	const [UniqueID, setUniqueID] = useState(null);

	useEffect(() => {
		let PolygonFeature = GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,index);
		if (PolygonFeature){
			let PointFeatures = CreatePointFeatureFromPolygonFeature(PolygonFeature);
			setInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			setUniqueID(PolygonFeature.properties.unique_id);
		}
	}, [index,ThisFeatureCollection]);

	return (
		<PrimaryPageWrapper>
			<EditAlphaZone
				InputFeatureCollection={InputFeatureCollection}
				UpdateID={UniqueID}
			/>
		</PrimaryPageWrapper>
	);
}

export const EditAlphaZoneById = () => {
	const [ThisObject,setThisObject] = useState();
	const [ID,setID] = useState();
	const [InputFeatureCollection, setInputFeatureCollection] = useState(null);

	useEffect(() => {
		if (ThisObject){
			let PointFeatures = CreatePointFeatureFromPolygonFeature(ThisObject);
			if (PointFeatures){
				setInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
		}
	}, [ThisObject]);

	return (
		<PrimaryPageWrapper>
			<DOMUsingAPIFetcherUsingID
				path='alpha/global/info'
				setFetchedObject={setThisObject}
				setID={setID}
				Child200 = {
					<EditAlphaZone
						InputFeatureCollection={InputFeatureCollection}
						UpdateID={ID}
					/>
				}
			/>
		</PrimaryPageWrapper>
	)
}

export const EditBlueZoneById = () => {
	const [ThisObject,setThisObject] = useState();
	const [ID,setID] = useState();
	const [InputFeatureCollection, setInputFeatureCollection] = useState(null);

	useEffect(() => {
		if (ThisObject){
			let PointFeatures = CreatePointFeatureFromPolygonFeature(ThisObject);
			if (PointFeatures){
				setInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
		}
	}, [ThisObject]);

	return (
		<PrimaryPageWrapper>
			<DOMUsingAPIFetcherUsingID
				path='blue/global/info'
				setFetchedObject={setThisObject}
				setID={setID}
				Child200 = {
					<EditBlueZone
						InputFeatureCollection={InputFeatureCollection}
						UpdateID={ID}
					/>
				}
			/>
		</PrimaryPageWrapper>
	)
}

export const EditMyPropertyById = () => {
	const [ThisObject,setThisObject] = useState();
	const [ID,setID] = useState();
	const [ParentUniqueID,setParentUniqueID] = useState();
	const [InputFeatureCollection, setInputFeatureCollection] = useState(null);

	useEffect(() => {
		if (ThisObject){
			let PointFeatures = CreatePointFeatureFromPolygonFeature(ThisObject);
			if (PointFeatures){
				setInputFeatureCollection(Features2FeatureCollection(PointFeatures));
				setParentUniqueID((ThisObject.relationships && ThisObject.relationships.parents) || null)
			}
		}
	}, [ThisObject]);

	return (
		<PrimaryPageWrapper>
			<DOMUsingAPIFetcherUsingID
				path='prop/global/info'
				setFetchedObject={setThisObject}
				setID={setID}
				Child200 = {
					<EditMyProperty
						InputFeatureCollection={InputFeatureCollection}
						UpdateID={ID}
						ParentUniqueID={ParentUniqueID}
					/>
				}
			/>
		</PrimaryPageWrapper>
	)
}