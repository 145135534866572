import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
	name: 'Navigate',
	initialState: {
		selectedId:null,
		TargetFeatureCollection: {
			"type":"FeatureCollection",
			"features":[]
		},
		UserFeatureCollection: {
			"type":"FeatureCollection",
			"features":[]
		},
		MarkersFeatureCollection: {
			"type":"FeatureCollection",
			"features":[]
		},
		Distance2Target: null,
		mapOptions: {
			autoZoom: true,
			autoRotate: true,
			autoFocus: true,
			updateTimestamp: null,
		}
	},
	reducers: {
		UpdateTargetFeatureCollection(state, action) {
			state.TargetFeatureCollection = action.payload;
		},
		UpdateUserFeatureCollection(state, action) {
			state.UserFeatureCollection = action.payload;
		},
		UpdateDistance2Target(state, action) {
			state.Distance2Target = action.payload;
		},
		UpdateMarkersFeatureCollection(state, action) {
			state.MarkersFeatureCollection = action.payload;
		},
		SelectTarget(state,action){
			state.selectedId = action.payload;
		},
		NextTarget(state){
			let NextTargetId = null
			if (state.selectedId !== null ){
				NextTargetId = state.selectedId + 1
			} else {
				NextTargetId = 0
			}
			if (state.MarkersFeatureCollection.features.length > NextTargetId){
				state.selectedId = NextTargetId;
			} else {
				state.selectedId = 0;
			}
		},
		UpdateMapOptions(state,action){
			if (action.payload !== {}){
				state.mapOptions.updateTimestamp = new Date();
				action.payload.forEach((item,index) => {
					console.log(item)
					console.log(index)
				});
			}
		},
	},
});

export { actions as NavigateActions };
export { reducer as NavigateReducer };
