import axios from "axios";
import mapboxgl from "mapbox-gl";


import t from '../../Libs/localization';
import './switcher.css';

export class switchBasemapControl {

	constructor(styles, defaultStyle, beforeSwitch, afterSwitch) {
		this.styles = styles;
		this.defaultStyle = defaultStyle;
		this.beforeSwitch = beforeSwitch;
		this.afterSwitch = afterSwitch;
		this.onDocumentClick = this.onDocumentClick.bind(this);
	}

	getDefaultPosition() {
		return 'top-right';
	}

	onAdd(map) {
		this.map = map;
		this.controlContainer = document.createElement('div');
		this.controlContainer.classList.add('mapboxgl-ctrl');
		this.controlContainer.classList.add('mapboxgl-ctrl-group');
		this.mapStyleContainer = document.createElement('div');
		this.styleButton = document.createElement('button');
		this.styleButton.type = 'button';
		this.styleButton.title = t('tooltipStyleSwitcher_Button');
		this.mapStyleContainer.classList.add('mapboxgl-style-list');
		for (const style of this.styles) {
			const styleElement = document.createElement('button');
			styleElement.type = 'button';
			styleElement.innerText = style.title;
			styleElement.title = t('tooltipStyleSwitcher_Dropdown');
			styleElement.classList.add(style.title.replace(/[^a-z0-9-]/gi, '_'));
			styleElement.dataset.uri = JSON.stringify(style.uri);
			styleElement.addEventListener('click', event => {
				const srcElement = event.srcElement;
				if (srcElement.classList.contains('active')) {
					return;
				}
				this.beforeSwitch();
				switchBasemap(this.map,JSON.parse(srcElement.dataset.uri));
				//this.map.setStyle(JSON.parse(srcElement.dataset.uri));
				this.afterSwitch();
				this.mapStyleContainer.style.display = 'none';
				this.styleButton.style.display = 'block';
				const elms = this.mapStyleContainer.getElementsByClassName('active');
				while (elms[0]) {
					elms[0].classList.remove('active');
				}
				srcElement.classList.add('active');
			});
			if (style.title === this.defaultStyle) {
				styleElement.classList.add('active');
				switchBasemap(this.map,style.uri);
			}
			this.mapStyleContainer.appendChild(styleElement);
		}
		this.styleButton.classList.add('mapboxgl-ctrl-icon');
		this.styleButton.classList.add('mapboxgl-style-switcher');
		this.styleButton.addEventListener('click', () => {
			this.styleButton.style.display = 'none';
			this.mapStyleContainer.style.display = 'block';
		});
		document.addEventListener('click', this.onDocumentClick);
		this.controlContainer.appendChild(this.styleButton);
		this.controlContainer.appendChild(this.mapStyleContainer);
		return this.controlContainer;
	}

	onRemove() {
		if (!this.controlContainer || !this.controlContainer.parentNode || !this.map || !this.styleButton) {
			return;
		}
		this.styleButton.removeEventListener('click', this.onDocumentClick);
		this.controlContainer.parentNode.removeChild(this.controlContainer);
		document.removeEventListener('click', this.onDocumentClick);
		this.map = undefined;
	}

	onDocumentClick(event) {
		if (this.controlContainer && !this.controlContainer.contains(event.target) && this.mapStyleContainer && this.styleButton) {
			this.mapStyleContainer.style.display = 'none';
			this.styleButton.style.display = 'block';
		}
	}
}

async function switchBasemap(map, styleURL) {
	const { data: newStyle } = await axios.get(
		`${styleURL}?access_token=${mapboxgl.accessToken}`
	);
	// ensure any sources from the current style are copied across to the new style
	const currentStyle = map.getStyle();
	newStyle.sources = Object.assign(
			{},
			currentStyle.sources,
			newStyle.sources
	);

	// find the index of where to insert our layers to retain in the new style
	let labelIndex = newStyle.layers.findIndex((el) => {
			return el.id === 'waterway-label';
	});

	// default to on top
	if (labelIndex === -1) {
			labelIndex = newStyle.layers.length;
	}
	const appLayers = currentStyle.layers.filter((el) => {
			// app layers are the layers to retain, and these are any layers which have a different source set
			return (
					el.source &&
					el.source !== 'mapbox://mapbox.satellite' &&
					el.source !== 'mapbox' &&
					el.source !== 'osm' &&
					el.source !== 'composite'
			);
	});
	newStyle.layers = [
			...newStyle.layers.slice(0, labelIndex),
			...appLayers,
			...newStyle.layers.slice(labelIndex, -1),
	];
	map.setStyle(newStyle);
}