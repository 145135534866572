import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/* eslint-disable no-unused-vars */
import MapIcon from '@material-ui/icons/Map';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FolderIcon from '@material-ui/icons/Folder';
import PageviewIcon from '@material-ui/icons/Pageview';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import BarChartIcon from '@material-ui/icons/BarChart';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import GavelIcon from '@material-ui/icons/Gavel';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
/* eslint-enable no-unused-vars */

import t from '../../Libs/localization';

export const DevelopmentMenu = () => {
	const history = useHistory();
	const access_level = useSelector(state => state.UserConfig.access_level);
	const userId = useSelector(state => state.UserConfig.user && state.UserConfig.user.user_id);
	return(<>
		<List
			subheader={
				<ListSubheader>
					{t('sharedProperty')}
				</ListSubheader>
			}>
		</List>
		<ListItem button onClick={() => history.push('/view/my_property')}>
			<ListItemIcon>
				<PageviewIcon />
			</ListItemIcon>
			<ListItemText primary={t('menu_MyProperty_view')} />
		</ListItem>

		<Divider />
		<List
			subheader={
				<ListSubheader>
					{t('sharedBlueZone')}
				</ListSubheader>
			}>
		</List>
		<ListItem button onClick={() => history.push('/view/blue')}>
			<ListItemIcon>
				<PageviewIcon />
			</ListItemIcon>
			<ListItemText primary={t('menu_Blue_view')} />
		</ListItem>

		<Divider />
		<List
			subheader={
				<ListSubheader>
					{t('sharedMyGreen')}
				</ListSubheader>
			}>
		</List>
		<ListItem button onClick={() => history.push('/view/my_green')}>
			<ListItemIcon>
				<PageviewIcon />
			</ListItemIcon>
			<ListItemText primary={t('menu_MyGreen_view')} />
		</ListItem>
		<ListItem button onClick={() => history.push('/my_green/subdivide')}>
			<ListItemIcon>
				<CallSplitIcon />
			</ListItemIcon>
			<ListItemText primary={t('sharedSubdivide')} />
		</ListItem>

		{ false && (
			<>
		<Divider />
		<List
			subheader={
				<ListSubheader>
					{t('title_ParentProperties')}
				</ListSubheader>
			}></List>
		<ListItem button onClick={() => history.push('/parent_properties/view_me')}>
				<ListItemIcon>
					<MapIcon />
				</ListItemIcon>
				<ListItemText primary={t('page_ParentProperties_ViewMe')} />
			</ListItem>
		</>
		)}
		{access_level === 20 &&
		<>
			<Divider />
			<List
				subheader={
					<ListSubheader>
						{t('menu_subheader_alpha')}
					</ListSubheader>
				}>
				<ListItem button onClick={() => history.push('/alpha/global/add')}>
					<ListItemIcon>
						<AddBoxIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu_alpha_add')} />
				</ListItem>
				<ListItem button onClick={() => history.push('/alpha/edit')}>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu_alpha_edit')} />
				</ListItem>
			</List>

			<Divider />
			<List
				subheader={
					<ListSubheader>
						{t('settingsTitle')}
					</ListSubheader>
				}>
				<ListItem button disabled={!userId} onClick={() => history.push(`/user/edit/me`)}>
					<ListItemIcon>
						<PersonIcon />
					</ListItemIcon>
					<ListItemText primary={t('settingsUser')} />
				</ListItem>
				<ListItem button onClick={() => history.push('/settings/notifications')}>
					<ListItemIcon>
						<NotificationsIcon />
					</ListItemIcon>
					<ListItemText primary={t('sharedNotifications')} />
				</ListItem>
				<ListItem button onClick={() => history.push('/settings/groups')}>
					<ListItemIcon>
						<FolderIcon />
					</ListItemIcon>
					<ListItemText primary={t('settingsGroups')} />
				</ListItem>
				<ListItem button onClick={() => history.push('/settings/attributes')}>
					<ListItemIcon>
						<StorageIcon />
					</ListItemIcon>
					<ListItemText primary={t('sharedComputedAttributes')} />
				</ListItem>
			</List>

			<Divider />
			<ListItem button onClick={() => history.push('/admin/server')}>
				<ListItemIcon>
					<StorageIcon />
				</ListItemIcon>
				<ListItemText primary={t('settingsServer')} />
			</ListItem>

			<ListItem button onClick={() => history.push('/admin/statistics')}>
				<ListItemIcon>
					<BarChartIcon />
				</ListItemIcon>
				<ListItemText primary={t('statisticsTitle')} />
			</ListItem>
		</>}
	</>)
}
