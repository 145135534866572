import { useDispatch } from 'react-redux';
import {
	UserConfigActions, AppConfigActions
} from '../ReduxStore';
import { useEffectAsync } from '../reactHelper';
import { AppDebugger, MyAPIFetcher } from '../App'

const ProjectController = () => {
	const dispatch = useDispatch();
	const domainName =  window.location.hostname;

	useEffectAsync(async () => {
		dispatch(AppConfigActions.setStatusCode(100));
		const response = await MyAPIFetcher.GetProjectConfig(domainName);
		if (response[0]) {
			if (response[1].data){
				dispatch(UserConfigActions.updateServer(response[1].data.payload));
				dispatch(AppConfigActions.updateProjectJSON(response[1].data.payload));
				dispatch(AppConfigActions.setStatusCode(200));
			}
		} else {
			AppDebugger.log('Failed to get server info');
			switch(response[1].status) {
				case 500:
					dispatch(AppConfigActions.setStatusCode(500));
					break;
				default:
					dispatch(AppConfigActions.setStatusCode(400));
			}
		}
	}, [domainName]);

	return null
}

export default ProjectController