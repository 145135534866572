import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	IconButton, Avatar
} from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { AlphaZoneActions } from '../../ReduxStore';
import ContextMenu from '../ContextMenus/AlphaZoneList';

// General Functions
import t from '../../Libs/localization';
import {AppDebugger} from '../../App';
import { useAppStyles } from '../../Libs/AppStyleClasses';

const AlphaZoneView = ({ updateTimestamp, onMenuClick }) => {
	const classes = useAppStyles();
	const dispatch = useDispatch();
	const ThisFeatureCollection = useSelector(state => state.AlphaZone.FeatureCollection);
	const status_code = useSelector(state => state.AlphaZone.status_code);
	const SelectedID = useSelector(state => state.AlphaZone.selectedId);

	var ReturnSwitch = null
	if (status_code === null) { ReturnSwitch = 0; }
	else if (status_code === 100) { ReturnSwitch = 1; }
	else if (status_code === 200)
	{
		if (ThisFeatureCollection.features.length > 0){
				ReturnSwitch = 3
			} else {
				ReturnSwitch = 2
			}
	}
	else if (status_code === 400) { ReturnSwitch = 4; }
	else {
		AppDebugger.log('Invalid value for state.AlphaZone.status_code')
	}

	return (
		<div>
			{
				ReturnSwitch === 0 ?
					<div className={classes.listMessageDiv}>
						<h3>{t('sidebar_AlphaZone_list_ReturnSwitchOutput_0')}</h3>
					</div>
				: ReturnSwitch === 1 ?
					<div className={classes.listMessageDiv}>
						<h3>{t('sidebar_AlphaZone_list_ReturnSwitchOutput_1')}</h3>
					</div>
				: ReturnSwitch === 2 ?
					<div className={classes.listMessageDiv}>
						<h3>{t('sidebar_AlphaZone_list_ReturnSwitchOutput_2')}</h3>
					</div>
				: ReturnSwitch === 3 ?
					<List className={classes.list}>
						{ThisFeatureCollection.features.map((item, index) => (
							<Fragment key={index}>
							<ListItem button key={index} onClick={() => dispatch(AlphaZoneActions.select(index))}>
								<ListItemAvatar>
									{ index === SelectedID ? (
										<Avatar style={{'backgroundColor': 'green'}}>
											<img className={classes.icon} src={`images/icon/selected.svg`} alt="" />
										</Avatar>
									):(
										<Avatar>
											<img className={classes.icon} src={`images/icon/${item.category || 'default'}.svg`} alt="" />
									 </Avatar>
									)}
								</ListItemAvatar>
								<ListItemText primary={item.properties.name} secondary={"Feature type is " + item.geometry.type} />
								<ListItemSecondaryAction>
									<IconButton onClick={(event) => onMenuClick(event.currentTarget, index)}>
										<MoreVertIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</Fragment>
						))}
					</List>
				: ReturnSwitch === 4 ?
					<h3>{t('sidebar_AlphaZone_list_ReturnSwitchOutput_4')}</h3>
				: <div></div>
			}

		</div>
	);
}

const AlphaZoneList = () => {
	return (
		<ContextMenu
			content={AlphaZoneView}
			infoPath="/alpha/me/view"
			assignPath="/alpha/me/assign"
			editPath="/alpha/global/edit/properties"
		/>
	);
}

export default AlphaZoneList;
