import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = ({ handleSubmit,isOpen,AlertConfig }) => {
    const handleClose = async (option) => {
        handleSubmit(
            option
        );
    };
    return (
        <div>
        <Dialog
            open={isOpen}
            onClose={() => handleClose(0)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{AlertConfig['DialogTitle']}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {AlertConfig['DialogContentText']}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            {Object.keys(AlertConfig.buttons).map((index) => {
                //const autofocus = (AlertConfig.buttons[index]["autoFocus"] ? 'autofocus' : null);
                return (
                    <Button key={index}
                        onClick={() => handleClose(AlertConfig.buttons[index]["response"])}
                        color="primary"
                    >
                        {AlertConfig.buttons[index]["title"]}
                    </Button>
                );
            })}
            </DialogActions>
        </Dialog>
        </div>
    );
}

export default AlertDialog