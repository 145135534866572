// Standard React components
import React from 'react';

// Material UI components
import {
	makeStyles, Paper, Toolbar, IconButton
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
	sidebar: {
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		left: 0,
		top: 60,
		margin: theme.spacing(1.5),
		width: theme.dimensions.drawerWidthDesktop,
		bottom: theme.spacing(2),
		zIndex: 1101,
		transition: 'transform .5s ease',
		backgroundColor: 'white',
		[theme.breakpoints.down('md')]: {
		width: '100%',
		margin: 0,
		},
	},
	sidebarInner :{
		position: 'relative',
		top: 0,
		height: '100%',
		left: '0px',
		width: '100%',
	},
	sidebarInner_bottom :{
		bottom: 0,
		left: '0px',
		width: '100%',
		position: 'absolute',
		height: 70,
	},
	center :{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	sidebarCollapsed: {
		transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
		marginLeft: 0,
		[theme.breakpoints.down('md')]: {
		transform: 'translateX(-100vw)',
		},
	},
	paper: {
		zIndex: 1,
	},
	toolbar: {
		display: 'flex',
		padding: theme.spacing(0, 1),
		'& > *': {
		margin: theme.spacing(0, 1),
		},
	},
	ItemsList: {
		flex: 1,
		position: 'absolute',
		top: 0,
		bottom: 70,
		width: '100%',
		overflowY: 'auto'
	},
	ItemsListExtended: {
		flex: 1,
		position: 'absolute',
		top: 0,
		bottom: 0,
		width: '100%',
		overflowY: 'auto'
	},
}));

const DefaultSidebar = ({isCollapsed,
	handleDoCollapse,
	SidebarHeader,
	TopToolbar,
	ItemList,
	BottomToolbar,
	FloatingDiv
}) => {
	const classes = useStyles();

	return (
	<>
		<Paper elevation={3} className={`${classes.sidebar} ${isCollapsed && classes.sidebarCollapsed}`}>
			<Paper className={classes.paper} square elevation={3}>
				<Toolbar className={classes.toolbar} disableGutters>
					<IconButton onClick={handleDoCollapse}>
						<ArrowBackIcon />
					</IconButton>
					<h3>{SidebarHeader}</h3>
				</Toolbar>
				{ TopToolbar !== undefined && (
					<Toolbar className={classes.toolbar} disableGutters>
						{TopToolbar}
					</Toolbar>
				)}
			</Paper>
			<div className={classes.sidebarInner}>
				{ BottomToolbar ? (
					<>
					<div className={classes.ItemsList}>
						{ItemList}
					</div>
					<div className={classes.sidebarInner_bottom}>
						<div className={classes.center}>
							<Toolbar className={classes.toolbar} disableGutters>
								{BottomToolbar}
							</Toolbar>
						</div>
					</div>
					</>)
					:
					<>
						<div className={classes.ItemsListExtended}>
							{ItemList}
						</div>
					</>
				}
			</div>
			{FloatingDiv}
		</Paper>
	</>
	)
}

export default DefaultSidebar;