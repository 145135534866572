// Standard React components
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// App components
import RemoveDialog from '../Dialogs/RemoveDialog';

// General Functions
import t from '../../Libs/localization';

const ViewAllContextMenu = ({ content, editPath, endpoint }) => {
	const history = useHistory();

	const [selectedId, setSelectedId] = useState(null);
	const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
	const [removeDialogShown, setRemoveDialogShown] = useState(false);
	const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());

	const menuShow = (anchorId, itemId) => {
		setSelectedAnchorEl(anchorId);
		setSelectedId(itemId);
	}

	const menuHide = () => {
		setSelectedAnchorEl(null);
	}

	const handleEdit = () => {
		history.push(`${editPath}/${selectedId}`);
		menuHide();
	}

	const handleRemove = () => {
		setRemoveDialogShown(true);
		menuHide();
	}

	const handleRemoveResult = () => {
		setRemoveDialogShown(false);
		setUpdateTimestamp(Date.now());
	}

	const Content = content;

	return (
		<>
			<Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} />
			<Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
				<MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
				<MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
			</Menu>
			<RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} />
		</>
	);
}

export default ViewAllContextMenu;
