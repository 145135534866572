
// Standard React components
import React from 'react';
import { useHistory } from 'react-router-dom';

// Pages
import { PrimaryPageWrapper } from '..'
import ViewAllBody from "./ViewAllBody";
import AddNewBody from './AddNewBody';
import { EditUsingId } from './EditExisting';

// App Components
import { FloatingAddButton } from '../../Components/Buttons/FloatingButtons';

export const ViewAllLessees = () => {
	const history = useHistory();

	const handleAdd = () => {
		history.push('/lessee/add/');
	};

	return (
		<PrimaryPageWrapper>
			<ViewAllBody/>
			<FloatingAddButton
				handleClick={handleAdd}
			/>
		</PrimaryPageWrapper>
	);
}

export const EditExisting = () => {
	return (
		<PrimaryPageWrapper>
			<EditUsingId/>
		</PrimaryPageWrapper>
	);
}

export const AddNew = () => {
	return (
		<PrimaryPageWrapper>
			<AddNewBody/>
		</PrimaryPageWrapper>
	);
}