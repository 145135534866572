// Redux
import { useSelector } from 'react-redux';

// Map Components
import {
	FeatureCollectionLayer, DefaultFeatureCollectionLayerOptions
} from './FeatureCollectionLayer';

// General Functions
import { CheckNullOrUndefined } from '../../Libs/EasyFnc';

export const TargetPoint = ({ShowLayer}) => {
	const ThisFeatureCollection = useSelector(state => state.Navigate.TargetFeatureCollection);

	const id = 'TargetPoint';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.point.paint = {
		'circle-radius': 6,
		'circle-color': '#eb4034'
	};

	FCOptions.layers.line.paint = {
		'line-color': '#51e014',
		'line-width': 6,
	};

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

  return null;
}

export const UserLocation = ({ShowLayer, SetSymbol, IconRotate}) => {
	const ThisFeatureCollection = useSelector(state => state.Navigate.UserFeatureCollection);
	const id = 'UserLocationExtended';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	if (CheckNullOrUndefined(SetSymbol)){
		FCOptions.layers.point.paint = {
			'circle-radius': 12,
			'circle-color': '#eb34d3'
		};
	} else {
		FCOptions.layers.point = {}
		FCOptions.layers.point.symbol = SetSymbol;
	}

	//34ebdf

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

  return null;
}