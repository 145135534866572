// Standard React Components
import React, { useState } from 'react';

// Material UI Components
import {
	Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';

const CountrySelector = ({ItemLabel, handleChange}) => {
	const [Country, setCountry] = useState('za')
	return (
		<FormControl
			variant="filled"
			margin="normal"
			fullWidth
		>
			<InputLabel>{ItemLabel}</InputLabel>
			<Select
				value={Country}
				onChange={event => {
					//setItem({...item, category: event.target.value})
					setCountry(event.target.value)
					handleChange(event.target.value)
				}}
			>
				<MenuItem key={0} value="za">South Africa</MenuItem>
				<MenuItem key={1} value="bw">Botswana</MenuItem>
				<MenuItem key={2} value="other">Other</MenuItem>
			</Select>
		</FormControl>
	)
}

export default CountrySelector