// Standard React components
import React, { useState, useEffect } from 'react';
import ContainerDimensions from 'react-container-dimensions';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { NavigateActions } from '../../ReduxStore';

// Map & Map Features
import Map, {map} from '../../Map/Map';
import CurrentLocationMap from '../../Map/CurrentLocationMap';
import { TargetPoint, UserLocation } from '../../Map/Layers/NavigateMap';
import {
	CreateFeatureFromPointCoords, Features2FeatureCollection, CreateFeatureFromLineCoords
} from '../mapUtil';

// General Functions
import { useAppStyles } from '../../Libs/AppStyleClasses';
import { CheckNullOrUndefined, GetKeyIfExists } from '../../Libs/EasyFnc';
import { WebCompass } from '../../Libs/WebCompass';

import * as turf from '@turf/turf'

// Global Objects
import { AppDebugger } from '../../App';
export const MyWebCompass = new WebCompass()

const StraightLineNavigate = ({InputTargetPoint}) => {
	const dispatch = useDispatch();
	const classes = useAppStyles();
	const geolocate = useSelector(state => state.AppConfig.geolocate);
	const [UserLocationSymbol, setUserLocationSymbol] = useState(null)
	const [LineAngle, setLineAngle] = useState(null)
	const [MapAngle, setMapAngle] = useState(null)
	const mapOptions = useSelector(state => state.Navigate.mapOptions);

	useEffect(() => {
		var UserPos = []
		var UserFeature = null
		var TargetFeature = null
		var LineFeature = null

		if (geolocate.isValid){
			UserPos = [geolocate.coords.longitude,geolocate.coords.latitude]
			UserFeature = CreateFeatureFromPointCoords(UserPos[0],UserPos[1])
			MyWebCompass.UpdateWithGeolocate(geolocate);
		}
		if (!CheckNullOrUndefined(InputTargetPoint)){
			TargetFeature = CreateFeatureFromPointCoords(InputTargetPoint[0],InputTargetPoint[1])
		}
		if (UserPos.length !== 0 & !CheckNullOrUndefined(InputTargetPoint)){
			var options = {units: 'kilometers'};
			var distance = turf.distance(InputTargetPoint, UserPos, options) * 1000;
			dispatch(NavigateActions.UpdateDistance2Target(distance))

			LineFeature = CreateFeatureFromLineCoords([
				InputTargetPoint,
				UserPos
			])

			setLineAngle(turf.angle(
				InputTargetPoint,
				UserPos,
				[geolocate.coords.longitude, 0],{explementary:true}
			));
		}

		if (UserFeature){
			dispatch(NavigateActions.UpdateUserFeatureCollection(Features2FeatureCollection([UserFeature])))
		}
		if (TargetFeature){
			if (LineFeature){
				dispatch(NavigateActions.UpdateTargetFeatureCollection(Features2FeatureCollection([TargetFeature, LineFeature])))
			} else {
				dispatch(NavigateActions.UpdateTargetFeatureCollection(Features2FeatureCollection([TargetFeature])))
			}
		}
	}, [InputTargetPoint,geolocate]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		map.loadImage('/images/arrow.png',
			(error, image) => {
				if (error) throw error;

				// Add the image to the map style.
				map.addImage('arrow', image);
				setUserLocationSymbol({
					'icon-image':'arrow',
					'icon-size': 0.05
				})
			}
		);
		setMapAngle(map.getBearing());

		map.on('rotateend',RotateArrow);
		return () => {
			map.off('rotateend',RotateArrow);
			map.removeImage('arrow');
		}
	}, [])

	const RotateArrow = (e) => {
		setMapAngle(map.getBearing());
	}

	useEffect(() => {
		if (!CheckNullOrUndefined(LineAngle) & !CheckNullOrUndefined(MapAngle)){
			var RotateAngle = LineAngle - MapAngle
			let ThisLayer = map.getLayer('UserLocationExtended-point');
			let ThisLayerType = GetKeyIfExists(ThisLayer, 'type');
			if(ThisLayerType === 'symbol'){
				try {
					map.setLayoutProperty('UserLocationExtended-point','icon-rotate',RotateAngle);
				}
				catch(err) {
					AppDebugger.error('Set icon rotate failed');
					AppDebugger.error(err.message);
				}
			}
		}
	}, [MapAngle,LineAngle])


	useEffect(() => {
		var FlyOptions = {
			duration:500
		}
		if (mapOptions.autoFocus){
			if (geolocate.coords.longitude & geolocate.coords.latitude){
				FlyOptions.center = [geolocate.coords.longitude,geolocate.coords.latitude];
			}
		}
		if (mapOptions.autoZoom){
			FlyOptions.zoom = Math.max(...[map.getZoom(), 16])
		}
		if (mapOptions.autoRotate){
			let NewBearing = MyWebCompass.GetAngle();
			if (NewBearing){
				FlyOptions.bearing = NewBearing;
				//geolocate.coords.heading
			}
		}
		map.easeTo(FlyOptions);
	}, [mapOptions, geolocate])

	return (
		<>
			<div className={classes.mapContainer}>
				<ContainerDimensions>
					<Map>
							<CurrentLocationMap />
							<UserLocation
								SetSymbol={UserLocationSymbol}
							/>
							<TargetPoint/>
					</Map>
				</ContainerDimensions>
			</div>
		</>
	);
};

export default StraightLineNavigate;