// Standard React components
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Map & Map Features
import {
	FeatureCollectionHaveFeatures
} from '../../Map/mapUtil';

// App Components
import StatusCodeSwitcher from '../../Components/PageContent/StatusCodeSwitcher';
//import { ViewMyProperty } from '../../Components/PageContent/PropertiesViewer';
import {
	BasicZoneInfoContent,
	ContractInfoContent,
	AlphaZoneInfoContent,
	ParentZoneInfoContent,
	ChildZoneInfoContent
} from '../../Components/PageContent/PropertiesViewer';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';

export const ZoneInfoBodyUsingId = () => {
	const { id } = useParams();
	const [ThisObject,setThisObject] = useState();
	const [StatusCode,setStatusCode] = useState(100);

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.get(`prop/global/info/${id}`);
		if (response[0]) {
			if (response[1].data){
				setThisObject(response[1].data.payload);
				setStatusCode(200)
			}
		} else {
			AppDebugger.log('Failed to get Contact')
		}
	}, [id]);

	return (
		<StatusCodeSwitcher
			StatusCode = {StatusCode}
			Child200 = {
				<DefaultZoneInfoBody
					InputFeature={ThisObject}
				/>
			}
		/>
	);
}

export const MyPropertyZoneInfoBodyUsingIndex = ({setID}) => {
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	return (
		<ZoneInfoBodyUsingIndex
			ThisFeatureCollection={ThisFeatureCollection}
			setID={setID}
		/>
	)
}

export const AlphaZoneInfoBodyUsingIndex = () => {
	const ThisFeatureCollection = useSelector(state => state.AlphaZone.FeatureCollection);
	return (
		<ZoneInfoBodyUsingIndex
			ThisFeatureCollection={ThisFeatureCollection}
		/>
	)
}

export const MyGreenZoneInfoBodyUsingIndex = () => {
	const ThisFeatureCollection = useSelector(state => state.MyGreenZone.FeatureCollection);
	return (
		<ZoneInfoBodyUsingIndex
			ThisFeatureCollection={ThisFeatureCollection}
		/>
	)
}

export const BlueZoneInfoBodyUsingIndex = () => {
	const ThisFeatureCollection = useSelector(state => state.BlueZone.FeatureCollection);
	return (
		<ZoneInfoBodyUsingIndex
			ThisFeatureCollection={ThisFeatureCollection}
		/>
	)
}

export const ZoneInfoBodyUsingIndex = ({ThisFeatureCollection, setID}) => {
	const { index } = useParams();
	const [ThisFeature,setThisFeature] = useState();
	const [StatusCode,setStatusCode] = useState(100);

	useEffectAsync(() => {
		if (index) {
			if (FeatureCollectionHaveFeatures(ThisFeatureCollection)) {
				setThisFeature(ThisFeatureCollection.features[index]);
				let UniqueID = ThisFeatureCollection.features[index].properties.unique_id;
				{ setID && setID(UniqueID)} //eslint-disable-line no-lone-blocks
				setStatusCode(200);
			}
			else
			{
				setThisFeature({});
				setStatusCode(100);
			}
		} else {
			setThisFeature({});
			setStatusCode(400);
		}

		return () => {
			setThisFeature({});
			setStatusCode(100);
			{ setID && setID()} //eslint-disable-line no-lone-blocks
		}
	}, [ThisFeatureCollection,]);

	return (
		<StatusCodeSwitcher
			StatusCode = {StatusCode}
			Child200 = {
				<DefaultZoneInfoBody
					InputFeature={ThisFeature}
				/>
			}
		/>
	);
}


const DefaultZoneInfoBody = ({InputFeature}) => {
	AppDebugger.dir(InputFeature);
	return (<>
		{ InputFeature
		?
			<>
				<BasicZoneInfoContent
					InputFeature={InputFeature}
				/>
				{InputFeature.contracts &&
					<ContractInfoContent
						InputFeature = {InputFeature.contracts}
					/>
				}
				{InputFeature.relationships && InputFeature.relationships.parent
					&& InputFeature.relationships.parent.category !== 'alpha' &&
					<ParentZoneInfoContent
						parentObj = {InputFeature.relationships.parent}
					/>
				}
				{InputFeature.relationships && InputFeature.relationships.alpha &&
					<AlphaZoneInfoContent
						AlphaID = {InputFeature.relationships.alpha}
					/>
				}
				{InputFeature.relationships && InputFeature.relationships.child &&
					<ChildZoneInfoContent
						InputFeature = {InputFeature.relationships.child}
					/>
				}
			</>
		:
			<></>
		}
	</>)
}