// Standard React Components
import React, { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { MyPropertyActions } from '../../ReduxStore';

// Material UI Components
import {
	Accordion, AccordionSummary, AccordionDetails, Typography, TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';
import t from '../../Libs/localization';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import SubmitForm from '../../Components/FormControls/SubmitForm';
import BoolSelector from '../../Components/Selectors/Bool';
import CountrySelector from '../../Components/Selectors/Country';
import { DOMUsingAPIFetcherUsingID } from '../../Components/PageContent/APIFetcher';

// Other
import { useAppStyles } from '../../Libs/AppStyleClasses';
import { DocumentAccordion } from '../../Components/PageContent/PropertiesEditor';

export const EditUsingId = () => {
	const [ThisObject,setThisObject] = useState();
	const [ID,setID] = useState();

	return (
		<DOMUsingAPIFetcherUsingID
			path='lessor/global/info'
			setFetchedObject={setThisObject}
			setID={setID}
			Child200 = {
				<EditBody
					InputItem={ThisObject}
					UniqueID={ID}
				/>
			}
		/>
	)
}

const EditBody = ({ InputItem, UniqueID }) => {
	const classes = useAppStyles();
	const dispatch = useDispatch();
	const access_level = useSelector(state => state.UserConfig.access_level);
	const [ItemProperties, setItemProperties] = useState();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null);
	const [LockedItem, setLockedItem] = useState(false);
	const [FeatureDocuments, setFeatureDocuments] = useState([]);

	useEffect(() => {
		let ThisProperties = InputItem;
		ThisProperties.locked = ThisProperties.locked || false;
		setLockedItem((access_level < 20 && ThisProperties.locked) || false);
		setItemProperties(ThisProperties);

		if (InputItem.hasOwnProperty('documents')){
			setFeatureDocuments(InputItem.documents);
		};

		return () => {
			setItemProperties();
			setLockedItem(false);
			SetSubmitStatusCode(null);
			setFeatureDocuments([]);
		}
	}, [InputItem, access_level]);

	const handleSubmit = async () => {
		var PostObj = ItemProperties
		delete PostObj['me_index'];
		SetSubmitStatusCode(100);
		const response = await MyAPIFetcher.patch(`lessor/manage/${UniqueID}`,PostObj);
		if (response[0]) {
			SetSubmitStatusCode(200);
			dispatch(MyPropertyActions.DoUpdate());
			AppDebugger.log('Update submitted');
			AppDebugger.dir(PostObj);
		} else {
			SetSubmitStatusCode(404);
			AppDebugger.error('Lessor edit patch failed');
		}
	};

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<SubmitForm
						handleSubmit={handleSubmit}
						SubmitButtonText={t('sharedSave')}
					>
						{ ItemProperties &&
							<>
								{ItemProperties.category === 'individual' && <>
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('str_sobtu027yy')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<TextField
												margin="normal"
												value={ItemProperties.individual.first_name || ''}
												onChange={event => setItemProperties({...ItemProperties, individual:{ ...ItemProperties.individual, first_name: event.target.value}})}
												label={t('sharedNameFirst')}
												variant="filled" />
											<TextField
												margin="normal"
												value={ItemProperties.individual.last_name || ''}
												onChange={event => setItemProperties({...ItemProperties, individual:{ ...ItemProperties.individual, last_name: event.target.value}})}
												label={t('sharedNameLast')}
												variant="filled" />
											<TextField
												margin="normal"
												value={ItemProperties.email || ''}
												onChange={event => setItemProperties({...ItemProperties, email: event.target.value})}
												label={t('userEmail')}
												variant="filled" />
											<TextField
												margin="normal"
												value={ItemProperties.telephone || ''}
												onChange={event => setItemProperties({...ItemProperties, telephone: event.target.value})}
												label={t('userTelNum')}
												variant="filled" />
											<CountrySelector
												ItemLabel={t('str_lwhd9du1ab')}
												handleChange={()=>{}}
											/>
											<TextField
												margin="normal"
												value={ItemProperties.individual.passport_number || ''}
												onChange={event => setItemProperties({...ItemProperties, individual:{ ...ItemProperties.individual, passport_number: event.target.value}})}
												label={t('userID')}
												variant="filled" />
											<TextField
												margin="normal"
												value={ItemProperties.individual.id_number || ''}
												onChange={event => setItemProperties({...ItemProperties, individual:{ ...ItemProperties.individual, id_number: event.target.value}})}
												label={t('userPassport')}
												variant="filled" />
										</AccordionDetails>
									</Accordion>
								</>}
								{ItemProperties.category === 'company' && <>
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('str_j0lqqihgqe')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<TextField
												margin="normal"
												value={ItemProperties.company.registered_name || ''}
												onChange={event => setItemProperties({...ItemProperties, company:{ ...ItemProperties.company, registered_name: event.target.value}})}
												label={t('str_ivk8tq9bih')}
												variant="filled" />
											<TextField
												margin="normal"
												value={ItemProperties.company.reg_number || ''}
												onChange={event => setItemProperties({...ItemProperties, company:{ ...ItemProperties.company, reg_number: event.target.value}})}
												label={t('str_285573olkl')}
												variant="filled" />
											<CountrySelector
												ItemLabel={t('str_lxoe16rryb')}
												handleChange={()=>{}}
											/>
											<TextField
												margin="normal"
												value={ItemProperties.email || ''}
												onChange={event => setItemProperties({...ItemProperties, email: event.target.value})}
												label={t('userEmail')}
												variant="filled" />
											<TextField
												margin="normal"
												value={ItemProperties.telephone || ''}
												onChange={event => setItemProperties({...ItemProperties, telephone: event.target.value})}
												label={t('userTelNum')}
												variant="filled" />
										</AccordionDetails>
									</Accordion>
								</>}

								<DocumentAccordion
									id={UniqueID}
									category="lessor"
									ExistingDocumentsObj={FeatureDocuments}
								/>

								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('sharedRequired')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<BoolSelector
											ItemLabel={t('sharedLocked')}
											handleChange={value => setItemProperties({...ItemProperties, locked: value})}
											trueLabel={null}
											falseLabel={null}
											InputValue={ItemProperties.locked || false}
											isDisabled={LockedItem}
										/>
									</AccordionDetails>
								</Accordion>
							</>
						}
					</SubmitForm>
				}
				Child200 = {<SuccessScreen
					buttonText = {t('str_81tdwxfy0z')}
					buttonPath = {'/lessor/multiview'}
				/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
	);
}