// Standard React Components
import React, { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Material UI Components
import {
	Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../../Legacy/attributes/EditAttributesView';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';
import t from '../../Libs/localization';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import SubmitForm from '../../Components/FormControls/SubmitForm';

// Other
import userAttributes from '../../Legacy/attributes/userAttributes';
import { useAppStyles } from '../../Libs/AppStyleClasses';
import SuperiorSelector from '../../Components/Selectors/Superior';


const AddUserBody = () => {
	const classes = useAppStyles();
	const [item, setItem] = useState();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null);
	const access_level = useSelector(state => state.UserConfig.access_level);

	useEffect(() => {
		setItem({
			username:null,
			first_name:null,
			last_name:null,
			email:null,
			password:null,
			enabled: true,
			access_level:1
		})
	}, [])

	const handleSubmit = async () => {
		var PostObj = item
		PostObj.username = PostObj.email
		SetSubmitStatusCode(100);
		const response = await MyAPIFetcher.post('users/add',item);
		if (response[0]) {
			SetSubmitStatusCode(200);
		} else {
			SetSubmitStatusCode(404);
		}
	};

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<SubmitForm handleSubmit={handleSubmit}>
						{item &&
							<>
								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('sharedRequired')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<TextField
											margin="normal"
											value={item.first_name || ''}
											onChange={event => setItem({...item, first_name: event.target.value})}
											label={t('sharedNameFirst')}
											variant="filled" />
										<TextField
											margin="normal"
											value={item.last_name || ''}
											onChange={event => setItem({...item, last_name: event.target.value})}
											label={t('sharedNameLast')}
											variant="filled" />
										<TextField
											margin="normal"
											value={item.email || ''}
											onChange={event => setItem({...item, email: event.target.value})}
											label={t('userEmail')}
											variant="filled" />
										<TextField
											margin="normal"
											type="password"
											onChange={event => setItem({...item, password: event.target.value})}
											label={t('userPassword')}
											variant="filled" />
										<FormControl variant="filled" fullWidth>
											<InputLabel>{t('sharedAccessLevel')}</InputLabel>
											<Select
												value={item.access_level}
												onChange={event => setItem({...item, access_level: event.target.value})}
											>
												{access_level >= 15 && <MenuItem key={0} value={0}>{t('accessLevel_0')}</MenuItem>}
												<MenuItem key={1} value={1}>{t('accessLevel_1')}</MenuItem>
												{access_level >= 10 && <MenuItem key={5} value={5}>{t('accessLevel_5')}</MenuItem>}
												{access_level >= 15 && <MenuItem key={10} value={10}>{t('accessLevel_10')}</MenuItem>}
												{access_level >= 20 && <MenuItem key={15} value={15}>{t('accessLevel_15')}</MenuItem>}
												{access_level >= 20 && <MenuItem key={20} value={20}>{t('accessLevel_20')}</MenuItem>}
											</Select>
										</FormControl>

										{access_level >= 15 &&
											<SuperiorSelector
												UsersScope={"admins"}
												ItemLabel={t('str_onib1acb6r')}
												handleChange={(value)=>{setItem({...item, group_admin_id: value})}}
												preSelectValue={item.group_admin_id}
											/>
										}
										<SuperiorSelector
											UsersScope={"all"}
											ItemLabel={t('str_4zpvt2yskf')}
											handleChange={(value)=>{setItem({...item, superior_id: value})}}
											preSelectValue={item.superior_id}
										/>

									</AccordionDetails>
								</Accordion>
								{ AppDebugger.isEnabled() &&
									<Accordion>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('sharedAttributes')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<EditAttributesView
												attributes={item.attributes}
												setAttributes={attributes => setItem({...item, attributes})}
												definitions={userAttributes}
												/>
										</AccordionDetails>
									</Accordion>
								}
							</>
						}
					</SubmitForm>
				}
				Child200 = {<SuccessScreen
					buttonText = {t('settingsUsers')}
					buttonPath = {'/user/multiview'}
				/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
	);
}

export default AddUserBody