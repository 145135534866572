// Standard React components
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Redux
import {  useDispatch, useSelector } from 'react-redux';
import { MyGreenZoneActions } from '../../ReduxStore';

// Pages
import { PrimaryPageWrapper } from '..'
import AssignGreen from "./AssignGreen";

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';

// General Functions
import { MyAPIFetcher } from '../../App';
import { FeatureCollectionHaveFeatures, GetFeatureFromFeatureCollectionByIndex } from '../../Map/mapUtil';
import { CheckNullOrUndefined } from '../../Libs/EasyFnc';

export const AssignAlphaZoneToGreenZoneUsingIndex = () => {
	const { index } = useParams();
	const dispatch = useDispatch();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null)
	const ThisFeatureCollection = useSelector(state => state.AlphaZone.FeatureCollection);
	const [ThisFeature, setThisFeature] = useState()
	const [CurrentGreen, setCurrentGreen] = useState()

	const handleClick = async (user_id) => {
		if (!CheckNullOrUndefined(ThisFeature)){
			SetSubmitStatusCode(100);
			const response = await MyAPIFetcher.get('alpha/green/set',{
				"zid":ThisFeature.properties.unique_id,
				"uid":user_id
			});
			if (response[0]) {
				SetSubmitStatusCode(200);
				dispatch(MyGreenZoneActions.DoUpdate());
			} else {
				SetSubmitStatusCode(404);
			}
		}
	};

	useEffect(() => {
		if (FeatureCollectionHaveFeatures(ThisFeatureCollection) & !CheckNullOrUndefined(index)){
			let CurrentFeature = GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,index);
			setThisFeature(CurrentFeature);
			setCurrentGreen((CurrentFeature.relationships && CurrentFeature.relationships.green) || null);
		}
	}, [ThisFeatureCollection,index]);

	return (
		<PrimaryPageWrapper>
			<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<AssignGreen
						handleClick={handleClick}
						CurrentGreen={CurrentGreen}
					/>
				}
				Child200 = {<SuccessScreen/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
		</PrimaryPageWrapper>
	);
}

export const AssignBlueZoneToGreenZoneUsingIndex = () => {
	const { index } = useParams();
	const dispatch = useDispatch();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null)
	const ThisFeatureCollection = useSelector(state => state.BlueZone.FeatureCollection);
	const [ThisFeature, setThisFeature] = useState()
	const [CurrentGreen, setCurrentGreen] = useState()

	const handleClick = async (user_id) => {
		if (!CheckNullOrUndefined(ThisFeature)){
			SetSubmitStatusCode(100);
			const response = await MyAPIFetcher.get('blue/green/set',{
				"zid":ThisFeature.properties.unique_id,
				"uid":user_id
			});
			if (response[0]) {
				SetSubmitStatusCode(200);
				dispatch(MyGreenZoneActions.DoUpdate());
			} else {
				SetSubmitStatusCode(404);
			}
		}
	};

	useEffect(() => {
		if (FeatureCollectionHaveFeatures(ThisFeatureCollection) & !CheckNullOrUndefined(index)){
			let CurrentFeature = GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,index);
			setThisFeature(CurrentFeature);
			setCurrentGreen((CurrentFeature.relationships && CurrentFeature.relationships.green) || null);
		}
	}, [ThisFeatureCollection,index]);

	return (
		<PrimaryPageWrapper>
			<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<AssignGreen
						handleClick={handleClick}
						CurrentGreen={CurrentGreen}
					/>
				}
				Child200 = {<SuccessScreen/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
		</PrimaryPageWrapper>
	);
}