import { createSlice } from '@reduxjs/toolkit';
import {
	CreateFeatureCollectionFromPolygonCoords, PolyFeatureArea, PolyFeatureBoundryLength, PolyFeatureMarkerCount
} from '../Map/mapUtil';

const { reducer, actions } = createSlice({
	name: 'GLDraw',
	initialState: {
		selectedId: null,
		status_code: null,
		DrawnPointsFeatureCollection: {
			"type":"FeatureCollection",
			"totalFeatures":0,
			"features":[]
		},
		PointsPolyFeatureCollection: {
			"type":"FeatureCollection",
			"totalFeatures":0,
			"features":[]
		},
		FeatureArea: null,
		BoundryPolygonFeature: null,
		isBoundryContained: null
	},
	reducers: {
		UpdateDrawnPoints(state,action) {
			var FeaturesCount = action.payload.features.length
			if (FeaturesCount <= state.selectedId){
				state.selectedId = null;
			}
			state.DrawnPointsFeatureCollection = action.payload;
			if (FeaturesCount >= 3){
				var NewPolyPoints = [];
				action.payload.features.forEach((item) => {
					if (item.geometry.type === 'Point'){
						NewPolyPoints.push(item.geometry.coordinates);
					}
				})
				if (NewPolyPoints.length >= 3){
					// Polygon require that first and last point be the same. Add first point again to end
					NewPolyPoints.push(NewPolyPoints[0]);

					// Create new Feature collection from marker points
					var NewPointsPolyFeatureCollection = CreateFeatureCollectionFromPolygonCoords(NewPolyPoints);

					// Check if 'properties' in Feature, if not add
					if (!('properties' in NewPointsPolyFeatureCollection.features[0])){
						NewPointsPolyFeatureCollection.features[0].properties = {}
					}
					if (!('geometry' in NewPointsPolyFeatureCollection.features[0].properties)){
						NewPointsPolyFeatureCollection.features[0].properties.geometry = {}
					}

					// Calculate Area and add to Feature Collection
					const area = PolyFeatureArea(NewPointsPolyFeatureCollection.features[0]);
					const rounded_area = Math.round(area * 100) / 100;
					NewPointsPolyFeatureCollection.features[0].properties.geometry.area = rounded_area;
					NewPointsPolyFeatureCollection.features[0].properties.geometry.boundry_length = PolyFeatureBoundryLength(NewPointsPolyFeatureCollection.features[0]);
					NewPointsPolyFeatureCollection.features[0].properties.geometry.marker_count = PolyFeatureMarkerCount(NewPointsPolyFeatureCollection.features[0]);

					// Update PointsPolyFeatureCollection with valid marker points
					state.PointsPolyFeatureCollection = NewPointsPolyFeatureCollection;
					state.FeatureArea = rounded_area;
				}
			} else {
				state.PointsPolyFeatureCollection.features = [];
				state.FeatureArea = null;
			}
		},
		select(state, action) {
			state.selectedId = action.payload;
		},
		selectByID(state, action) {
			state.DrawnPointsFeatureCollection.features.forEach((item, index) => {
				if (item['id'] === action.payload){
					state.selectedId = index;
				}
			});
		},
		UpdateBoundryPolygonFeature(state,action){
			state.BoundryPolygonFeature = action.payload;
		},
		setBoundryContained(state,action){
			state.isBoundryContained = action.payload;
		},
		ClearAll(state){
			state.status_code = null
			state.selectedId = null
			state.DrawnPointsFeatureCollection = {
					"type":"FeatureCollection",
					"totalFeatures":0,
					"features":[]
			}
			state.PointsPolyFeatureCollection = {
				"type":"FeatureCollection",
				"totalFeatures":0,
				"features":[]
			}
			state.BoundryPolygonFeature = null
			state.isBoundryContained = null
		},
	},
});

export { actions as GLDrawActions };
export { reducer as GLDrawReducer };
