import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import t from '../../Libs/localization';

const ContextMenuView = ({ content, ContentChildren, handleAdd, handleRemove }) => {
	const [selectedId, setSelectedId] = useState(null);
	const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
	const [updateTimestamp] = useState(Date.now());

	const menuShow = (anchorId, itemId) => {
		setSelectedAnchorEl(anchorId);
		setSelectedId(itemId);
	}

	const menuHide = () => {
		setSelectedAnchorEl(null);
	}

	const Content = content;
	return (
		<>
			<Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} ContentChildren={ContentChildren}/>
			<Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
				{/*
				<MenuItem onClick={() => { handleAdd(selectedId);menuHide(); }}>{t('subdivideAddBefore')}</MenuItem>
				<MenuItem onClick={() => { handleAdd(selectedId+1);menuHide(); }}>{t('subdivideAddAfter')}</MenuItem>
				*/}
				<MenuItem onClick={() => { handleRemove(selectedId);menuHide(); }}>{t('sharedRemove')}</MenuItem>
			</Menu>
		</>
	);
}

export default ContextMenuView;
