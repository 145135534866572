// Standard React components
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { AppConfigActions } from './ReduxStore';

// Material UI components
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// App Pages
import  * as AppPages from './Pages';
import LoginPage from './Pages/Authentication/LoginPage';
import LoginPage2 from './Pages/Authentication/LoginPage2';
import ServerPage from './Legacy/admin/ServerPage';
import StatisticsPage from './Legacy/admin/StatisticsPage';
import ErrorPage_Offline from './Pages/Error/OfflinePage';
import FeedbackPage from './Legacy/FeedbackPage';

// Controllers
import AuthController from './Controllers/AuthController';
import CachingController from './Controllers/CachingController';
import ProjectController from './Controllers/ProjectController';

// App Theme
import theme from './Theme';

// General Functions
import { APIFetcher } from './Libs/APIFetcher';
import CustomDebugger from './Libs/CustomDebugger';

// Global Objects
export const MyAPIFetcher = new APIFetcher()
export const AppDebugger = new CustomDebugger()

const App = () => {
	const dispatch = useDispatch();
	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
		AppDebugger.EnableDebugger();
		dispatch(AppConfigActions.enableDev());
	}
	const authenticated = useSelector(state => !!state.AppConfig.project_json && !!state.UserConfig.user);
	const initialized = useSelector(state => !!state.AppConfig.project_json);

	if (!authenticated){
		AppDebugger.log('App is not yet authenticated, waiting')
	} else {
		let MultilineOutput = '-------------------------------------------------------------------- \n';
		MultilineOutput += 'App is authenticated, running app \n';
		MultilineOutput += '====================================================================';
		AppDebugger.log(MultilineOutput);
	}
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ProjectController/>
			<AuthController />
			<CachingController />
			<Switch>
				<Route exact path='/error_offline' component={ErrorPage_Offline} />
				<Route>
					{initialized ? (
						<Switch>
							<Route exact path='/login' component={LoginPage2} />
							<Route exact path='/login2' component={LoginPage} />
							<Route exact path='/logout' component={AppPages.Logout} />
							<Route exact path='/' component={AppPages.IndexPage} />
							<Route exact path='/home' component={AppPages.HomePage} />

							<Route exact path='/view/:page?' component={AppPages.HomePage} />
							<Route exact path='/feedback' component={FeedbackPage} />

							<Route exact path='/user/edit/:id?' component={AppPages.UserManagementPages.EditUser} />
							<Route exact path='/user/add/' component={AppPages.UserManagementPages.AddUser} />
							<Route exact path='/user/multiview/' component={AppPages.UserManagementPages.ViewAllUsers} />

							<Route exact path='/lessee/add/' component={AppPages.LesseePages.AddNew} />
							<Route exact path='/lessee/edit/:id?' component={AppPages.LesseePages.EditExisting} />
							<Route exact path='/lessee/multiview/' component={AppPages.LesseePages.ViewAllLessees} />

							<Route exact path='/lessor/add/' component={AppPages.LessorPages.AddNew} />
							<Route exact path='/lessor/edit/:id?' component={AppPages.LessorPages.EditExisting} />
							<Route exact path='/lessor/multiview/' component={AppPages.LessorPages.MultiView} />

							<Route exact path='/contract/add/' component={AppPages.ContractPages.AddNew} />
							<Route exact path='/contract/edit/:id?' component={AppPages.ContractPages.EditExisting} />
							<Route exact path='/contract/multiview/' component={AppPages.ContractPages.MultiView} />

							<Route exact path='/file/view/:id?' component={AppPages.FileManagerPages.ViewPDFPage} />
							<Route exact path='/file/download/:id?' component={AppPages.FileManagerPages.DownloadPDFPage} />
							<Route exact path='/file/remove/:id?' component={AppPages.FileManagerPages.RemovePDFPage} />
							<Route exact path='/file/upload' component={AppPages.FileManagerPages.UploadPDFPage} />
							<Route exact path='/file/edit/properties/:id?' component={AppPages.FileManagerPages.EditPDFProperties} />
							<Route exact path='/file/reqgen' component={AppPages.FileManagerPages.PDFGenerateReqPage} />

							<Route exact path='/prop/me/info/:index?' component={AppPages.InfoPages.MyPropertyIndex} />
							<Route exact path='/prop/me/edit/properties/:index?' component={AppPages.ZonePropertiesEditPages.MyPropertyIndex} />
							<Route exact path='/prop/me/edit/map/:index?' component={AppPages.MapPages.MyPropertySubdivideEditByIndex} />
							<Route exact path='/prop/me/navigate/point/:id?' component={AppPages.MapPages.NavigatePropertyPointPage} />
							<Route exact path='/prop/global/info/:id?' component={AppPages.InfoPages.GlobalPropertyInfo} />
							<Route exact path='/prop/global/edit/properties/:id?' component={AppPages.ZonePropertiesEditPages.MyPropertyId} />
							<Route exact path='/prop/global/edit/map/:id?' component={AppPages.MapPages.EditMyPropertyById} />

							<Route exact path='/blue/me/assign/:index?' component={AppPages.GreenUser.AssignBlueZoneToGreenZoneUsingIndex} />
							<Route exact path='/blue/me/view/:index?' component={AppPages.InfoPages.BlueZoneIndex} />
							<Route exact path='/blue/global/edit/properties/:id?' component={AppPages.ZonePropertiesEditPages.BlueZoneId} />
							<Route exact path='/blue/global/edit/map/:id?' component={AppPages.MapPages.EditBlueZoneById} />

							<Route exact path='/my_green/me/view/:index?' component={AppPages.InfoPages.MyGreenZoneIndex} />
							<Route exact path='/my_green/subdivide' component={AppPages.MapPages.SubdividePageMyGreenZone} />

							<Route exact path='/alpha/global/add' component={AppPages.MapPages.AddAlphaZone} />
							<Route exact path='/alpha/global/edit/properties/:id?' component={AppPages.ZonePropertiesEditPages.AlphaZoneId} />
							<Route exact path='/alpha/global/edit/map/:id?' component={AppPages.MapPages.EditAlphaZoneById} />
							<Route exact path='/alpha/me/assign/:index?' component={AppPages.GreenUser.AssignAlphaZoneToGreenZoneUsingIndex} />
							<Route exact path='/alpha/me/view/:index?' component={AppPages.InfoPages.AlphaZoneIndex} />
							<Route exact path='/alpha/me/edit/properties/:index?' component={AppPages.ZonePropertiesEditPages.AlphaZoneIndex} />
							<Route exact path='/alpha/me/edit/map/:index?' component={AppPages.MapPages.EditAlphaZoneByIndex} />

							<Route exact path='/admin/server' component={ServerPage} />
							<Route exact path='/admin/users' component={AppPages.AdminPages.UsersPageBody} />
							<Route exact path='/admin/statistics' component={StatisticsPage} />

							<Route exact path='/navigate/demo' component={AppPages.MapPages.NavigatePage} />
							<Route exact path='/navigate/prop/point/:unique_id?' component={AppPages.MapPages.NavigatePropertyPointPage} />

							<Route exact path='/_blank' component={AppPages._blank} />
							<Route exact path='/createpdf' component={AppPages.FileManagerPages.CreatePDFPage} />
							<Route><Redirect to="/" /></Route>
						</Switch>
					)
					: (<>
						<AppPages.Global.AppLoading/>
					</>)}
				</Route>
			</Switch>
		</ThemeProvider>
	);
}

export default App;
