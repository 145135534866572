// Standard React components
import React from 'react';
import { useHistory } from 'react-router-dom';

// Material UI components
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PanToolIcon from '@material-ui/icons/PanTool';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import BackspaceIcon from '@material-ui/icons/Backspace';

// General Functions
import t from '../../Libs/localization';
import { CheckUndefined } from '../../Libs/EasyFnc'
import { useAppStyles } from '../../Libs/AppStyleClasses';

// Map & Map Features
import { map } from '../Map';
import { draw } from "./AddPolygonPointsMap"

// General Features
import { AppDebugger } from '../../App';

const DefaultDrawButton = ({ handleClick , ThisIcon, ThisHeader, Properties }) => {
	const classes = useAppStyles();
	const theme = useTheme();
	const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

	return (
	<div>
		<Button
			variant="contained"
			color={isPhone ? 'secondary' : 'primary'}
			classes={{ containedPrimary: classes.sidebarToggleBg }}
			className={classes.sidebarToggle}
			onClick={ handleClick }
			disableElevation
			disabled = { !CheckUndefined(Properties) && (Properties.hasOwnProperty('disabled') ? Properties.disabled : false)}
		>
			{ ThisIcon }
			<div className={classes.sidebarToggleText}>{ ThisHeader }</div>
		</Button>
	</div>
	)
}

const handle_MapboxDrawBtn_select = () => {
	try {
		draw.changeMode('simple_select');
	} catch(e) {
		AppDebugger.error(e.message)
	}
}

const handle_MapboxDrawBtn_polygon = () => {
	try {
		draw.changeMode('draw_multi_point');
	} catch(e) {
		AppDebugger.error(e.message)
	}
}

const handle_MapboxDrawBtn_delete = () => {
	try {
  	let currentMode = draw.getMode()
		if (currentMode === 'simple_select'){
			draw.trash();
		}
		map.fire('draw.update');
	} catch(e) {
		AppDebugger.error(e.message)
	}
}

export const DrawButtonAdd = (( {enabled, handleClick} ) => {
	return (
		<DefaultDrawButton
			handleClick = { handle_MapboxDrawBtn_polygon }
			ThisIcon = { <CreateIcon /> }
			ThisHeader = { t('sharedDrawPolygon') }
		/>
	)
});

export const DrawButtonSelect = (( {enabled, handleClick} ) => {
	return (
		<DefaultDrawButton
			handleClick = {handle_MapboxDrawBtn_select}
			ThisIcon = { <PanToolIcon /> }
					ThisHeader = { t('sharedDrawSelect') }
		/>
	)
});

export const DrawButtonDelete = (( {enabled, handleClick} ) => {
	return (
		<DefaultDrawButton
			handleClick = { handle_MapboxDrawBtn_delete }
			ThisIcon = { <DeleteForeverIcon /> }
			ThisHeader = { t('sharedDrawDelete') }
		/>
	)
});

export const DrawButtonSubmit = (( {enabled, handleClick} ) => {
	return (
		<DefaultDrawButton
			handleClick = { (!CheckUndefined(handleClick) ? handleClick : (() => {})) }
			ThisIcon = { <DoneIcon /> }
			ThisHeader = { t('sharedSubmit') }
			Properties = {{
				'disabled': !CheckUndefined(enabled) ? !enabled : false
			}}
		/>
	)
});

export const DrawButtonCancel = () => {
	const history = useHistory();
	return (
		<DefaultDrawButton
			handleClick = { () => {history.goBack()} }
			ThisIcon = { <BackspaceIcon /> }
			ThisHeader = { t('sharedCancel') }
		/>
	)
};