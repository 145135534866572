import mapboxgl from 'mapbox-gl';

import { switchBasemapControl } from './CustomControls/BasemapSwitcher';
import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { styleBlank, styleMapboxAPI, styleOsm } from './mapStyles';
import t from '../Libs/localization';
import { useSelector } from 'react-redux';
import { AppDebugger } from '../App';
import { LoadingMapStyleScreen } from '../Components/PageContent/ProcessingRequestScreens';

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';

export const map = new mapboxgl.Map({
	container: element,
	style: styleBlank()
});

let ready = false;
const readyListeners = new Set();

const addReadyListener = listener => {
	readyListeners.add(listener);
	listener(ready);
};

const removeReadyListener = listener => {
	readyListeners.delete(listener);
};

const updateReadyValue = value => {
	ready = value;
	readyListeners.forEach(listener => listener(value));
};

const initMap = async () => {
	updateReadyValue(true);
};

map.on('load', initMap);

map.addControl(new mapboxgl.NavigationControl({
	showCompass: true,
}));

const MapStylesSwitcherJSON = {
	"Blank" : { title: t('mapBlank'), uri: styleMapboxAPI('empty-v8') },
	"OSM_Default" : { title: t('mapOsm'), uri: styleOsm() },
	"MapboxStreets" : { title: t('mapMapboxStreets'), uri: styleMapboxAPI('streets-v11') },
	"MapboxOutdoors" : { title: t('mapMapboxOutdoors'), uri: styleMapboxAPI('outdoors-v11') },
	"MapboxLight" : { title: t('mapMapboxLight'), uri: styleMapboxAPI('light-v10') },
	"MapboxDark" : { title: t('mapMapboxDark'), uri: styleMapboxAPI('dark-v10') },
	"MapboxSatellite" : { title: t('mapMapboxSatellite'), uri: styleMapboxAPI('satellite-v9') },
	"MapboxSatelliteStreets" : { title: t('mapMapboxSatelliteStreets'), uri: styleMapboxAPI('satellite-streets-v11') },
	"MapboxNavigationDay" : { title: t('mapMapboxNavigationDay'), uri: styleMapboxAPI('navigation-day-v1') },
	"MapboxNavigationNight" : { title: t('mapMapboxNavigationNight'), uri: styleMapboxAPI('navigation-night-v1') },
}

const Map = ({ children }) => {
	const MapDefaults = useSelector(state => state.AppConfig.map_defaults);
	const MapStyles = useSelector(state => state.AppConfig.map_styles);
	const MapboxAPIKey = useSelector(state => state.AppConfig.api_keys.mapbox);
	const containerEl = useRef(null);

	const [mapReady, setMapReady] = useState(false);
	const [styleReady, setStyleReady] = useState(true);

	const onStyleLoad = () => {
		setStyleReady(false);
		const waiting = () => {
			if (!map.isStyleLoaded()) {
				setTimeout(waiting, 200);
			} else {
				setStyleReady(true);
			}
		};
		waiting();
	}

	useEffect(() => {
		const MapReadyListener = ready => setMapReady(ready);

		addReadyListener(MapReadyListener);
		map.on('style.load', onStyleLoad);
		return () => {
			removeReadyListener(MapReadyListener);
			map.off('style.load', onStyleLoad);
		};
	}, []);

	useEffect(() => {
		if (MapDefaults.bbox !== null){
			if (MapDefaults.bbox !== []){
					map.fitBounds(MapDefaults.bbox, {padding: 20});
					AppDebugger.log('Map Defaults Found, doing fitBounds')
			}
		}
	}, [MapDefaults]);

	useEffect(() => {
		mapboxgl.accessToken = MapboxAPIKey
		AppDebugger.log('Mapbox API Key is set')
	}, [MapboxAPIKey]);

	useEffect(() => {
		var StyleSwitcherControl_styles = [];
		var StyleSwitcherControl_default = null;
		if (map !== null) {
			if (MapStyles !== []){
				MapStyles.map((item) => (item in MapStylesSwitcherJSON) && StyleSwitcherControl_styles.push(MapStylesSwitcherJSON[item]));
			} else {
				StyleSwitcherControl_styles.push({ title: t('mapOsm'), uri: styleOsm() })
				if (MapboxAPIKey !== null){
					StyleSwitcherControl_styles.push({ title: t('mapMapboxStreets'), uri: styleMapboxAPI('streets-v11') });
				}
			}

			if (MapDefaults.style in MapStylesSwitcherJSON) {
				StyleSwitcherControl_default = MapStylesSwitcherJSON[MapDefaults.style]['title']
			} else {
				StyleSwitcherControl_default = StyleSwitcherControl_styles["0"]['title'];
			}

			const MyBasemapControlSwitcher = new switchBasemapControl(
				StyleSwitcherControl_styles,
				StyleSwitcherControl_default,
				() => {},
				() => {},
			);
			map.addControl(MyBasemapControlSwitcher);

			return () => {
				map.removeControl(MyBasemapControlSwitcher);
			}
		}
	}, [MapboxAPIKey,MapStyles,MapDefaults]);

	useLayoutEffect(() => {
		const currentEl = containerEl.current;
		currentEl.appendChild(element);
		if (map) {
			map.resize();
		}
		return () => {
			currentEl.removeChild(element);
		};
	}, [containerEl]);

	return (
		<div style={{ width: '100%', height: '100%' }}>
			{ !styleReady &&
				<LoadingMapStyleScreen/>
			}
			<div
				style={{ width: '100%', height: '100%' }}
				ref={containerEl}
			>
				{mapReady && children}
			</div>
		</div>
	);
};

export default Map;