// Standard React components
import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useEffectAsync } from '../../reactHelper';
import { useSelector } from 'react-redux';

// Material UI components
import {
	Typography,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	List,
	Divider,
	LinearProgress,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Map & Map Features
import {
	FeatureCollectionHaveFeatures,
	PolyFeatureArea,
	GetPolygonPoints,
	CreateFeatureCollectionFromPolygonCoords
} from '../../Map/mapUtil';
import * as turf from '@turf/turf'

// App components
import { NewPaper, NewPaperInner } from './Shared';
import { DocumentList } from "./FileExplorer";

// General Functions
import {
	FormatVolumeMetric, formatDistanceMetric, formatPosition
} from '../../Libs/formatter';
import { prefixString } from '../../Libs/stringUtils';
import { AppDebugger, MyAPIFetcher } from '../../App';
import t from '../../Libs/localization';
import { CheckNullOrUndefined } from '../../Libs/EasyFnc';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
	table: {
    minWidth: 650,
  },
}));

const PropertiesListItem = ({ThisKey,value1,value2}) => {
	return (
		<Fragment key={ThisKey}>
			<ListItem>
				<ListItemText
					primary={value1}
				/>
				<ListItemSecondaryAction>
					<Typography variant="body2">
						{value2}
					</Typography>
				</ListItemSecondaryAction>
			</ListItem>
		</Fragment>
	)
}

export const ViewMyProperty = () => {
	const { id } = useParams();

	const [item, setItem] = useState();
	const [ThisFeature, setThisFeature] = useState();
	const [ThisFeatureCalculatedValues, setThisFeatureCalculatedValues] = useState({});
	const [HaveDocuments, setHaveDocuments] = useState(false);

	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);

	const formatKey = (key) => {
		return t(prefixString('position', key)) || `${t('sharedAttribute')} "${key}"`;
	};

	const attributesList = () => {
		const combinedList = {...item, ...item.attributes};
		return Object.entries(combinedList).filter(([_, value]) => typeof value !== 'object');
	}

	useEffectAsync(() => {
		if (id) {
			if (FeatureCollectionHaveFeatures(ThisFeatureCollection)) {
				setItem(ThisFeatureCollection.features[id].properties);
				setThisFeature(ThisFeatureCollection.features[id]);
				if ('documents' in ThisFeatureCollection.features[id]){
					setHaveDocuments(true)
				}
			}
			else
			{
				setItem({});
				setThisFeature({});
			}
		} else {
			setItem({});
			setThisFeature({});
		}
	}, [ThisFeatureCollection]);

	useEffect(() => {
		if (ThisFeature){
			var CalcuatedValuesObj = {}

			CalcuatedValuesObj.area = FormatVolumeMetric(PolyFeatureArea(ThisFeature)) || t('sharedNull');
			CalcuatedValuesObj.boundry_length = formatDistanceMetric(turf.length(ThisFeature)*1000) || t('sharedNull');
			CalcuatedValuesObj.number_of_coordinates = ThisFeature.geometry.coordinates[0].length || t('sharedNull');

			setThisFeatureCalculatedValues(CalcuatedValuesObj);
		}
	}, [ThisFeature])

	return (
		<>
			{ ThisFeature &&
				<NewPaper>
					<NewPaperInner>
						<h2>{t('str_ho973r2crf')}</h2>
						<h3>{t('str_o119y4wa7b')}</h3>
						<List>
							<PropertiesListItem
								ThisKey="name"
								value1="Property Name"
								value2={ThisFeature.properties.name}
							/>
							<PropertiesListItem
								ThisKey="unique_id"
								value1="Unique ID"
								value2={ThisFeature.properties.unique_id}
							/>
						</List>
						<Divider />
					</NewPaperInner>
					<NewPaperInner>
						<h3>{t('str_9ugj5n3oig')}</h3>
						<List>
							<PropertiesListItem
								ThisKey="area"
								value1="Size"
								value2={ThisFeatureCalculatedValues.area}
							/>
							<PropertiesListItem
								ThisKey="length"
								value1="Boundry Length"
								value2={ThisFeatureCalculatedValues.boundry_length}
							/>
							<PropertiesListItem
								ThisKey="polypoints"
								value1="Number of boundry points"
								value2={ThisFeatureCalculatedValues.number_of_coordinates}
							/>
							<Divider />
						</List>
					</NewPaperInner>
				</NewPaper>
			}
			<NewPaper>
				{ HaveDocuments &&
					<DocumentList
						DocumentIDsList = {ThisFeature.documents}
					/>
				}
			</NewPaper>
			<NewPaper>
				<NewPaperInner>
					<h2>{t('str_1395nbfxp1')}</h2>
					<List>

					</List>
				</NewPaperInner>
			</NewPaper>
			{ AppDebugger.isEnabled() &&
				<NewPaper>
					<NewPaperInner>
						<h2>All info</h2>
						{item &&
							<List>
								{attributesList().map(([key, value], index, list) => (
									<Fragment key={key}>
										<ListItem>
											<ListItemText
												primary={formatKey(key)}
												/>
											<ListItemSecondaryAction>
												<Typography variant="body2">
													{formatPosition(value, key)}
												</Typography>
											</ListItemSecondaryAction>
										</ListItem>
										{index < list.length - 1 ? <Divider /> : null}
									</Fragment>
								))}
							</List>
						}
					</NewPaperInner>
				</NewPaper>
			}
		</>
	);
}

export const BasicZoneInfoContent = ({InputFeature}) => {
	return (
		<NewPaper>
			<NewPaperInner>
				<h2>{t('str_ho973r2crf')}</h2>
				<h3>{t('str_o119y4wa7b')}</h3>
				<List>
					<PropertiesListItem
						ThisKey="name"
						value1="Property Name"
						value2={InputFeature.properties.name}
					/>
					<PropertiesListItem
						ThisKey="unique_id"
						value1="Unique ID"
						value2={InputFeature.properties.unique_id}
					/>
				</List>
				<Divider />
			</NewPaperInner>
			<ZoneGeographicInfoContent
				InputFeature={InputFeature}
			/>
			{InputFeature.documents &&
				<DocumentList
					DocumentIDsList = {InputFeature.documents}
				/>
			}
		</NewPaper>
	)
}

export const ZoneGeographicInfoContent = ({InputFeature}) => {
	const [GeometryProperties, setGeometryProperties] = useState();

	useEffect(() => {
		if (!CheckNullOrUndefined(InputFeature)){
			let TemporaryFeature = {};

			TemporaryFeature.area = FormatVolumeMetric(PolyFeatureArea(InputFeature)) || t('sharedNull');
			TemporaryFeature.boundry_length = formatDistanceMetric(turf.length(InputFeature)*1000) || t('sharedNull');
			TemporaryFeature.number_of_coordinates = InputFeature.geometry.coordinates[0].length -1 || t('sharedNull');

			setGeometryProperties(TemporaryFeature);
		}

		return () => {
			setGeometryProperties();
		}
	}, [InputFeature]);

	return (
		<>
			{ GeometryProperties &&
				<NewPaperInner>
					<h3>{t('str_9ugj5n3oig')}</h3>
					<List>
						<PropertiesListItem
							ThisKey="area"
							value1="Size"
							value2={GeometryProperties.area}
						/>
						<PropertiesListItem
							ThisKey="length"
							value1="Boundry Length"
							value2={GeometryProperties.boundry_length}
						/>
						<PropertiesListItem
							ThisKey="polypoints"
							value1="Number of boundry points"
							value2={GeometryProperties.number_of_coordinates}
						/>
						<StaticMapWithFeature InputFeature={InputFeature}/>
						<CoordsListAccordion InputFeature={InputFeature}/>
						<Divider />
					</List>
				</NewPaperInner>
			}
		</>
	)
}

const CoordsListAccordion = ({InputFeature}) => {
	const classes = useStyles();
	const coords = GetPolygonPoints(InputFeature)

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="subtitle1">
					{t('sharedCoordinates')}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.details}>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center">{t('sharedLongitude')}</TableCell>
							<TableCell align="center">{t('sharedLatitude')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{coords.map((row,index) => (
							<TableRow key={index}>
								<TableCell align="center">{row[0]}</TableCell>
								<TableCell align="center">{row[1]}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			</AccordionDetails>
		</Accordion>
	)
}

const StaticMapWithFeature = ({InputFeature}) => {
	const classes = useStyles();
	const [imageObjectURL, setImageObjectURL] = useState();

	useEffect(() => {
		if (InputFeature){
			const coords = GetPolygonPoints(InputFeature);
			var ThisFeatureCollection = CreateFeatureCollectionFromPolygonCoords(coords);
			ThisFeatureCollection.features[0].properties = {
				"stroke": "#eb5934",
				"stroke-opacity": 1.0,
				"stroke-width": 2,
				"fill": "#eb34d8",
				"fill-opacity": 0.5
			}

			const thisURL = process.env.REACT_APP_JC2GIS_URL + 'static/mapbox_overlay/auto/satellite-streets-v11';

			var ReqObj = {
				'url':thisURL,
				'token':"123",
				'PostData': {
					"width": 800,
					"height": 600,
					"padding": [10,10,10,10],
					"GeoJSON": ThisFeatureCollection
				}
			}

			DownloadFile(ReqObj);
		}
		return () => {
		}
	}, [InputFeature])

	const DownloadFile = (ReqObj) => {
		if (ReqObj){
			fetch(ReqObj['url'], {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Token': ReqObj['token'],
				},
				body: JSON.stringify(ReqObj['PostData']),
			})
			.then((response) => {
				if (response.ok){
					response.blob()
						.then((blob) => {
							// Create blob link to download
							const imageObjectURL = window.URL.createObjectURL(
								new Blob([blob]),
							);
							setImageObjectURL(imageObjectURL);
						})
				} else {
					AppDebugger.dir(response);
					throw new Error('Invalid Static Map response');
				}
			})
			.catch(function(error){
				console.log('Static Map loading failed');
				AppDebugger.error(error);
			});
		}
	}

	return (
		<>
			{imageObjectURL &&
				<Accordion defaultExpanded>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography variant="subtitle1">
							{t('sharedMap')}
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
					<img src={imageObjectURL} alt="static map" />
					</AccordionDetails>
				</Accordion>
			}
		</>)
}

export const ContractInfoContent = ({InputFeature}) => {
	const [ContractsInfoObj, setContractsInfoObj] = useState([])

	const GetContract = async (ContractID) => {
		var output = null
		const response = await MyAPIFetcher.get(`contract/global/info/${ContractID}`);
		if (response[0]) {
			if (response[1].data){
				output = response[1].data.payload;
				output['contract_id'] = ContractID
				setContractsInfoObj(ContractsInfoObj => [...ContractsInfoObj, output])
			}
		} else {
			AppDebugger.log('Failed to get Contact')
		}
		return output
	}

	useEffect(() => {
		InputFeature.forEach(element => {
			GetContract(element)
		});
		return () => {
			setContractsInfoObj()
		}
	}, [InputFeature]);

	return (
		<NewPaper>
			<NewPaperInner>
				{ContractsInfoObj &&
					<>
						<h2>{t('str_4kx3qetkh7')}</h2>
						{ContractsInfoObj.map((item,index) => (
							<div key={index}>
							{ item.name ?
								<>
									<h3>{t('str_o119y4wa7b')}</h3>
									<List>
										<PropertiesListItem
											ThisKey="name"
											value1={t('sharedName')}
											value2={item.name}
										/>
										{item.start_date &&
											<PropertiesListItem
												ThisKey="start_date"
												value1={t('sharedDateStart')}
												value2={(item.start_date && moment.unix(item.start_date).format(moment.HTML5_FMT.DATETIME_LOCAL)) || null}
											/>
										}
										{item.end_date &&
											<PropertiesListItem
												ThisKey="end_date"
												value1={t('sharedDateEnd')}
												value2={(item.end_date && moment.unix(item.end_date).format(moment.HTML5_FMT.DATETIME_LOCAL)) || null}
											/>
										}
										<DocumentList
											DocumentIDsList = {item.documents || [] }
											PaperHeader = {<h4>{t('sharedDocuments')}</h4>}
										/>
										<Divider />
										<h3>{t('sharedLessee')}</h3>
										{item.lessee_unique_id && <LesseeInfoContent LesseeID={item.lessee_unique_id} />}
										<Divider />
										<h3>{t('sharedLesseor')}</h3>
										{item.lessor_unique_id && <LessorInfoContent LessorID={item.lessor_unique_id} />}
									</List>
									<Divider />
									<Divider />
								</>
							:
								<LinearProgress/>
							}
							</div>
						))}
					</>
				}

			</NewPaperInner>
		</NewPaper>
	)
}

const LesseeInfoContent = ({LesseeID}) => {
	const [ThisObj, setThisObj] = useState();

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.get(`lessee/global/info/${LesseeID}`);
		if (response[0]) {
			if (response[1].data){
				setThisObj(response[1].data.payload);
			}
		} else {
			AppDebugger.log('Failed to get lessee info')
		}
	}, [LesseeID]);

	return (
		<>
		{ThisObj ?
			<>
				<PropertiesListItem
					ThisKey="name"
					value1={t('sharedName')}
					value2={ThisObj.name}
				/>
				<DocumentList
					DocumentIDsList = {ThisObj.documents || [] }
					PaperHeader = {<h4>{t('sharedDocuments')}</h4>}
				/>
			</>
		:
			<LinearProgress/>
		}
		</>
	)
}

const LessorInfoContent = ({LessorID}) => {
	const [ThisObj, setThisObj] = useState()

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.get(`lessor/global/info/${LessorID}`);
		if (response[0]) {
			if (response[1].data){
				setThisObj(response[1].data.payload);
			}
		} else {
			AppDebugger.log('Failed to get lessor info')
		}
	}, [LessorID]);

	return (
		<>
		{ThisObj ?
			<>
			<PropertiesListItem
				ThisKey="name"
				value1={t('sharedName')}
				value2={ThisObj.name}
			/>
			<DocumentList
				DocumentIDsList = {ThisObj.documents || [] }
				PaperHeader = {<h4>{t('sharedDocuments')}</h4>}
			/>
		</>
		:
			<LinearProgress/>
		}
		</>
	)
}

export const AlphaZoneInfoContent = ({AlphaID}) => {
	const [ThisObj, setThisObj] = useState()

	useEffectAsync(async () => {
		let ParentID = AlphaID
		let ParentCategory = 'alpha'
		const response = await MyAPIFetcher.get(`prop/parent/info/${ParentCategory}/${ParentID}`);
		if (response[0]) {
			if (response[1].data){
				setThisObj(response[1].data.payload);
			}
		} else {
			AppDebugger.log('Failed to get parent info')
		}
	}, [AlphaID]);

	return (
		<>
		{ AlphaID &&
				<NewPaper>
					<NewPaperInner>
						<h2>{t('str_7jjmvzfh4k')}</h2>
						{ThisObj ?
							<>
								<h3>{t('str_o119y4wa7b')}</h3>
								<List>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedName')}
										value2={ThisObj.properties.name}
									/>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedUniqueId')}
										value2={ThisObj.properties.unique_id}
									/>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedDescription')}
										value2={ThisObj.properties.description}
									/>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedZoneType')}
										value2={ThisObj.properties.zone_type}
									/>
								</List>
								<Divider />
								<ZoneGeographicInfoContent
									InputFeature={ThisObj}
								/>
								{ThisObj.documents &&
									<DocumentList
										DocumentIDsList = {ThisObj.documents}
									/>
								}
							</>
						:
							<LinearProgress/>
						}
					</NewPaperInner>
				</NewPaper>
		}
		</>
	)
}

export const ParentZoneInfoContent = ({parentObj}) => {
	const [ThisObj, setThisObj] = useState()

	useEffectAsync(async () => {
		let ParentID = parentObj['id']
		let ParentCategory = parentObj['category']
		const response = await MyAPIFetcher.get(`prop/parent/info/${ParentCategory}/${ParentID}`);
		if (response[0]) {
			if (response[1].data){
				setThisObj(response[1].data.payload);
			}
		} else {
			AppDebugger.log('Failed to get parent info')
		}
	}, [parentObj]);

	return (
		<>
		{ parentObj &&
				<NewPaper>
					<NewPaperInner>
						<h2>{t('str_zfh4k7jjmv')}</h2>
						{ThisObj ?
							<>
								<h3>{t('str_o119y4wa7b')}</h3>
								<List>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedName')}
										value2={ThisObj.properties.name}
									/>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedUniqueId')}
										value2={ThisObj.properties.unique_id}
									/>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedDescription')}
										value2={ThisObj.properties.description}
									/>
									<PropertiesListItem
										ThisKey="name"
										value1={t('sharedZoneType')}
										value2={ThisObj.properties.zone_type}
									/>
								</List>
								<Divider />
								<ZoneGeographicInfoContent
									InputFeature={ThisObj}
								/>
								{ThisObj.documents &&
									<DocumentList
										DocumentIDsList = {ThisObj.documents}
									/>
								}
							</>
						:
							<LinearProgress/>
						}
					</NewPaperInner>
				</NewPaper>
		}
		</>
	)
}

export const ChildZoneInfoContent = ({InputFeature}) => {
	return (
		<NewPaper>
			<NewPaperInner>
				<h2>{t('str_4kx3qetkh7')}</h2>
				{InputFeature && InputFeature.map((item,index) => (
					<>
						<h3>Children Zone info</h3>
						<Divider />
					</>
				))}
			</NewPaperInner>
		</NewPaper>
	)
}