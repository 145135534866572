// Standard React components
import React, { useState, useEffect} from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { AlphaZoneActions, GLDrawActions } from '../../ReduxStore';

// App Components
import {APICallStatusCodeSwitcher} from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import GLDrawPageContent from '../../Components/PageContent/GLDraw';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App'

// Map Features
import {
	FeatureCollectionHaveFeatures,
	GetFeatureFromFeatureCollectionByIndex,
	Features2FeatureCollection,
	CreatePointFeatureFromPolygonFeature
} from '../../Map/mapUtil';

export const AddAlphaZoneBody = ({InputFeatureCollection}) => {
	const dispatch = useDispatch();
	const [SubmitEnabled, setSubmitEnabled] = useState(false)
	const [SubmitStatusCode,SetSubmitStatusCode] = useState(null)
	const status_code = useSelector(state => state.MyGreenZone.status_code);
	const isBoundryContained = useSelector(state => state.GLDraw.isBoundryContained);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);
	const [CacheInputFeatureCollection, setCacheInputFeatureCollection] = useState()

	useEffect(() => {
		dispatch(GLDrawActions.UpdateBoundryPolygonFeature(null));
		return () => {
			setCacheInputFeatureCollection();
			dispatch(GLDrawActions.UpdateBoundryPolygonFeature(null));
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSubmitEnabled(false);
		if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)) {
			if (isBoundryContained){
				setSubmitEnabled(true);
			};
		};
	},[PointsPolyFeatureCollection,isBoundryContained]);

	const handleSubmit = async () => {
		SetSubmitStatusCode(100)
		const response = await MyAPIFetcher.post('alpha/global/add',PointsPolyFeatureCollection);
		AppDebugger.log('Adding new property');
		AppDebugger.dir(PointsPolyFeatureCollection);
		if (response[0]) {
			dispatch(AlphaZoneActions.DoUpdate());
			SetSubmitStatusCode(200);
			setCacheInputFeatureCollection();
		} else {
			SetSubmitStatusCode(404);
			let PolygonFeature = GetFeatureFromFeatureCollectionByIndex(PointsPolyFeatureCollection,0);
			if (PolygonFeature){
				let PointFeatures = CreatePointFeatureFromPolygonFeature(PolygonFeature);
				console.log(PointFeatures)
				setCacheInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
		}
	}

	return (
		<APICallStatusCodeSwitcher
			StatusCode = {SubmitStatusCode}
			ChildNull = {
				<>
					<GLDrawPageContent
						StatusCode = {status_code}
						handleSubmit = {handleSubmit}
						SubmitEnabled = {SubmitEnabled}
						InputFeatureCollection={CacheInputFeatureCollection || InputFeatureCollection}
					/>
				</>
			}
			Child200 = {<SuccessScreen/>}
			ChildError = {
				<ErrorScreen
					handleClick = {() => SetSubmitStatusCode(null)}
				/>
			}
		/>
	)
}