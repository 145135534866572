import wellknown from 'wellknown';
import * as turf from '@turf/turf'
import circle from '@turf/circle';
import pointOnFeature from '@turf/point-on-feature';
import bbox from '@turf/bbox';

import { CheckNullOrUndefined } from '../Libs/EasyFnc'
import { AppDebugger } from '../App';

export const loadImage = (url) => {
	return new Promise(imageLoaded => {
		const image = new Image();
		image.onload = () => imageLoaded(image);
		image.src = url;
	});
};

export const loadIcon = async (key, background, url) => {
	const image = await loadImage(url);
	const pixelRatio = window.devicePixelRatio;

	const canvas = document.createElement('canvas');
	canvas.width = background.width * pixelRatio;
	canvas.height = background.height * pixelRatio;
	canvas.style.width = `${background.width}px`;
	canvas.style.height = `${background.height}px`;

	const context = canvas.getContext('2d');
	context.drawImage(background, 0, 0, canvas.width, canvas.height);

	const iconRatio = 0.5;
	const imageWidth = canvas.width * iconRatio;
	const imageHeight = canvas.height * iconRatio;
	context.drawImage(image, (canvas.width - imageWidth) / 2, (canvas.height - imageHeight) / 2, imageWidth, imageHeight);

	return context.getImageData(0, 0, canvas.width, canvas.height);
};

export const reverseCoordinates = it => {
	if (!it) {
		return it;
	} else if (Array.isArray(it)) {
		if (it.length === 2 && !Number.isNaN(it[0]) && !Number.isNaN(it[1])) {
			return [it[1], it[0]];
		} else {
			return it.map(it => reverseCoordinates(it));
		}
	} else {
		return {
			...it,
			coordinates: reverseCoordinates(it.coordinates),
		}
	}
}

export const geofenceToFeature = (item) => {
	if (item.area.indexOf('CIRCLE') > -1) {
		let coordinates = item.area.replace(/CIRCLE|\(|\)|,/g, " ").trim().split(/ +/);
		var options = { steps: 32, units: 'meters' };
		let polygon = circle([Number(coordinates[1]), Number(coordinates[0])], Number(coordinates[2]), options);
		return {
			type: 'Feature',
			geometry: polygon.geometry,
			properties: { name: item.name }
		};
	} else {
		return {
			type: 'Feature',
			geometry: wellknown(item.area),
			properties: { name: item.name }
		};
	}
}

export const Fly2Point = (feature) => {
	const PointFeature = pointOnFeature(feature);
	return PointFeature.geometry.coordinates
}

export const Fly2Extend = (feature) => {
	var Thisbbox = bbox(feature);
	return Thisbbox
}

export const PolyFeatureArea = (feature) => {
	if (!CheckNullOrUndefined(feature)){
		return turf.area(feature);
	} else {
		return null;
	}
}

export const PolyFeatureBoundryLength = (feature) => {
	if (!CheckNullOrUndefined(feature)){
		return turf.length(feature)*1000;
	} else {
		return null;
	}
}

export const PolyFeatureMarkerCount = (feature) => {
	if (!CheckNullOrUndefined(feature)){
		return feature.geometry.coordinates[0].length -1
	} else {
		return null;
	}
}

// Input: GeoJSON Feature or Geometry
export const PolyFeatureInPolyFeature = (OuterFeature,InnerFeature) => {
	if (!CheckNullOrUndefined(OuterFeature) && !CheckNullOrUndefined(InnerFeature) ){
		return turf.booleanContains(OuterFeature, InnerFeature);
	} else {
		return null;
	}
}

export const PointFeatureInPolyFeature = (Point,Polygon) => {
	var output = null
	if (!CheckNullOrUndefined(Point) && !CheckNullOrUndefined(Polygon) ){
		try {
			let pointCoords = turf.coordAll(Point.features[0])[0]
			output = turf.booleanPointInPolygon(pointCoords, Polygon);
		}
		catch(err) {
			console.error(err)
		}
	}
	return output
}

export const Features2FeatureCollection = (FeaturesList) => {
	if (!CheckNullOrUndefined(FeaturesList)){
		return turf.featureCollection(FeaturesList);
	} else {
		return turf.featureCollection([]);
	}
}

export const FeatureCollectionHaveFeatures = (ThisFeatureCollection) => {
	let output = false;
	if (!CheckNullOrUndefined(ThisFeatureCollection)){
		if (ThisFeatureCollection.hasOwnProperty('features')){
			if (ThisFeatureCollection.features.length > 0){
				if (ThisFeatureCollection.features[0] !== null) {
					output = true;
				}
			}
		}
	}
	return output
}

export const FeatureHaveProperties = (InputFeature) => {
	let output = false;
	if (!CheckNullOrUndefined(InputFeature)){
		if (InputFeature.hasOwnProperty('properties')){
			output = true;
		}
	}
	return output
}

export const EmptyFeatureCollection = () => {
	return {
		type: 'FeatureCollection',
		features: []
	};
}

export const GetPolygonPoints = (InputFeatureCollection) => {
	return turf.coordAll(InputFeatureCollection);
}

export const GetCoordsFromFeature = (ThisFeature) => {
	var output = null;
	if (!CheckNullOrUndefined(ThisFeature)){
		try {
			output = turf.coordAll(ThisFeature);
		}
		catch(err) {}
	}
	return output
}


export const CreateFeatureCollectionFromPolygonCoords = (PolygonCoords) => {
	let feature = turf.polygon([PolygonCoords]);
	let collection = turf.featureCollection([feature]);
	return collection
}

export const CreateFeatureFromPointCoords = (longitude,latitude) => {
	return turf.point([longitude,latitude]);
}

export const CreateFeatureFromLineCoords = (LineCoords) => {
	return turf.lineString(LineCoords);
}

export const GetFeatureFromFeatureCollectionByIndex = (ThisFeatureCollection, index) =>{
	var output = null
	if (!CheckNullOrUndefined(ThisFeatureCollection) & !CheckNullOrUndefined(index)){
		if (FeatureCollectionHaveFeatures(ThisFeatureCollection)){
			if (ThisFeatureCollection.features.length > index){
				output = ThisFeatureCollection.features[index]
			}
		}
	}
	return output
}

export const CreatePointFeatureFromPolygonFeature = (InputFeature) => {
	var output = null;

	try {
		var PointsCoords = GetCoordsFromFeature(InputFeature);
		var PointsFeature = []

		PointsCoords.forEach((item,index) => {
			PointsFeature.push(CreateFeatureFromPointCoords(item[0],item[1]))
		})

		PointsFeature.pop() // Last element in polygon is repeat of first, remove it before return
		output = PointsFeature
	}
	catch (err){
		AppDebugger.error("CreatePointFeatureFromPolygonFeature: \n" + err.message)
	}

	return output;
}

export const GetUniqueIDFromFeatureProperties = (InputFeature) => {
	var output = null
	if (!CheckNullOrUndefined(InputFeature)){
		if (FeatureHaveProperties(InputFeature)){
			output = InputFeature.properties.unique_id || false;
		}
	}
	return output
}