// Standard React components
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserConfigActions, tokenActions } from '../../ReduxStore';

// App components
import { NewPaper, NewPaperInner } from '../../Components/PageContent/Shared';

// General Functions
import { AppDebugger, MyAPIFetcher } from '../../App';
import { useCookies } from 'react-cookie';

export const Logout = () => {
	const dispatch = useDispatch();
	const [cookies, removeCookie] = useCookies(['access_token','refresh_token']);

	const handleLogout = () => {
		MyAPIFetcher.ClearTokens();
		if (cookies) {
			removeCookie("access_token");
			removeCookie("refresh_token");
		}
		dispatch(UserConfigActions.updateUser(null));
		dispatch(tokenActions.updateToken(null));
	}

	useEffect(() => {
		AppDebugger.log('Logout was triggered');
		handleLogout()
		//history.push('/login');
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<NewPaper>
			<NewPaperInner>
				<p>Clearing cache....</p>
				<p>Logging you out....</p>
			</NewPaperInner>
		</NewPaper>
	)
}
