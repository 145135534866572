// Standard React components
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';

// Material UI Components
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// General Functions
import { formatBoolean, AccessLevelText } from '../../Libs/formatter';
import t from '../../Libs/localization';
import { MyAPIFetcher, AppDebugger } from '../../App';

// App Content
import ViewAllContextMenu from '../../Components/ContextMenus/UserManagement';

const useStyles = makeStyles(theme => ({
	columnAction: {
		width: theme.spacing(1),
		padding: theme.spacing(0, 1),
	},
}));

const ViewAllPageContent = ({ updateTimestamp, onMenuClick }) => {
	const classes = useStyles();
	const [items, setItems] = useState([]);

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.GetAPI('users/all/basic');
		if (response !== null) {
			setItems(response);
		} else {
			AppDebugger.log('Failed to get Users')
			AppDebugger.log(MyAPIFetcher._access_token)
		}
	}, [updateTimestamp]);

	return (
		<TableContainer>
		<Table>
			<TableHead>
				<TableRow>
					<TableCell className={classes.columnAction} />
					<TableCell>{t('sharedName')}</TableCell>
					<TableCell>{t('userEmail')}</TableCell>
					<TableCell>{t('sharedAccessLevel')}</TableCell>
					<TableCell>{t('sharedEnabled')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((item) => (
					<TableRow key={item.user_id}>
						<TableCell className={classes.columnAction} padding="none">
							<IconButton onClick={(event) => onMenuClick(event.currentTarget, item.user_id)}>
								<MoreVertIcon />
							</IconButton>
						</TableCell>
						<TableCell>{item.first_name + " " + item.last_name}</TableCell>
						<TableCell>{item.email}</TableCell>
						<TableCell>{AccessLevelText(item.access_level)}</TableCell>
						<TableCell>{formatBoolean(item, 'enabled')}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		</TableContainer>
	);
}

const ViewAllBody = () => {
	return (
		<ViewAllContextMenu content={ViewAllPageContent} editPath="/user/edit" endpoint="users/manage" />
	)
}

export default ViewAllBody;