// Standard React
import React from "react";

// Redux
import { useDispatch } from "react-redux";
import { AppConfigActions } from "../../ReduxStore";

// Material UI
import { Button, makeStyles, useTheme } from "@material-ui/core";

// App components
import { NewPaper, NewPaperInner } from "../../Components/PageContent/Shared";
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
	logoContainer: {
		textAlign: 'center',
		maxWidth: '90%'

	},
}));

export const WelcomeScreen = () => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles();
	const [cookies, setCookie] = useCookies(['show_welcome_screen']);

	if (cookies.show_welcome_screen){
		dispatch(AppConfigActions.setShowWelcomeScreen(false));
	}

	const handleEnterClick = () => {
		dispatch(AppConfigActions.setShowWelcomeScreen(false));
		var cookie_options = {
			path: '/',
			sameSite: true
		}
		setCookie('show_welcome_screen', false, cookie_options);
	}

	return (
		<NewPaper>
			<NewPaperInner>
				<div>
					<img className={classes.logoContainer} src={`images/logo.png`} alt="" />
				</div>
				<div>
					<Button
						onClick={handleEnterClick}
						variant='contained'
						style={{
							background: theme.palette.common.green,
							color: 'white'
						}}
					>
						Enter
					</Button>
				</div>
			</NewPaperInner>
		</NewPaper>
	)
}