export const CheckNull = (InputVar) => {
	if (InputVar === null){
		return true
	} else {
		return false
	}
};

export const CheckUndefined = (InputVar) => {
	if (typeof InputVar === 'undefined'){
		return true
	} else {
		return false
	}
};

export const CheckNullOrUndefined = (InputVar) => {
	if (CheckNull(InputVar) | CheckUndefined(InputVar)){
		return true;
	} else {
		return false;
	}
};

export const CheckEmptyList = (InputList) => {
	return (InputList.length > 0 ? false : true)
}

export const isSetNotBlank = (InputVar) => {
	if (!CheckNullOrUndefined(InputVar) & InputVar !== "")
	{
		return true;
	} else {
		return false;
	}
};

export const isSetNotEmpty = (InputVar) => {
	let output = false;
	if (!CheckNullOrUndefined(InputVar))
	{
		if (!CheckEmptyList(InputVar)){
			output = true
		}
	}

	return output;
};

export const KeyExistsAndEqualTo = (InputObj,Key,Value) => {
	var output = false
	if (!CheckNullOrUndefined(InputObj)){
		if (InputObj.hasOwnProperty(Key)){
			if (InputObj[Key] === Value){
				output = true
			}
		}
	}
	return output
}

export const GetKeyIfExists = (InputObj,Key) => {
	var output = false
	if (!CheckNullOrUndefined(InputObj)){
		if (InputObj.hasOwnProperty(Key)){
			output = InputObj[Key];
		}
	}
	return output
}

export const CloneObj = (InputObj) => {
	return JSON.parse(JSON.stringify(InputObj))
}