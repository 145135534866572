// Standard React Components
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectAsync } from '../../reactHelper';

// Redux
import { useSelector } from 'react-redux';

// Material UI Components
import {
	Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../../Legacy/attributes/EditAttributesView';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';
import t from '../../Libs/localization';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import SubmitForm from '../../Components/FormControls/SubmitForm';
import SuperiorSelector from '../../Components/Selectors/Superior'

// Other
import LinkField from '../../Legacy/form/LinkField';
import userAttributes from '../../Legacy/attributes/userAttributes';
import { useAppStyles } from '../../Libs/AppStyleClasses';

const EditUserBody = ({ updateTimestamp }) => {
	const MyUserId = useSelector(state => state.UserConfig.user && state.UserConfig.user.user_id);
	const classes = useAppStyles();
	const [item, setItem] = useState();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(100)

	const { id } = useParams();
	const SelectedUserId = (id !== 'me' ? id : MyUserId)
	const access_level = useSelector(state => state.UserConfig.access_level);

	useEffectAsync( async () => {
		SetSubmitStatusCode(100)
		if (SelectedUserId){
			const response = await MyAPIFetcher.GetAPI(`users/manage/${SelectedUserId}`);
			if (response !== null) {
				setItem(response);
				SetSubmitStatusCode(null)
			} else {
				AppDebugger.log('Failed to get Users')
				SetSubmitStatusCode(404)
			}
		}
		return () => {
			SetSubmitStatusCode(100);
			setItem();
		}
	}, [updateTimestamp,SelectedUserId]);

	const handleSubmit = async () => {
		var PostObj = item
		PostObj.username = PostObj.email
		SetSubmitStatusCode(100);
		const response = await MyAPIFetcher.patch(`users/manage/${SelectedUserId}`,item);
		if (response[0]) {
			SetSubmitStatusCode(200);
		} else {
			SetSubmitStatusCode(404);
		}
	};

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<SubmitForm
						handleSubmit={handleSubmit}
						SubmitButtonText={t('sharedSave')}
					>
						{item &&
							<>
								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('sharedRequired')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<TextField
											margin="normal"
											value={item.first_name || ''}
											onChange={event => setItem({...item, first_name: event.target.value})}
											label={t('sharedName')}
											variant="filled" />
										<TextField
											margin="normal"
											value={item.last_name || ''}
											onChange={event => setItem({...item, last_name: event.target.value})}
											label={t('sharedName')}
											variant="filled" />
										<TextField
											margin="normal"
											value={item.email || ''}
											onChange={event => setItem({...item, email: event.target.value})}
											disabled={true}
											label={t('userEmail')}
											variant="filled" />
										<TextField
											margin="normal"
											type="password"
											onChange={event => setItem({...item, password: event.target.value})}
											label={t('userPassword')}
											variant="filled" />

											{ id !== 'me' &&
											<>
												<div>
													<FormControl variant="filled" fullWidth>
														<InputLabel>{t('sharedAccessLevel')}</InputLabel>
														<Select
															value={item.access_level}
															onChange={event => setItem({...item, access_level: event.target.value})}
														>
															{access_level >= 15 && <MenuItem key={0} value={0}>{t('accessLevel_0')}</MenuItem>}
															<MenuItem key={1} value={1}>{t('accessLevel_1')}</MenuItem>
															{access_level >= 10 && <MenuItem key={5} value={5}>{t('accessLevel_5')}</MenuItem>}
															{access_level >= 15 && <MenuItem key={10} value={10}>{t('accessLevel_10')}</MenuItem>}
															{access_level >= 20 && <MenuItem key={15} value={15}>{t('accessLevel_15')}</MenuItem>}
															{access_level >= 20 && <MenuItem key={20} value={20}>{t('accessLevel_20')}</MenuItem>}
														</Select>
													</FormControl>
												</div>
												{access_level >= 15 &&
													<SuperiorSelector
														UsersScope={"admins"}
														ItemLabel={t('str_onib1acb6r')}
														handleChange={(value)=>{setItem({...item, group_admin_id: value})}}
														preSelectValue={item.group_admin_id}
													/>
												}
												<SuperiorSelector
													UsersScope={"all"}
													ItemLabel={t('str_4zpvt2yskf')}
													handleChange={(value)=>{setItem({...item, superior_id: value})}}
													preSelectValue={item.superior_id}
												/>
											</>}

									</AccordionDetails>
								</Accordion>
								{ AppDebugger.isEnabled() &&
									<Accordion>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('sharedAttributes')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<EditAttributesView
												attributes={item.attributes}
												setAttributes={attributes => setItem({...item, attributes})}
												definitions={userAttributes}
												/>
										</AccordionDetails>
									</Accordion>
								}
								{item.id &&
									<Accordion>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('sharedConnections')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<LinkField
												margin="normal"
												endpointAll="/prop/child/info/?all=true"
												endpointLinked={"/api/prop/child/info/?userId=" + item.id}
												baseId={item.id}
												keyBase="userId"
												keyLink="deviceId"
												label={t('deviceTitle')}
												variant="filled" />
											<LinkField
												margin="normal"
												endpointAll="/api/groups?all=true"
												endpointLinked={"/api/groups?userId=" + item.id}
												baseId={item.id}
												keyBase="userId"
												keyLink="groupId"
												label={t('settingsGroups')}
												variant="filled" />
										</AccordionDetails>
									</Accordion>
								}
							</>
						}
					</SubmitForm>
				}
				Child200 = {<SuccessScreen
					buttonText = {t('settingsUsers')}
					buttonPath = {'/user/multiview'}
				/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
	);
}

export default EditUserBody