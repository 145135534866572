// Standard React components
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { MyGreenZoneActions } from '../../ReduxStore';

// Material UI components
import {
  FormControl, InputLabel, Select, MenuItem, Grid,
} from '@material-ui/core';

// General Functions
import t from '../../Libs/localization';
import {isSetNotBlank} from '../../Libs/EasyFnc';

const SubdivideFilter = ({ children, handleSubmit, PreSelected }) => {
	const dispatch = useDispatch();
	const [ZoneIndex,SetZoneIndex] = useState("")
	const [divisionType,SetDivisionType] = useState("")
	const features = useSelector((state) => Object.values(state.MyGreenZone.FeatureCollection.features));
	const access_level = useSelector(state => state.UserConfig.access_level);

	useEffect(() => {
    ("zone" in PreSelected) && SetZoneIndex(PreSelected['zone']);
		("type" in PreSelected) && SetDivisionType(PreSelected['type']);
  }, [handleSubmit,PreSelected]);

	useEffect(() => {
		isSetNotBlank(ZoneIndex) && dispatch(MyGreenZoneActions.select(ZoneIndex))
	},[ZoneIndex]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		(isSetNotBlank(ZoneIndex) & isSetNotBlank(divisionType)) && handleSubmit(ZoneIndex,divisionType);
	},[ZoneIndex,divisionType,handleSubmit])

  return (
		<Grid container spacing={2} justifyContent="flex-end">
			<Grid item xs={12} sm={6}>
			<FormControl variant="filled" fullWidth>
				<InputLabel>{t('sharedMyGreen')}</InputLabel>
				<Select
					value={ZoneIndex}
					onChange={(e) => SetZoneIndex(e.target.value)}
				>
					{features.map((item,index) => (
						<MenuItem key={index} value={index}>{item.properties.name}</MenuItem>
					))}
				</Select>
			</FormControl>
			</Grid>
			<Grid item xs={12} sm={6}>
			<FormControl variant="filled" fullWidth>
				<InputLabel>{t('sharedSubdivideType')}</InputLabel>
				<Select value={divisionType} onChange={(e) => SetDivisionType(e.target.value)}>
					<MenuItem value="property">{t('sharedProperty')}</MenuItem>
					{ access_level >= 5 &&
						<MenuItem value="blue">{t('sharedBlueZone')}</MenuItem>
					}

				</Select>
			</FormControl>
			</Grid>
			{children}
		</Grid>
  );
};

export default SubdivideFilter;
