// Standard React components
import React from 'react';

// Toolbars & Dialogs
import MainToolbar from '../Components/Navigation/PrimaryDrawer';

const FeedbackPage = () => {
  return (
		<div>
			<MainToolbar />
			<p>OK</p>
		</div>
  );
}

export default FeedbackPage