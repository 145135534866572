import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'UserConfig',
  initialState: {
    server: null,
    user: null,
    access_level: 0
  },
  reducers: {
    updateServer(state, action) {
      state.server = action.payload
    },
    updateUser(state, action) {
      state.user = action.payload;
      if (action.payload !== null){
        if (action.payload.hasOwnProperty('access_level')){
          state.access_level = action.payload.access_level
        } else {
          state.access_level = 0
        }
      } else {
        state.server = null
        state.user = null
        state.access_level = 0
      }
    },
  },
});

export { actions as UserConfigActions };
export { reducer as UserConfigReducer };
