// Standard React components
import React, {useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI components
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Button,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	List
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// General Functions
import { AppDebugger, MyAPIFetcher } from '../../App';
import t from '../../Libs/localization';
import { useAppStyles } from '../../Libs/AppStyleClasses';

export const DocumentList = ({InputFeature}) => {
	const history = useHistory();
	const [FeatureDocuments, setFeatureDocuments] = useState([]);

	const GetDocumentName = async (DocumentID) => {
		const response = await MyAPIFetcher.get(`document/properties/name/${DocumentID}`);
		if (response[0]) {
			if (response[1].data){
				var output = response[1].data.payload;
				output['document_id'] = DocumentID
				setFeatureDocuments(FeatureDocuments => [...FeatureDocuments, output])
			}
		} else {
			AppDebugger.log('Failed to get Document Name');
		}
	}

	useEffect(() => {
		InputFeature.forEach(element => {
			GetDocumentName(element);
		});
		return () => {
			setFeatureDocuments([]);
		}
	}, [InputFeature]);

	return (
		<>
		{FeatureDocuments.map((item) => (
			<List key={item['document_id']} >
				<ListItemText
					primary={item['name']}
				/>
				<ListItemSecondaryAction>
					<IconButton
						edge="end" aria-label="view"
						onClick={() => history.push(`/file/view/${item['document_id']}`)}
					>
						<VisibilityIcon />
					</IconButton>
					<IconButton
						edge="end" aria-label="delete"
						onClick={() => history.push(`/file/edit/properties/${item['document_id']}`)}
					>
						<EditIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</List>
		))}
		</>
	)
}

export const AddDocumentBtn = ({id,category}) => {
	const history = useHistory();
	return (
		<Button
			type='button' color='primary' variant='contained'
			onClick={() => history.push(
				{
					pathname: '/file/upload',
					state: {
						id:id,
						category:category
					}
				}
			)}
		>
			{ t('sharedAdd')}
		</Button>
	)
};

export const DocumentAccordion = ({id,category,ExistingDocumentsObj}) => {
	const classes = useAppStyles();
	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="subtitle1">
					{t('sharedDocuments')}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.FormAccordionDetails}>
				<DocumentList
					InputFeature={ExistingDocumentsObj}
				/>
				<AddDocumentBtn
					id={id}
					category={category}
				/>
			</AccordionDetails>
		</Accordion>
	)
}