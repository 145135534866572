// Standard React Components
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { MyPropertyActions } from '../../ReduxStore';

// Material UI Components
import {
	Accordion, AccordionSummary, AccordionDetails, Typography, TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';
import t from '../../Libs/localization';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import SubmitForm from '../../Components/FormControls/SubmitForm';
import BoolSelector from '../../Components/Selectors/Bool';
import PropertySelector from '../../Components/Selectors/Property';
import LessorSelector from '../../Components/Selectors/Lessor';
import LesseeSelector from '../../Components/Selectors/Lessee';
import { DOMUsingAPIFetcherUsingID } from '../../Components/PageContent/APIFetcher';

// Other
import { useAppStyles } from '../../Libs/AppStyleClasses';
import { CloneObj } from '../../Libs/EasyFnc';
import { DocumentAccordion } from '../../Components/PageContent/PropertiesEditor';

export const EditUsingId = () => {
	const [ThisObject,setThisObject] = useState();
	const [ID,setID] = useState();

	return (
		<DOMUsingAPIFetcherUsingID
			path='contract/global/info'
			setFetchedObject={setThisObject}
			setID={setID}
			Child200 = {
				<EditBody
					InputItem={ThisObject}
					UniqueID={ID}
				/>
			}
		/>
	)
}

const EditBody = ({ InputItem, UniqueID }) => {
	const classes = useAppStyles();
	const dispatch = useDispatch();
	const access_level = useSelector(state => state.UserConfig.access_level);
	const [ItemProperties, setItemProperties] = useState();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null);
	const [LockedItem, setLockedItem] = useState(false);
	const [FeatureDocuments, setFeatureDocuments] = useState([]);

	useEffect(() => {
		let ThisProperties = InputItem;
		ThisProperties.locked = ThisProperties.locked || false;
		ThisProperties.start_date = (ThisProperties.start_date && moment.unix(ThisProperties.start_date)) || moment().subtract(1, 'hour');
		ThisProperties.end_date = (ThisProperties.end_date && moment.unix(ThisProperties.end_date)) || moment().add(1, 'year');
		setLockedItem((access_level < 21 && ThisProperties.locked) || false);
		setItemProperties(ThisProperties);

		if (InputItem.hasOwnProperty('documents')){
			setFeatureDocuments(InputItem.documents);
		};

		return () => {
			setItemProperties();
			setLockedItem(false);
			SetSubmitStatusCode(null);
			setFeatureDocuments([]);
		}
	}, [InputItem, access_level]);

	const handleSubmit = async () => {
		var PostObj = CloneObj(ItemProperties);
		delete PostObj['me_index'];
		PostObj.start_date = moment(PostObj.start_date).format("X");
		PostObj.end_date = moment(PostObj.end_date).format("X");
		SetSubmitStatusCode(100);
		const response = await MyAPIFetcher.patch(`contract/manage/${UniqueID}`,PostObj);
		if (response[0]) {
			SetSubmitStatusCode(200);
			dispatch(MyPropertyActions.DoUpdate());
			AppDebugger.log('Update submitted');
			AppDebugger.dir(PostObj);
		} else {
			SetSubmitStatusCode(404);
			AppDebugger.error('Contract edit patch failed');
		}
	};

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<SubmitForm
						handleSubmit={handleSubmit}
						SubmitButtonText={t('sharedSave')}
					>
						{ ItemProperties &&
							<>
								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('str_p0ohrp4q70')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<TextField
											margin="normal"
											value={ItemProperties.name || ''}
											onChange={event => setItemProperties({...ItemProperties, name: event.target.value})}
											label={t('sharedName')}
											variant="filled" />
										<TextField
											margin="normal"
											variant="filled"
											label={t('sharedDateStart')}
											type="datetime-local"
											value={ItemProperties.start_date.format(moment.HTML5_FMT.DATETIME_LOCAL)}
											onChange={event => setItemProperties({...ItemProperties, start_date: moment(event.target.value,moment.HTML5_FMT.DATETIME_LOCAL)})}
											fullWidth />
										<TextField
											margin="normal"
											variant="filled"
											label={t('sharedDateEnd')}
											type="datetime-local"
											value={ItemProperties.end_date.format(moment.HTML5_FMT.DATETIME_LOCAL)}
											onChange={event => setItemProperties({...ItemProperties, end_date: moment(event.target.value,moment.HTML5_FMT.DATETIME_LOCAL)})}
											fullWidth />
										<PropertySelector
											ItemLabel={t('str_fwwk86o1hr')}
											handleChange={(value)=>{setItemProperties({...ItemProperties, property_unique_id: value})}}
											preSelectValue={ItemProperties.property_unique_id}
											isDisabled={LockedItem}
										/>
										<LessorSelector
											ItemLabel={t('str_enayrj5kfq')}
											handleChange={(value)=>{setItemProperties({...ItemProperties, lessor_unique_id: value})}}
											preSelectValue={ItemProperties.lessor_unique_id}
											isDisabled={LockedItem}
										/>
										<LesseeSelector
											ItemLabel={t('str_acb6ronib1')}
											handleChange={(value)=>{setItemProperties({...ItemProperties, lessee_unique_id: value})}}
											preSelectValue={ItemProperties.lessee_unique_id}
											isDisabled={LockedItem}
										/>
									</AccordionDetails>
								</Accordion>

								<DocumentAccordion
									id={UniqueID}
									category="contract"
									ExistingDocumentsObj={FeatureDocuments}
								/>

								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('sharedRequired')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<BoolSelector
											ItemLabel={t('sharedLocked')}
											handleChange={value => setItemProperties({...ItemProperties, locked: value})}
											trueLabel={null}
											falseLabel={null}
											InputValue={ItemProperties.locked || false}
											isDisabled={LockedItem}
										/>
									</AccordionDetails>
								</Accordion>
							</>
						}
					</SubmitForm>
				}
				Child200 = {<SuccessScreen
					buttonText = {t('str_2kixehhoqu')}
					buttonPath = {'/contract/multiview'}
				/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
	);
}