import React, { useState } from 'react';
import { Grid, useMediaQuery, makeStyles, InputLabel, Select, MenuItem, FormControl, Button, TextField, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { tokenActions, AppConfigActions } from '../../ReduxStore';
import t from '../../Libs/localization';
import RegisterForm from './RegisterForm';
import ResetPasswordForm from './ResetPasswordForm';
import { AppDebugger, MyAPIFetcher } from '../../App'

const useStyles = makeStyles(theme => ({
	logoContainer: {
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
	resetPassword: {
		cursor: 'pointer',
	}
}));

const forms = {
	register: () => RegisterForm,
	resetPassword: () => ResetPasswordForm,
};

const LoginForm = ({ setCurrentForm }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const theme = useTheme();

	const [failed, setFailed] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const registrationEnabled =  false; //useSelector(state => state.session.server ? state.session.server['registration'] : false);
	const [Language,SetLanguage] = useState("en")

	const handleEmailChange = (event) => {
		if (event.target.value === 'enabledev'){
			setEmail("");
			AppDebugger.EnableDebugger();
			dispatch(AppConfigActions.enableDev());
		} else if (event.target.value === 'disabledev'){
			setEmail("");
			AppDebugger.DisableDebugger();
			dispatch(AppConfigActions.disableDev());
		} else {
			setEmail(event.target.value);
		}
	}

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	}

	const handleLogin = async (event) => {
		event.preventDefault();
		const PostObj = {
			"user": {
				"username": "",
				"email": email,
				"password": password
			},
			"grant_type": "password",
			"refresh_token": ""
		}
		const response = await MyAPIFetcher.DoLogin(PostObj)
		if (response) {
			//dispatch(tokenActions.updateAccessToken(data.payload.access_token));
			dispatch(tokenActions.updateToken(response.payload));
			MyAPIFetcher.SetTokens(response.payload)
			history.push('/');
		} else {
			setFailed(true);
			setPassword('');
		}
	}

	const handleSpecialKey = (event) => {
		if (event.keyCode === 13 && email && password) {
			handleLogin(event);
		}
	};

	return (
		<Grid container direction='column' spacing={3}>
			{useMediaQuery(theme.breakpoints.down('md')) &&
				<Grid item className={classes.logoContainer}>
					<svg height="64" width="240">
						<use xlinkHref="/logo.svg#img"></use>
					</svg>
				</Grid>
			}
			<Grid item>
				<TextField
					required
					fullWidth
					error={failed}
					label={t('userEmail')}
					name='email'
					value={email}
					autoComplete='email'
					autoFocus
					onChange={handleEmailChange}
					onKeyUp={handleSpecialKey}
					helperText={failed && 'Invalid username or password'}
					variant='filled' />
			</Grid>
			<Grid item>
				<TextField
					required
					fullWidth
					error={failed}
					label={t('userPassword')}
					name='password'
					value={password}
					type='password'
					autoComplete='current-password'
					onChange={handlePasswordChange}
					onKeyUp={handleSpecialKey}
					variant='filled' />
			</Grid>
			<Grid item>
				<Button
					onClick={handleLogin}
					onKeyUp={handleEmailChange}
					variant='contained'
					color='secondary'
					disabled={!email || !password}
					fullWidth>
					{t('loginLogin')}
				</Button>
			</Grid>
			<Grid item container>
				<Grid item
					style={{ display: "none" }}
				>
					<Button onClick={() => setCurrentForm(forms.register)} disabled={!registrationEnabled} color="secondary">
						{t('loginRegister')}
					</Button>
				</Grid>
				<Grid item xs>
					<FormControl variant="filled" fullWidth>
						<InputLabel>{t('loginLanguage')}</InputLabel>
						<Select
							value={Language}
							onChange={(e) => SetLanguage(e.target.value)}
						>
							<MenuItem value="en">English</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Grid item container justifyContent="flex-end">
				<Grid item>
					<Link onClick={() => setCurrentForm(forms.resetPassword)} className={classes.resetPassword} underline="none">{t('loginReset')}</Link>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default LoginForm;
