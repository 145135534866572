// Redux
import { useSelector } from 'react-redux';

// Map Components
import {
	FeatureCollectionLayer, DefaultFeatureCollectionLayerOptions, SelectedFeatureCollectionLayer
} from './FeatureCollectionLayer';

// General Functions
import { CheckNullOrUndefined } from '../../Libs/EasyFnc';

export const DrawnFeaturesMapPolyFromPoints = ({ShowLayer}) => {
	const ThisFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);
	const id = 'DrawnFeaturesMapPolyFromPoints';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.polygon.paint = {
		'fill-color':'#4287f5',
		'fill-outline-color':'#4287f5',
		'fill-opacity':0.4
	}

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	});

  return null;
}

export const DrawnFeaturesMapAllPoints = ({ShowLayer}) => {
	const ThisFeatureCollection = useSelector(state => state.GLDraw.DrawnPointsFeatureCollection);
	const id = 'DrawnFeaturesMapAllPoints';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.point.paint = {
		'circle-radius': 8,
		'circle-color': '#bf1ff0'
	};

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

  return null;
}

export const DrawnFeaturesMapSelectedPoints = ({ShowLayer, Fly2Selected}) => {
	const ThisFeatureCollection = useSelector(state => state.GLDraw.DrawnPointsFeatureCollection);
	const id = 'DrawnFeaturesMapSelectedPoints';
	let FCOptions = DefaultFeatureCollectionLayerOptions()
	const selectedId = useSelector(state => state.GLDraw.selectedId);

	FCOptions.layers.point.paint = {
		'circle-radius': 12,
		'circle-color': '#f01fbf'
	};

	SelectedFeatureCollectionLayer({
		SourceID:id,
		FeatureCollection:ThisFeatureCollection,
		selectedId:selectedId,
		ShowLayer:ShowLayer,
		Options:FCOptions,
		Fly2Selected:Fly2Selected
	})

  return null;
}

export const BoundryPolygonFeatureMap = ({ShowLayer}) => {
	const ThisFeature = useSelector(state => state.GLDraw.BoundryPolygonFeature);
	const id = 'BoundryPolygonFeatureMap';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.polygon.paint = {
		'fill-color':'#1ff0ca',
		'fill-outline-color':'#45f01f',
		'fill-opacity':0.8,
	}

	FeatureCollectionLayer({
		SourceID:id,
		Features: CheckNullOrUndefined(ThisFeature) ? [] : [ThisFeature],
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

  return null;
}