// Radius mode
// Source:
// https://gist.github.com/chriswhong/694779bc1f1e5d926e47bab7205fa559
// custom mapbopx-gl-draw mode that modifies draw_line_string
// shows a center point, radius line, and circle polygon while drawing
// forces draw.create on creation of second vertex
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import _ from "lodash";


const MultiPointMode = _.extend({}, MapboxDraw.modes.draw_point);

MultiPointMode.onSetup = function(opts) {
  const props = MapboxDraw.modes.draw_point.onSetup.call(this, opts);

  return {
    ...props

  };
};

MultiPointMode.clickAnywhere = function(state, e) {

  return null;
};

MultiPointMode.onMouseMove = function(state, e) {

};

// creates the final geojson point feature with a radius property
// triggers draw.create
MultiPointMode.onStop = function(state) {
	//this.changeMode("simple_select", {}, { silent: true });
	//console.log('this is done')
};

MultiPointMode.toDisplayFeatures = function(state, geojson, display) {


  return null;
};

export default MultiPointMode;
