// Standard React components
import React, { useState } from 'react';

// Material UI components
import Menu from '@material-ui/core/Menu';

const ContextMenu = ({ content }) => {
	const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
	const [updateTimestamp] = useState(Date.now());

	const menuShow = (anchorId, itemId) => {
		setSelectedAnchorEl(anchorId);
	}

	const menuHide = () => {
		setSelectedAnchorEl(null);
	}

	const Content = content;
	return (
		<>
			<Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} />
			<Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
			</Menu>
		</>
	);
}

export default ContextMenu;
