// Standard React components
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';
import moment from 'moment';

// Material UI Components
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// General Functions
import { formatBoolean } from '../../Libs/formatter';
import t from '../../Libs/localization';
import { MyAPIFetcher, AppDebugger } from '../../App';

// App Content
import ViewAllContextMenu from '../../Components/ContextMenus/UserManagement';
import { LoadingDataScreen } from '../../Components/PageContent/ProcessingRequestScreens';
import { NoDataForUserScreen } from '../../Components/PageContent/RequestProcessedScreens';

const useStyles = makeStyles(theme => ({
	columnAction: {
		width: theme.spacing(1),
		padding: theme.spacing(0, 1),
	},
}));

const ViewAllPageContent = ({ updateTimestamp, onMenuClick }) => {
	const classes = useStyles();
	const [items, setItems] = useState();
	const [loaded,setLoaded] = useState(false);

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.get('contract/me/all');
		if (response[0]) {
			if (response[1].data){
				setItems(response[1].data.payload);
			}
			setLoaded(true);
		} else {
			AppDebugger.log('Failed to get Contacts Data');
			setLoaded(false);
		}
	}, [updateTimestamp]);

	return (
		<>
		{loaded
		? <>
			{items ?
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell className={classes.columnAction} />
								<TableCell>{t('sharedName')}</TableCell>
								<TableCell>{t('sharedDateStart')}</TableCell>
								<TableCell>{t('sharedDateEnd')}</TableCell>
								<TableCell>{t('sharedEnabled')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((item) => (
								<TableRow key={item.unique_id}>
									<TableCell className={classes.columnAction} padding="none">
										<IconButton onClick={(event) => onMenuClick(event.currentTarget, item.unique_id)}>
											<MoreVertIcon />
										</IconButton>
									</TableCell>
									<TableCell>{item.name}</TableCell>
									<TableCell>{(item.start_date && moment.unix(parseFloat(item.start_date)).format("DD/MM/YYYY")) || t('sharedNull')}</TableCell>
									<TableCell>{(item.end_date && moment.unix(parseFloat(item.end_date)).format("DD/MM/YYYY")) || t('sharedNull')}</TableCell>
									<TableCell>{formatBoolean(item, 'enabled')}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			:
				<>
					<NoDataForUserScreen/>
				</>
			}
		</>
		: <>
			<LoadingDataScreen/>
		</>
		}
		</>
	);
}

const ViewAllBody = () => {
	return (
		<ViewAllContextMenu content={ViewAllPageContent} editPath="/contract/edit" endpoint="contract/manage" />
	)
}

export default ViewAllBody;