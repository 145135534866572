import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { AppConfigActions } from '../ReduxStore';

import mapboxgl from 'mapbox-gl';
import { map } from './Map';

const CurrentLocationMap = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		const control = new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true,
				timeout: 5000,
			},
			trackUserLocation: true,
			showUserHeading: true
		});
		map.addControl(control);

		control.on('geolocate', function(e) {
			const GPSObj = {
				coords: {
					accuracy: e.coords.accuracy,
					altitude: e.coords.altitude,
					altitudeAccuracy: e.coords.altitudeAccuracy,
					heading: e.coords.heading,
					latitude: e.coords.latitude,
					longitude: e.coords.longitude,
				},
				timestamp: e.timestamp
			}
			dispatch(AppConfigActions.updateGeoLocation(GPSObj));
		});

		map.on('load', ()=> {
			console.log('map loaded')
			control.trigger();
		})

		return () => map.removeControl(control);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return null;
}

export default CurrentLocationMap;
