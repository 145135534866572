// Standard React Components
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';

// Material UI Components
import {
	Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';

const SuperiorSelector = ({UsersScope, ItemLabel, handleChange, preSelectValue, isDisabled}) => {
	const [AdminObj,setAdminObj] = useState([]);
	const [SelectedValue, setSelectedValue] = useState(preSelectValue || "");

	useEffectAsync(async () => {
		var url = null;
		if (UsersScope === 'all'){
			url = 'users/all/basic';
		} else if (UsersScope === 'admins'){
			url = 'users/admins/basic';
		} else {
			url = 'users/all/basic';
		}

		const response = await MyAPIFetcher.get(url);
		if (response[0]) {
			if (response[1].data){
				var UsersObj = response[1].data.payload;
				UsersObj.unshift({
					unique_id: "",
					first_name: 'None',
					last_name: ""
				});
				setAdminObj(UsersObj);
			}
		} else {
			AppDebugger.log('Failed to get Users Scope');
		}
	}, []);

	return (
		<FormControl
			variant="filled"
			margin="normal"
			fullWidth
		>
			<InputLabel>{ItemLabel}</InputLabel>
			<Select
				disabled={isDisabled}
				value={SelectedValue}
				onChange={event => {
					//setItem({...item, category: event.target.value})
					setSelectedValue(event.target.value)
					handleChange(event.target.value)
				}}
			>
				{AdminObj.map((item, index) => (
					<MenuItem key={index} value={item.user_id}>{item.first_name} {item.last_name}</MenuItem>
				))}

			</Select>
		</FormControl>
	)
}

export default SuperiorSelector