export default class CustomDebugger {
	constructor() {
		this.enabled = false;
		this.history = [];
	}

	EnableDebugger(){
		this.enabled = true;
		console.info("CustomDebugger Enabled");
	}

	DisableDebugger(){
		this.enabled = false;
		console.info("CustomDebugger Disabled");
	}

	log(output){
		if (this.enabled) {
			console.info(output);
			this.history.push(output);
		};
	}

	error(output){
		if (this.enabled) {
			console.error(output);
			this.history.push(output);
		};
	}

	dir(output){
		if (this.enabled) {
			console.dir(output);
			this.history.push(JSON.stringify(output));
		};
	}

	isEnabled(){
		return this.enabled;
	}

	getHistory(){
		return this.history;
	}
}