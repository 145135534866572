// Standard React Components
import React, { useState } from 'react';

// Material UI Components
import {
	Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';

import t from '../../Libs/localization';

const BoolSelector = ({ItemLabel, handleChange, trueLabel, falseLabel, InputValue, isDisabled}) => {
	const [SelectValue, setSelectValue] = useState(InputValue);
	return (
		<FormControl
			variant="filled"
			margin="normal"
			fullWidth
		>
			<InputLabel>{ItemLabel}</InputLabel>
			<Select
				disabled={isDisabled}
				value={SelectValue}
				onChange={event => {
					setSelectValue(event.target.value);
					handleChange(event.target.value);
				}}
			>
				<MenuItem key={0} value={true}>{trueLabel || t('sharedTrue')}</MenuItem>
				<MenuItem key={1} value={false}>{falseLabel || t('sharedFalse')}</MenuItem>
			</Select>
		</FormControl>
	)
}

export default BoolSelector