import React from 'react';

import {
	List,
	ListSubheader,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import BuildIcon from '@material-ui/icons/Build';

import t from '../../Libs/localization';
import { useHistory } from 'react-router';

export const MenuButtonIcon = BuildIcon;
export const MenuContent = ({id}) => {
	const history = useHistory();
	return (<>
		<List
			subheader={
				<ListSubheader>
					{t('sharedDocuments')}
				</ListSubheader>
			}>
		</List>
		<ListItem button onClick={() => history.push(`/file/download/${id}`)}>
			<ListItemIcon>
				<GetAppIcon />
			</ListItemIcon>
			<ListItemText primary={t('sharedDownload')} />
		</ListItem>
		<Divider />
	</>)
}