// Standard React components
import React, { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { NavigateActions } from '../../ReduxStore';

// Material UI components
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewListIcon from '@material-ui/icons/ViewList';

// App Components
import SidebarToggleButton from '../../Components/Buttons/SidebarToggleButton';
import StraightLineNavigate from '../../Map/Navigate/StraightLineNavigate';
import NavigatePointsList from '../../Components/Lists/NavigatePointsList';
import DefaultSidebar from '../../Components/Sidebars/DefaultSidebar';
import { NavigateBottom, NavigateBottomReserveSpace } from '../../Components/PageContent/Navigate';

// Map & Map Features
import {
	GetPolygonPoints, CreateFeatureFromPointCoords, Features2FeatureCollection,GetFeatureFromFeatureCollectionByIndex, GetCoordsFromFeature
} from '../../Map/mapUtil';

// General Functions
import t from '../../Libs/localization';
import { AppDebugger } from '../../App';
import { useAppStyles } from '../../Libs/AppStyleClasses';
import { CheckNullOrUndefined } from '../../Libs/EasyFnc';

const NavigateBody = ({InputFeature}) => {
	const classes = useAppStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const geolocate = useSelector(state => state.AppConfig.geolocate);
	const Distance2Target = useSelector(state => state.Navigate.Distance2Target);

	const SelectedTargetID = useSelector(state => state.Navigate.selectedId);
	const MarkersFeatureCollection = useSelector(state => state.Navigate.MarkersFeatureCollection);
	const [TargetPoint, setTargetPoint] = useState(null)

	const [SidebarCollapsed, setSidebarCollapsed] = useState(true);
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
	let isDesktop = (isTablet | isPhone) ? false : true;

	useEffect(() => { (!isDesktop) && (SelectedTargetID !== null) && setSidebarCollapsed(true)}, [isDesktop,SelectedTargetID]);

	useEffect(() => {
		if (!CheckNullOrUndefined(InputFeature)){
			const PropertyPoints = GetPolygonPoints(InputFeature)
			PropertyPoints.pop();
			var PropertyPointsFeatures = [];

			PropertyPoints.forEach((item) => {
				PropertyPointsFeatures.push(CreateFeatureFromPointCoords(item[0],item[1]));
			});
			dispatch(NavigateActions.UpdateMarkersFeatureCollection(Features2FeatureCollection(PropertyPointsFeatures)))
			dispatch(NavigateActions.NextTarget());
		}
	}, [InputFeature]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let ThisFeature = GetFeatureFromFeatureCollectionByIndex(MarkersFeatureCollection,SelectedTargetID)
		if (ThisFeature){
			let TargetPoint = GetCoordsFromFeature(ThisFeature);
			if (TargetPoint && TargetPoint[0]){
				setTargetPoint(TargetPoint[0]);
				AppDebugger.log('New target location selected, \nPoint: ' + TargetPoint[0]);
			}
		}
	}, [MarkersFeatureCollection,SelectedTargetID])

	return (
		<>
			<div style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',}}
			>
				<StraightLineNavigate
					InputTargetPoint={TargetPoint}
				/>
				<NavigateBottomReserveSpace/>
			</div>
			<NavigateBottom
				InputValues = {{
					ValidGeolocate: geolocate.isValid,
					ValidTarget: TargetPoint,
					Timestamp: geolocate.timestamp,
					Accuracy: geolocate.coords.accuracy,
					altitudeAccuracy: geolocate.coords.altitudeAccuracy,
					heading: geolocate.coords.heading,
					latitude: geolocate.coords.latitude,
					longitude: geolocate.coords.longitude,
					Distance: Distance2Target
				}}
			/>
			<div className={classes.sidebarToggleContainer}>
				<SidebarToggleButton
					handleClick = { () => setSidebarCollapsed(!SidebarCollapsed) }
					ThisIcon = { <ViewListIcon /> }
					ThisHeader = { t('str_xkwtije5yk') }
				/>
			</div>
			<div>
				<DefaultSidebar
					isCollapsed={SidebarCollapsed}
					handleDoCollapse={() => {
						setSidebarCollapsed(true)
					}}
					SidebarHeader = { t('str_xkwtije5yk') }
					ItemList = {
						<NavigatePointsList/>
					}
				/>
			</div>
		</>
	);
};

export default NavigateBody;