// Standard React components
import React from 'react';
import { useHistory } from 'react-router-dom';

// Material UI components
import { Button, Fab } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AddIcon from '@material-ui/icons/Add';

// General Functions
import { useAppStyles } from '../../Libs/AppStyleClasses';
import t from '../../Libs/localization';

export const FloatingBackButton = () => {
	const history = useHistory();
	const classes = useAppStyles();

	return (
	<div className={classes.FloatingButtonBack}>
		<Button
			variant="contained"
			color={'secondary'}
			classes={{ containedPrimary: classes.sidebarToggleBg }}
			className={classes.sidebarToggle}
			onClick={ () => {history.goBack()} }
			disableElevation
		>
			<KeyboardBackspaceIcon/>
			{t('sharedBack')}
		</Button>
	</div>
	)
}

export const FloatingAddButton = ({handleClick}) => {
	const classes = useAppStyles();

	return (
	<div className={classes.FloatingButtonAdd}>
		<Fab size="medium" color="primary" onClick={handleClick}>
			<AddIcon />
		</Fab>
	</div>
	)
}