// Standard React Components
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// React PDF
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack'

// App components
import StatusCodeSwitcher, { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { AppDebugger, MyAPIFetcher } from '../../App';
import { NewPaper, NewPaperInner } from '../../Components/PageContent/Shared';

// General Functions
import t from '../../Libs/localization';

// Material Ui
import { Button, makeStyles } from '@material-ui/core';

const url = '/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = url;

export const DownloadPDFPageUsingId = ({setID}) => {
	const { id } = useParams();
	const [GetDocmanIdStatusCode, setGetDocmanIdStatusCode] = useState(100);
	const [PDFPath, setPDFPath] = useState()

	const GetDocmanId = async (DocumentID) => {
		const response = await MyAPIFetcher.get(`document/properties/docman_id/${DocumentID}`);
		if (response[0]) {
			if (response[1].data){
				var output = response[1].data.payload;
				setPDFPath({
					url: process.env.REACT_APP_JC2Docman_URL + 'action/download/' + output['id'],
					httpHeaders: { 'Token': output['token'] },
					filename: (output['name'] || 'Document') + '.pdf'
				})

				setGetDocmanIdStatusCode(200);
			} else {
				setGetDocmanIdStatusCode(400);
			}
		} else {
			AppDebugger.log('Failed to get Docman ID')
			setGetDocmanIdStatusCode(400);
		}
	}

	useEffect(() => {
		GetDocmanId(id);
		{ setID && setID(id)} //eslint-disable-line no-lone-blocks

		return () => {
			setGetDocmanIdStatusCode(100)
			{ setID && setID()} //eslint-disable-line no-lone-blocks
		}
	}, [id, setID])

	return (
		<APICallStatusCodeSwitcher
			StatusCode={GetDocmanIdStatusCode}
			Child200={
				<DownloadFileBtn
					PDFPath={PDFPath}
				/>
			}
		/>

	);
}

const useStyles = makeStyles(() => ({
	center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonContainer : {
		maxWidth: 400,
	}
}));

export const DownloadFileBtn = ({PDFPath}) => {
	const history = useHistory();
	const classes = useStyles();
	const [StatusCode, setStatusCode] = useState(100)

	const DownloadFile = (PDFPath) => {
		if (PDFPath){
			fetch(PDFPath['url'], {
				method: 'GET',
				headers: {
					'Content-Type': 'application/pdf',
					'Token': PDFPath['token'],
				},
			})
			.then((response) => {
				if (response.ok){
					response.blob()
						.then((blob) => {
							console.log(blob)
							// Create blob link to download
							const url = window.URL.createObjectURL(
								new Blob([blob]),
							);
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute(
								'download',
								PDFPath['filename'],
							);

							// Append to html link element page
							document.body.appendChild(link);

							// Start download
							link.click();

							// Clean up and remove the link
							link.parentNode.removeChild(link);
							setStatusCode(200);
						})
				} else {
					setStatusCode(400);
					throw new Error('Invalid Docman response');
				}
			})
			.catch(function(error){
				console.log('Document loading failed');
				AppDebugger.error(error);
			});
		}
	}

	return (
		<>
			<NewPaper>
				<NewPaperInner>
					<h3>{t('str_0rj5jfakac')}</h3>
					<div className={classes.center}>
						<div className={classes.buttonContainer}>
							<StatusCodeSwitcher
								StatusCode={StatusCode}
								Child100 = {
									<Button
										onClick = {() => DownloadFile(PDFPath)}
										color="secondary"
										variant='contained'
										fullWidth
									>
										{t('str_uel1w33paw')}
									</Button>
								}
								Child200 = {<h2>{t('str_9new3mtf62')}</h2>}
								ChildError = {
									<>
										<h2>{t('sharedRemoveFailed')}</h2>
										<p>{t('sharedCheckInternetAndTryAgain')}</p>
									</>
								}
							/>

							<Button
								onClick={() => history.goBack()}
								variant='contained'
								color='primary'
								fullWidth
							>
								{ t('sharedGo') + t('sharedBack')}
							</Button>
							<Button
								onClick={() => history.push('/')}
								variant='contained'
								color='primary'
								fullWidth
							>
								{ t('sharedGo') + t('homeTitle')}
							</Button>
						</div>
					</div>
				</NewPaperInner>
			</NewPaper>
		</>
	)
}