// Standard React components
import React, { useState } from 'react';

// App Components
import { PrimaryPageWrapper } from '..';
import {
	UploadPDFView
} from '../../Components/PageContent/FileExplorer';
import MyDocument from './createPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { EditUsingId, RemoveUsingIndex } from './editProperties'
import { MenuContent, MenuButtonIcon } from '../../Components/Navigation/FileExplorerMenu';
import { ViewPDFPageUsingId } from './viewPDF';
import { DownloadPDFPageUsingId } from './downloadPDF';
import { GeneratePDFReqPageUsingTypeAndId } from './generatePDF';

export const ViewPDFPage = () => {
	const [id, setID] = useState();

	return (
		<PrimaryPageWrapper
			showBack={true}
			PageMenuContent={<MenuContent id={id}/>}
			PageMenuButtonIcon={<MenuButtonIcon/>}
		>
			<ViewPDFPageUsingId
				setID={setID}
			/>
		</PrimaryPageWrapper>
	);
}

export const UploadPDFPage = () => {
	return (
		<PrimaryPageWrapper>
			<UploadPDFView/>
		</PrimaryPageWrapper>
	);
}

export const CreatePDFPage = () => {
	return (
		<PrimaryPageWrapper>
			<PDFViewer>
				<MyDocument/>
			</PDFViewer>
			<PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
				{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
			</PDFDownloadLink>
		</PrimaryPageWrapper>
	);
}

export const EditPDFProperties = () => {
	return (
		<PrimaryPageWrapper>
			<EditUsingId/>
		</PrimaryPageWrapper>
	);
}

export const DownloadPDFPage = () => {
	return (
		<PrimaryPageWrapper>
			<DownloadPDFPageUsingId/>
		</PrimaryPageWrapper>
	);
}

export const RemovePDFPage = () => {
	return (
		<PrimaryPageWrapper	>
			<RemoveUsingIndex/>
		</PrimaryPageWrapper>
	);
}

export const PDFGenerateReqPage = () => {
	return (
		<PrimaryPageWrapper>
			<GeneratePDFReqPageUsingTypeAndId/>
		</PrimaryPageWrapper>
	);
}