// Standard React components
import { useEffect, useState } from 'react';

// Map Components
import { map } from '../Map';
import { Fly2Extend } from '../mapUtil';

// General Functions
import { CheckNullOrUndefined, CheckEmptyList } from '../../Libs/EasyFnc'

export const DefaultFeatureCollectionLayerOptions = (LayerTypesList = ["polygon","line","point"]) => {
	let DefaultJSON = {
		layers : {},
		Fly2Feature : {
			enabled : false,
			options : { padding: 20 },
			only_when_shown: true,
		}
	};

	if (LayerTypesList.includes("polygon")){
		DefaultJSON.layers.polygon = {
			'paint': {
				'fill-color':'#66D03B',
				'fill-outline-color':'#66D03B',
				'fill-opacity':0.1,
			}
		};
	};
	if (LayerTypesList.includes("line")){
		DefaultJSON.layers.line = {
			'paint': {
				'line-color': '#66D03B',
				'line-width': 2,
			}
		};
	};
	if (LayerTypesList.includes("point")){
		DefaultJSON.layers.point = {
			'paint': {
				'circle-radius': 6,
				'circle-color': '#B42222'
			}
		};
	};
	return DefaultJSON
}

export const FeatureCollectionLayer = ({SourceID, Features, ShowLayer=true, Options}) => {
	var LayerIDs = [];
	const LayerTypes = Object.keys(Options.layers);
	LayerTypes.map((item) => LayerIDs.push(SourceID + "-" + item));

	useEffect(() => {
		map.addSource(SourceID, {
			'type': 'geojson',
			'data': {
				type: 'FeatureCollection',
				features: []
			}
		});

		if (LayerTypes.includes("polygon")) {
			map.addLayer({
				'source': SourceID,
				'id': SourceID + '-polygon',
				'type': 'fill',
				'filter': [
					'all',
					['==', '$type', 'Polygon'],
				],
				'paint': Options.layers.polygon.paint,
			});
		};

		if (LayerTypes.includes("line")) {
			if (Options.layers.line.hasOwnProperty('symbol')){
				map.addLayer({
					'source': SourceID,
					'id': SourceID + '-line',
					'type': 'symbol',
					'layout': Options.layers.line.symbol,
					//'filter': ['==', '$type', 'LineString'],
				});
			} else {
				map.addLayer({
					'source': SourceID,
					'id': SourceID + '-line',
					'type': 'line',
					'paint': Options.layers.line.paint,
					//'filter': ['==', '$type', 'LineString'],
				});
			}
		};

		if (LayerTypes.includes("point")) {
			if (Options.layers.point.hasOwnProperty('symbol')){
				map.addLayer({
					'source': SourceID,
					'id': SourceID + '-point',
					'type': 'symbol',
					'layout': Options.layers.point.symbol,
					'filter': ['==', '$type', 'Point']
				});
			}	else {
				map.addLayer({
					'source': SourceID,
					'id': SourceID + '-point',
					'type': 'circle',
					'paint': Options.layers.point.paint,
					'filter': ['==', '$type', 'Point']
				});
			}
		};

		return () => {
			LayerIDs.forEach((item) => {
				if (map.getLayer(item)) {
					map.removeLayer(item);
				}
			});
			if (map.getSource(SourceID)) {
				map.removeSource(SourceID);
			}
		};
	}, [SourceID]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (Features !== null){
			map.getSource(SourceID).setData({
				type: 'FeatureCollection',
				features: Features
			});
		}
	}, [Features,SourceID]);

	useEffect(() => {
		LayerIDs.forEach((LayerID) => {
			if (map.getLayer(LayerID)){
				if (CheckNullOrUndefined(ShowLayer) | ShowLayer) {
					map.setLayoutProperty(
						LayerID,
						"visibility",
						"visible"
					);
				} else {
					map.setLayoutProperty(
						LayerID,
						"visibility",
						"none"
					);
				};
			};
		});
	}, [ShowLayer,LayerIDs]);

	useEffect(() => {
		if (Options.Fly2Feature.enabled) {
			if (Options.Fly2Feature.only_when_shown & !ShowLayer){
				return null;
			}
			if (!CheckEmptyList(Features)){
				if (!CheckNullOrUndefined(Features[0])) {
					let FeatureType = Features[0].geometry.type
					if (FeatureType === 'Point'){
						map.flyTo({
							center: Features[0].geometry.coordinates,
							essential: true,
							zoom: Math.max(...[map.getZoom(), 9])
						})
					} else {
						const bbox = Fly2Extend(Features[0])
						if (bbox && Options.Fly2Feature.enabled) {
							map.fitBounds(bbox, Options.Fly2Feature.options);
						}
					}
				};
			};
		};
	}, [Options,Features,ShowLayer]);

  return null;
}

export const SelectedFeatureCollectionLayer = ({SourceID, FeatureCollection, ShowLayer, Options, selectedId, Fly2Selected}) => {
	const [ThisFeature,setThisFeature] = useState([])

	if (Fly2Selected){
		Options.Fly2Feature.enabled = true;
	}

	useEffect(() => {
		setThisFeature([])
		if (selectedId !== null){
			if (FeatureCollection.features.length > selectedId){
				setThisFeature([FeatureCollection.features[selectedId]]);
			}
		}
	}, [FeatureCollection,selectedId]);

	FeatureCollectionLayer({
		SourceID:SourceID,
		Features:ThisFeature,
		ShowLayer:ShowLayer,
		Options:Options
	})
}