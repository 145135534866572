import React, {  } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import t from '../../Libs/localization';
import { useHistory } from "react-router-dom";

import { useCookies } from 'react-cookie';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
    background: theme.palette.common.purple,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('md')]: {
      width: theme.dimensions.tabletSidebarWidth,
    },
    [theme.breakpoints.down('xs')]: {
      width: '0px',
    },
  },
  paper: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 25, 0, 0)
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: "100%",
  },
}));

const ErrorPage = () => {
  // eslint-disable-next-line
  const [cookies, removeCookie] = useCookies(['access_token','refresh_token']);
  removeCookie("access_token");
  removeCookie("refresh_token");

  const classes = useStyles();
  const history = useHistory();

  const routeChange = () =>{
    let path = `/`;
    history.push(path);
  }

  return (
    <main className={classes.root}>
      <Paper className={classes.paper}>
        <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            App is offline
        </Alert>
        <Button type='button' color='primary' variant='outlined' onClick={routeChange}>
              {t('btnReloadApp')}
        </Button>
      </Paper>
    </main>
  )
}
export default ErrorPage;
