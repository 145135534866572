// Standard React components
import React from 'react';

import { AppDebugger } from '../../App';

const OnScreenDebugger = () => {
	const DebugHistory = AppDebugger.getHistory();
	return (
		<div style={{
			overflowY: 'visible',
			overflowX: 'none'
		}}>
			{DebugHistory.map((item, index) => (
				<p key={index}>{item}</p>
			))}
		</div>
	)
}

export default OnScreenDebugger