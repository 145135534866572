// Standard React components
import React from 'react';

// App Components
import SubdivideFilter from '../../Components/Filters/MyGreenZoneSubdivideFilter';

// Material UI components
import {
	Accordion, AccordionDetails, AccordionSummary, Container, Typography
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// General Functions
import { useAppStyles } from '../../Libs/AppStyleClasses'
import t from '../../Libs/localization';

const MyGreenZoneAccordionContainer = ({ Expanded, handleExpand, PreSelected, handleSubmit }) => {
	const classes = useAppStyles();
	return (
		<Container maxWidth="sm" className={classes.controlPanel}>
			<div>
				<Accordion expanded={Expanded} onChange={handleExpand}>
				<AccordionSummary expandIcon={<ExpandLessIcon />}>
					<Typography align='center'>
						{t('reportConfigure')}
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.configForm}>
					<SubdivideFilter
						handleSubmit={handleSubmit}
						PreSelected={PreSelected}
					/>
				</AccordionDetails>
				</Accordion>
			</div>
		</Container>
	)
}

export default MyGreenZoneAccordionContainer