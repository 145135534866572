// Standard React Components
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { AlphaZoneActions } from '../../ReduxStore';

// Material UI Components
import {
	Accordion, AccordionSummary, AccordionDetails, Typography, Button, TextField, InputLabel
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';
import t from '../../Libs/localization';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import SubmitForm from '../../Components/FormControls/SubmitForm';
import BoolSelector from '../../Components/Selectors/Bool';
import { DOMUsingAPIFetcherUsingID } from '../../Components/PageContent/APIFetcher';

// Other
import { FeatureFromFeatureCollectionUsingIndex } from '../../Components/PageContent/FeatureCollection';
import { useAppStyles } from '../../Libs/AppStyleClasses';
import { DocumentAccordion } from '../../Components/PageContent/PropertiesEditor';

export const EditUsingIndex = () => {
	const ThisFeatureCollection = useSelector(state => state.AlphaZone.FeatureCollection);
	const [ThisFeature,setThisFeature] = useState();

	return (
		<FeatureFromFeatureCollectionUsingIndex
			ThisFeatureCollection={ThisFeatureCollection}
			Child200={
				<EditBody
				InputItem={ThisFeature}
				UniqueID={ThisFeature && ThisFeature.properties && ThisFeature.properties.unique_id}
				/>
			}
			setThisFeature={setThisFeature}
		/>
	)
}

export const EditUsingId = () => {
	const [ThisObject,setThisObject] = useState();
	const [ID,setID] = useState();

	return (
		<DOMUsingAPIFetcherUsingID
			path='alpha/global/info'
			setFetchedObject={setThisObject}
			setID={setID}
			Child200 = {
				<EditBody
					InputItem={ThisObject}
					UniqueID={ID}
				/>
			}
		/>
	)
}

const EditBody = ({ InputItem, updateTimestamp, UniqueID }) => {
	const classes = useAppStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const access_level = useSelector(state => state.UserConfig.access_level);
	const [FeatureProperties, setFeatureProperties] = useState();
	const [FeatureDocuments, setFeatureDocuments] = useState([]);
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null);
	const [LockedFeature, setLockedFeature] = useState(false)

	useEffect(() => {
		let ThisProperties = InputItem.properties;
		ThisProperties.locked = ThisProperties.locked || false;
		setLockedFeature((access_level < 20 && ThisProperties.locked) || false);
		setFeatureProperties(ThisProperties);

		if (InputItem.hasOwnProperty('documents')){
			setFeatureDocuments(InputItem.documents);
		}

		return () => {
			setFeatureProperties();
			setLockedFeature(false);
			SetSubmitStatusCode(null);
			setFeatureDocuments([]);
		}
	}, [InputItem, access_level])

	const handleSubmit = async () => {
		var PostObj = FeatureProperties
		delete PostObj['me_index'];
		SetSubmitStatusCode(100);
		const response = await MyAPIFetcher.patch(`alpha/manage/properties/${UniqueID}`,PostObj);
		if (response[0]) {
			SetSubmitStatusCode(200);
			dispatch(AlphaZoneActions.DoUpdate())
			AppDebugger.log('Update submitted');
			AppDebugger.dir(PostObj);
		} else {
			SetSubmitStatusCode(404);
			AppDebugger.error('Alpha edit patch failed');
		}
	};

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<SubmitForm
						handleSubmit={handleSubmit}
						SubmitButtonText={t('sharedSave')}
					>
						{ FeatureProperties &&
							<>
								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('sharedRequired')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<TextField
											margin="normal"
											value={FeatureProperties.name || ''}
											onChange={event => setFeatureProperties({...FeatureProperties, name: event.target.value})}
											label={t('sharedName')}
											variant="filled" />
										<TextField
											margin="normal"
											value={FeatureProperties.description || ''}
											onChange={event => setFeatureProperties({...FeatureProperties, description: event.target.value})}
											label={t('sharedDescription')}
											variant="filled" />
										<BoolSelector
											ItemLabel={t('sharedLocked')}
											handleChange={value => setFeatureProperties({...FeatureProperties, locked: value})}
											trueLabel={null}
											falseLabel={null}
											InputValue={FeatureProperties.locked || false}
											isDisabled={LockedFeature}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('sharedMap')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<Button
											type='button' color='primary' variant='contained'
											onClick={() => history.push(`/alpha/global/edit/map/${UniqueID}`)}
											disabled={LockedFeature}
										>
											{ t('sharedEdit')}
										</Button>
										{LockedFeature && <InputLabel>{t('str_a0an0qwtvf')}</InputLabel>}
									</AccordionDetails>
								</Accordion>
								<DocumentAccordion
									id={UniqueID}
									category="alpha"
									ExistingDocumentsObj={FeatureDocuments}
								/>
							</>
						}
					</SubmitForm>
				}
				Child200 = {<SuccessScreen/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
	);
}