// Standard React components
import React from 'react';

// Material UI components
import {Button} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// General Functions
import { useAppStyles } from '../../Libs/AppStyleClasses';

const SidebarToggleButton = ({ handleClick , ThisIcon, ThisHeader, Properties }) => {
	const classes = useAppStyles();
	const theme = useTheme();
	const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

	return (
	<div>
		<Button
			variant="contained"
			color={isPhone ? 'secondary' : 'primary'}
			classes={{ containedPrimary: classes.sidebarToggleBg }}
			className={classes.sidebarToggle}
			onClick={ handleClick }
			disableElevation
			disabled = { Properties !== undefined && (Properties.hasOwnProperty('disabled') ? Properties.disabled : false)}
		>
			{ ThisIcon }
			<div className={classes.sidebarToggleText}>{ ThisHeader }</div>
		</Button>
	</div>
	)
}

export default SidebarToggleButton