import React, { useState } from 'react';
import {
	TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import t from '../../Libs/localization';
import { useEffectAsync } from '../../reactHelper';

import { formatBoolean } from '../../Libs/formatter';

import { MyAPIFetcher, AppDebugger } from '../../App';

const useStyles = makeStyles(theme => ({
	columnAction: {
		width: theme.spacing(1),
		padding: theme.spacing(0, 1),
	},
}));

const AssignGreen = ({ updateTimestamp, handleClick, CurrentGreen }) => {
	const classes = useStyles();

	const [items, setItems] = useState([]);

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.GetAPI('users/all/basic');
		if (response !== null) {
			setItems(response);
		} else {
			AppDebugger.log('Failed to get Users')
		}
	}, [updateTimestamp]);

	return (
		<TableContainer>
		<Table>
			<TableHead>
				<TableRow>
					<TableCell className={classes.columnAction} />
					<TableCell>{t('sharedName')}</TableCell>
					<TableCell>{t('userEmail')}</TableCell>
					<TableCell>{t('sharedGreenZones_Current')}</TableCell>
					<TableCell>{t('sharedGreenZones_Assign')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((item) => (
					<TableRow key={item.user_id}>
						<TableCell className={classes.columnAction} padding="none">
						</TableCell>
						<TableCell>{item.first_name + " " + item.last_name}</TableCell>
						<TableCell>{item.email}</TableCell>
						<TableCell>{item.user_id === CurrentGreen ? formatBoolean(true) : formatBoolean(false)}</TableCell>
						<TableCell padding="none">
							<IconButton onClick={(event) => handleClick(item.user_id)}>
								<AddCircleIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		</TableContainer>
	);
}

export default AssignGreen