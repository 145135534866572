// Standard React components
import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Material UI Components
import {
	TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';

// General Functions
import t from '../../Libs/localization';

export const AddCoordinatesManuallyDialog = ({ handleSubmit, handleCancel, isOpen }) => {
	const [Latitude, setLatitude] = useState(25.874813144354732)
	const [Longitude, setLongitude] = useState(-26.359188097798324)

	const handleClose = async (option) => {
		setLatitude(25)
		setLongitude(-26)
	};
	return (
		<div>
		<Dialog
			open={isOpen}
			onClose={() => handleClose(0)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t('str_lxi2i22ksj')}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{t('str_yorbzis5os')}</DialogContentText>
				<div>
					<TextField
						margin="normal"
						value={Latitude || ''}
						onChange={event => setLatitude(event.target.value)}
						label={t('sharedLatitude')}
						variant="filled" />
				</div>
				<div>
					<TextField
						margin="normal"
						value={Longitude || ''}
						onChange={event => setLongitude(event.target.value)}
						label={t('sharedLongitude')}
						variant="filled" />
				</div>
			</DialogContent>
			<DialogActions>
			<Button
				onClick={() => handleSubmit([Latitude,Longitude])}
				color="primary"
			>
				{t('sharedAdd')}
			</Button>
			<Button
				onClick={() => handleCancel()}
				color="primary"
			>
				{t('sharedCancel')}
			</Button>
			</DialogActions>
		</Dialog>
		</div>
	);
}

export const AddAddCoordinatesGPSDialog = ({ handleSubmit, handleCancel, isOpen }) => {
	const geolocate = useSelector(state => state.AppConfig.geolocate.timestamp && state.AppConfig.geolocate);
	return (
		<div>
		<Dialog
			open={isOpen}
			onClose={() => {console.log('handle close')}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{t('str_2p06u7qavx')}</DialogTitle>
			<DialogContent>
				{geolocate
				? (<>
						<DialogContentText id="alert-dialog-description">{t('str_6opxeakfa9')}</DialogContentText>
						<DialogContentText id="alert-dialog-description-2">{`latitude: ${geolocate.coords.latitude}`}</DialogContentText>
						<DialogContentText id="alert-dialog-description-3">{`longitude: ${geolocate.coords.longitude}`}</DialogContentText>
						<DialogContentText id="alert-dialog-description-4">{`accuracy: ${geolocate.coords.accuracy}`}</DialogContentText>
					</>)
				: <DialogContentText id="alert-dialog-description2">{t('str_uwc5p9xue5')}</DialogContentText>
				}
			</DialogContent>
			<DialogActions>
			{geolocate &&
				<Button
					onClick={() => handleSubmit([geolocate.coords.longitude,geolocate.coords.latitude])}
					color="primary"
				>
					{t('sharedAdd')}
				</Button>
			}
			<Button
				onClick={() => handleCancel()}
				color="primary"
			>
				{t('sharedCancel')}
			</Button>
			</DialogActions>
		</Dialog>
		</div>
	)
}