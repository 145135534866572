import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'token',
  initialState: {
    access_token: null,
    access_token_expire: null,
    refresh_token: null,
    refresh_token_expire: null,
    token_type: 'Bearer',

    user: null,
    auth_headers: {
      'Content-Type': 'application/json',
      'Authorization': null
    }
  },
  reducers: {
    updateToken(state,action){
      if (action.payload == null){
        state.refresh_token = null
        state.refresh_token_expire = null
        state.access_token_expire = null
        state.access_token = null
      }
      else{
        if (action.payload.expires_in != null) {
          state.access_token_expire = Date.now() + (action.payload.expires_in*1000*5) // Check KeyCloak
        } else {
          state.access_token_expire = null
        }
        if (action.payload.refresh_token_expire != null) {
          state.refresh_token_expire = Date.now() + (action.payload.refresh_token_expire*1000)
        } else {
          state.refresh_token_expire = null
        }
        state.refresh_token = action.payload.refresh_token
        state.access_token = action.payload.access_token
      }

      var new_headers = state.auth_headers;
      new_headers['Authorization'] = state.token_type + " " + state.access_token;
      state.auth_headers = new_headers;
    },
    updateAccessToken(state, action) {
      var new_headers = state.auth_headers;
      new_headers['Authorization'] = state.token_type + " " + action.payload;
      state.auth_headers = new_headers;

      state.access_token = action.payload;
    },
    updateTokenType(state, action) {
      state.token_type = action.payload;
    },
    updateUser(state, action) {
      state.user = action.payload;
      console.log(action.payload)
    },
  },
});

export { actions as tokenActions };
export { reducer as tokenReducer };