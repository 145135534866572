// Standard React components
import React from 'react';

// Material UI components
import {
	LinearProgress,
	makeStyles,
	useTheme
} from '@material-ui/core';

// General Functions
import t from '../../Libs/localization';

export const LoadingScreen = () => {
	const classes = CenterLoadingClasses();
	return (
		<>
			<div className={`${classes.coverDiv}`}>
				<div>
					<LinearProgress/>
					<div className={`${classes.coverDiv} ${classes.center}`}>
						<img src={'/images/page-loading.gif'} alt={t("sharedLoading")}/>
					</div>
				</div>
			</div>
		</>
	)
}

export const SubmittingScreen = () => {
	const classes = CenterLoadingClasses();
	return (
		<>
			<div className={`${classes.coverDiv}`}>
				<div>
					<LinearProgress/>
					<div className={`${classes.coverDiv} ${classes.center}`}>
						<img src={'/images/285.gif'} alt={t("sharedLoading")}/>
					</div>
				</div>
			</div>
		</>
	)
}

const CenterLoadingStyles = makeStyles((theme) => ({
	coverDiv: {
		position: 'absolute',
		top: 0,
		bottom:0,
		left: 0,
		right: 0,
	},
	absoluteTop: {
		zIndex: theme.zIndex.drawer + 1,
	},
	shaded: {
		backgroundColor: 'white',
		opacity: 0.8
	},
	center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const CenterLoadingClasses = () => {
	const theme = useTheme();
	const classes = CenterLoadingStyles(theme);
	return classes
}

export const LoadingMapStyleScreen = () => {
	const classes = CenterLoadingClasses();
	return (
		<>
			<div className={`${classes.coverDiv} ${classes.absoluteTop}`}>
				<div className={`${classes.coverDiv} ${classes.shaded}`}></div>
				<div>
					<LinearProgress/>
					<div className={`${classes.coverDiv} ${classes.center}`}>
						<img src={'/images/285.gif'} alt={t("sharedLoading")}/>
					</div>
				</div>
			</div>
		</>
	)
}

export const LoadingDataScreen = () => {
	const classes = CenterLoadingClasses();
	return (
		<>
			<div className={`${classes.coverDiv} ${classes.absoluteTop}`}>
				<div>
					<LinearProgress/>
					<div className={`${classes.coverDiv} ${classes.center}`}>
						<img src={'/images/285.gif'} alt={t("sharedLoading")}/>
					</div>
				</div>
			</div>
		</>
	)
}

export const BuildingDataScreen = ({additionalText}) => {
	const classes = CenterLoadingClasses();
	return (
		<>
			<div className={`${classes.coverDiv} ${classes.absoluteTop}`}>
				<div>
					<LinearProgress/>
					<div className={`${classes.coverDiv} ${classes.center}`}>
						<div>
							<p className={classes.center} ><img src={'/images/1493.gif'} alt={t("sharedLoading")}/></p>
							<br/>
							<p>{additionalText}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}