// Standard React components
import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Pages & Page Body
import { PrimaryPageWrapper } from '../'
import { BlueZoneBody } from './BlueZoneBody'
import {
	MyPropertyZoneInfoBodyUsingIndex,
	AlphaZoneInfoBodyUsingIndex,
	MyGreenZoneInfoBodyUsingIndex,
	BlueZoneInfoBodyUsingIndex,
	ZoneInfoBodyUsingId
} from './ZoneBody';

// App Components
import StatusCodeSwitcher from '../../Components/PageContent/StatusCodeSwitcher';
import {
	ViewMyProperty
} from '../../Components/PageContent/PropertiesViewer';
import { MenuContent, MenuButtonIcon } from '../../Components/Navigation/InfoPageMenu'; // eslint-disable-line no-unused-vars

export const MyProperty = () => {
	const StatusCode = useSelector(state => state.MyProperty.status_code);
	return (
		<PrimaryPageWrapper
			showBack={true}
		>
			<StatusCodeSwitcher
				StatusCode = {StatusCode}
				Child200 = {<ViewMyProperty/>}
			/>
		</PrimaryPageWrapper>
	);
}

export const MyPropertyIndex = () => {
	const [id, setID] = useState(); // eslint-disable-line no-unused-vars
	return (
		<PrimaryPageWrapper
			showBack={true}
		>
			<MyPropertyZoneInfoBodyUsingIndex
				setID={setID}
			/>
		</PrimaryPageWrapper>
	);
}

export const GlobalPropertyInfo = () => {
	return (
		<PrimaryPageWrapper
			showBack={true}
		>
			<ZoneInfoBodyUsingId/>
		</PrimaryPageWrapper>
	);
}

export const AlphaZoneIndex = () => {
	return (
		<PrimaryPageWrapper
			showBack={true}
		>
			<AlphaZoneInfoBodyUsingIndex/>
		</PrimaryPageWrapper>
	);
}

export const BlueZoneIndex = () => {
	return (
		<PrimaryPageWrapper
			showBack={true}
		>
			<BlueZoneInfoBodyUsingIndex/>
		</PrimaryPageWrapper>
	);
}

export const MyGreenZoneIndex = () => {
	return (
		<PrimaryPageWrapper
			showBack={true}
		>
			<MyGreenZoneInfoBodyUsingIndex/>
		</PrimaryPageWrapper>
	);
}


export const BlueZone = () => {
	const StatusCode = useSelector(state => state.BlueZone.status_code);
	return (
		<PrimaryPageWrapper
			showBack={true}
		>
			<StatusCodeSwitcher
				StatusCode = {StatusCode}
				Child200 = {<BlueZoneBody/>}
			/>
		</PrimaryPageWrapper>
	);
}