import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'ChildPropsPoints',
  initialState: {
    items: {},
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach(item => state.items[item['ChildPropId']] = item);
      console.log("store [ChildPropsPoints] : refresh")
      console.dir(action.payload)
    },
    update(state, action) {
      action.payload.forEach(item => state.items[item['ChildPropId']] = item);
      console.log("store [ChildPropsPoints] : update")
      console.dir(action.payload)
    },
  }
});
export { actions as ChildPropsPointsActions };
export { reducer as ChildPropsPointsReducer };
