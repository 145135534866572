// Standard React Components
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// App components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { AppDebugger, MyAPIFetcher } from '../../App';

// General Functions
import t from '../../Libs/localization';

// Material Ui
import { useEffectAsync } from '../../reactHelper';
import { CheckNullOrUndefined } from '../../Libs/EasyFnc';
import { BuildingDataScreen } from '../../Components/PageContent/ProcessingRequestScreens';

export const GeneratePDFReqPageUsingTypeAndId = () => {
	const location = useLocation();
	const [ReqStatusCode,setReqStatusCode] = useState(100);
	const [GetDocmanStatusCode, setGetDocmanStatusCode] = useState();
	const [returnPath, setReturnPath] = useState('/') // eslint-disable-line no-unused-vars
	const [UploadJSON,setUploadJSON] = useState();

	useEffectAsync(async () => {
		if (!CheckNullOrUndefined(location.state)){
			const PostObj = {
				id: location.state.id,
				category: location.state.category
			}
			setReturnPath(location.state.return_path)
			const response = await MyAPIFetcher.post(`document/new_upload_request/`,PostObj);
			if (response[0]) {
				if (response[1].data){
					if (response[1].data.payload[0]){
						setUploadJSON(response[1].data.payload[1]);
						setReqStatusCode(200);
					} else {
						setReqStatusCode(400);
					}
				} else {
					setReqStatusCode(400);
				}
			} else {
				setReqStatusCode(400);
			}
		} else {
			setReqStatusCode(400);
		}
	}, [location.state]);

	useEffect(() => {
		if (UploadJSON){
			console.log(UploadJSON)
			setReqStatusCode(200)
			DocmanGenReq(UploadJSON)
		}
		return () => {
			setReqStatusCode(100)
			setGetDocmanStatusCode()
		}
	}, [UploadJSON])

	const DocmanGenReq = async (UploadJSON) => {
		setGetDocmanStatusCode(100);
		let url =  process.env.REACT_APP_JC2Docman_URL + 'test'

		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/pdf',
				'Token': UploadJSON['token'],
			},
		})
		.then((response) => {
			if (response.ok){
				setGetDocmanStatusCode(200);
			} else {
				setGetDocmanStatusCode(400);
			}

		})
		.catch(function(error){
			console.log('Document generation failed');
			AppDebugger.error(error);
			setGetDocmanStatusCode(400);
		});
	}

	return (
		<APICallStatusCodeSwitcher
			StatusCode={ReqStatusCode}
			Child200={
				<APICallStatusCodeSwitcher
					StatusCode={GetDocmanStatusCode}
					Child100={<BuildingDataScreen
						additionalText={t('str_58r6t0r005')}
					/>}
				/>
			}
		/>
	);
}
