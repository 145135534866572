import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
	IconButton, Avatar
} from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { GLDrawActions } from '../../ReduxStore';

import ContextMenuView from '../ContextMenus/SubdividePointsList';

// General Functions
import t from '../../Libs/localization';
import * as EasyFnc from '../../Libs/EasyFnc';
import { formatCoordinate } from '../../Libs/formatter';

const useStyles = makeStyles(() => ({
	list: {
		maxHeight: '100%',
		overflow: 'auto',
	},
	icon: {
		width: '25px',
		height: '25px',
		filter: 'brightness(0) invert(1)',
	},
}));

const SubdividePointsView = ({ updateTimestamp, onMenuClick }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const SelectedID = useSelector(state => state.GLDraw.selectedId);
	const ThisFeatureCollection = useSelector(state => state.GLDraw.DrawnPointsFeatureCollection);
	return (
		<div>
			{
				EasyFnc.CheckEmptyList(ThisFeatureCollection.features) ?
					<h3>{t('sidebar_Subdivide_list_ReturnSwitchOutput_0')}</h3>
				:
					<List className={classes.list}>
						{ThisFeatureCollection.features.map((item, index) => (
							<Fragment key={index}>
							<ListItem button key={index} onClick={() => dispatch(GLDrawActions.select(index))}>
								<ListItemAvatar>
									{ index === SelectedID ? (
                    <Avatar style={{'backgroundColor': 'green'}}>
                      <img className={classes.icon} src={`images/icon/selected.svg`} alt="" />
                    </Avatar>
                  ):(
                    <Avatar>
                      <img className={classes.icon} src={`images/icon/${item.category || 'default'}.svg`} alt="" />
                   </Avatar>
                  )}
								</ListItemAvatar>
								<ListItemText
									primary={`Marker: ${index} `}
									secondary={`${formatCoordinate('latitude',item.geometry.coordinates[0],'dms')} ${formatCoordinate('longitude',item.geometry.coordinates[1],'dms')}`}
								/>
								<ListItemSecondaryAction>
									<IconButton onClick={(event) => onMenuClick(event.currentTarget, index)}>
										<MoreVertIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</Fragment>
						))}
					</List>
			}
		</div>
	);
}

const SubdividePointsList = ({handleAdd, handleRemove}) => {
	let ContentChildren = {};

	return (
		<ContextMenuView
			content={SubdividePointsView}
			ContentChildren={ContentChildren}
			handleAdd = {handleAdd}
			handleRemove = {handleRemove}
		/>
	);
}

export default SubdividePointsList;
