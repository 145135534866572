import React from 'react';

import {
	IconButton,
	List,
	ListSubheader,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import ComponentStyles from './Styles';
import BuildIcon from '@material-ui/icons/Build';
import PageviewIcon from '@material-ui/icons/Pageview';

import t from '../../Libs/localization';

export const MenuButton = ({openDrawerFnc}) => {
	const classes = ComponentStyles();
	return (
		<IconButton
			className={classes.iconButton}
			color="inherit"
			onClick={openDrawerFnc}
		>
			<BuildIcon />
		</IconButton>
	)
}

export const MenuButtonIcon = BuildIcon;

export const MenuContent = () => {
	return(<>
		<List
			subheader={
				<ListSubheader>
					{t('sharedPlaceholder')}
				</ListSubheader>
			}>
		</List>
		<ListItem button onClick={() => {}}>
			<ListItemIcon>
				<PageviewIcon />
			</ListItemIcon>
			<ListItemText primary={t('sharedPlaceholder')} />
		</ListItem>

		<Divider />
	</>)
}