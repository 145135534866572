// Standard React components
import React, { useState, useEffect} from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MyPropertyActions, GLDrawActions, BlueZoneActions } from '../../ReduxStore';

// App Components
import {APICallStatusCodeSwitcher} from '../../Components/PageContent/StatusCodeSwitcher';
import MyGreenZoneAccordionContainer from '../../Components/AccordionContainers/MyGreenZone';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import GLDrawPageContent from '../../Components/PageContent/GLDraw';

// General Functions
import * as EasyFnc from '../../Libs/EasyFnc';
import { MyAPIFetcher, AppDebugger } from '../../App'
import { CloneObj } from '../../Libs/EasyFnc';

// Map & Map Features
import { MyGreenZoneMapSelected } from '../../Map/Layers/MyGreenZoneMap';
import { MyPropertyMapExisting } from '../../Map/Layers/MyPropertyMap';
import { CreatePointFeatureFromPolygonFeature, EmptyFeatureCollection, FeatureCollectionHaveFeatures, Features2FeatureCollection, GetFeatureFromFeatureCollectionByIndex } from '../../Map/mapUtil';

export const MyGreenZoneSubdivideBody = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [SubmitEnabled, setSubmitEnabled] = useState(false)
	const [ControlPanelExpanded, setControlPanelExpanded] = useState(true);
	const [PreSelected,SetPreSelected] = useState({})
	const [ZoneIndex,SetZoneIndex] = useState("")
	const [DivisionType,SetDivisionType] = useState("")
	const [SubmitStatusCode,SetSubmitStatusCode] = useState(null)
	const status_code = useSelector(state => state.MyGreenZone.status_code);
	const selectedId = useSelector(state => state.MyGreenZone.selectedId);
	const ThisFeatureCollection = useSelector(state => state.MyGreenZone.FeatureCollection);
	const [InputFeatureCollection, setInputFeatureCollection] = useState(EmptyFeatureCollection()) // eslint-disable-line no-unused-vars
	const isBoundryContained = useSelector(state => state.GLDraw.isBoundryContained);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);
	const [ParentUniqueID, setParentUniqueID] = useState();
	const [ParentCategory, setParentCategory] = useState();
	const [CacheInputFeatureCollection, setCacheInputFeatureCollection] = useState()

	useEffect(() => {
		dispatch(GLDrawActions.UpdateBoundryPolygonFeature(null));
		return () => {
			setCacheInputFeatureCollection();
			dispatch(GLDrawActions.UpdateBoundryPolygonFeature(null));
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const qs = require('qs');
		const location_qparam = qs.parse(location.search, { ignoreQueryPrefix: true });
		var TempJSON = {};
		if ('z' in location_qparam){
			TempJSON['zone'] = location_qparam['z'];
			SetZoneIndex(location_qparam['z'])
		};
		if ('t' in location_qparam){
			TempJSON['type'] = location_qparam['t'];
			SetDivisionType(location_qparam['t'])
		};
		if (TempJSON !== {}){SetPreSelected(TempJSON)};
	},[location]);

	useEffect(() => {
		if(!EasyFnc.CheckNull(selectedId) & FeatureCollectionHaveFeatures(ThisFeatureCollection)){
			dispatch(GLDrawActions.UpdateBoundryPolygonFeature(ThisFeatureCollection.features[selectedId]))
			setParentUniqueID(ThisFeatureCollection.features[selectedId].properties.unique_id)
			setParentCategory(ThisFeatureCollection.features[selectedId].properties.zone_category)
		}
	}, [ThisFeatureCollection,selectedId]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSubmitEnabled(false);
		if (EasyFnc.isSetNotBlank(ZoneIndex) && EasyFnc.isSetNotBlank(DivisionType)){
			if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)) {
				if (isBoundryContained){
					setSubmitEnabled(true);
				};
			};
		};
	},[PointsPolyFeatureCollection,ZoneIndex,DivisionType,isBoundryContained]);

	const GetSubmitFeatureCollection = () => {
		let SubmitFeatureCollection = CloneObj(PointsPolyFeatureCollection);
		SubmitFeatureCollection.features[0].relationships = {
			parent: {
				id: ParentUniqueID,
				category: ParentCategory
			}
		}

		AppDebugger.log('New SubmitFeatureCollection');
		AppDebugger.dir(SubmitFeatureCollection);
		return SubmitFeatureCollection
	}

	const handleSubmit_Subdivide_Property = async () => {
		SetSubmitStatusCode(100);
		let SubmitFeatureCollection = GetSubmitFeatureCollection();
		const response = await MyAPIFetcher.post('prop/me/add',SubmitFeatureCollection);
		if (response[0]) {
			dispatch(MyPropertyActions.DoUpdate());
			setCacheInputFeatureCollection();
			SetSubmitStatusCode(200);
			AppDebugger.log('Adding new property completed successfully');
		} else {
			let PolygonFeature = GetFeatureFromFeatureCollectionByIndex(PointsPolyFeatureCollection,0);
			if (PolygonFeature){
				let PointFeatures = CreatePointFeatureFromPolygonFeature(PolygonFeature);
				setCacheInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
			SetSubmitStatusCode(404);
			AppDebugger.log('Adding new property failed');
		}
	}

	const handleSubmit_Subdivide_Blue = async () => {
		SetSubmitStatusCode(100);
		let SubmitFeatureCollection = GetSubmitFeatureCollection();
		const response = await MyAPIFetcher.post('blue/me/add',SubmitFeatureCollection);
		if (response[0]) {
			dispatch(BlueZoneActions.DoUpdate());
			setCacheInputFeatureCollection();
			SetSubmitStatusCode(200);
			AppDebugger.log('Adding new blue zone completed successfully');
		} else {
			SetSubmitStatusCode(404);
			let PolygonFeature = GetFeatureFromFeatureCollectionByIndex(PointsPolyFeatureCollection,0);
			if (PolygonFeature){
				let PointFeatures = CreatePointFeatureFromPolygonFeature(PolygonFeature);
				setCacheInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
			AppDebugger.log('Adding new blue zone failed');
		}
	}

	const handleSubmit_SaveConfig = async (ZoneIndex,divisionType) => {
		setControlPanelExpanded(false);
		SetZoneIndex(ZoneIndex);
		SetDivisionType(divisionType);
		//SetPreSelected({});
  };
	return (
		<APICallStatusCodeSwitcher
			StatusCode = {SubmitStatusCode}
			ChildNull = {
				<>
					<GLDrawPageContent
						StatusCode = {status_code}
						handleSubmit = {
							DivisionType === 'property' ? handleSubmit_Subdivide_Property
							: DivisionType === 'blue' ? handleSubmit_Subdivide_Blue
							: () => {}
						}
						SubmitEnabled = {SubmitEnabled}
						MapChildren = {
							<>
								<MyPropertyMapExisting/>
								<MyGreenZoneMapSelected
									ShowLayer={false}
									Fly2SelectedOptions={{
										enabled: true,
										only_when_shown: false,
									}}
								/>
							</>
						}
						InputFeatureCollection={CacheInputFeatureCollection || InputFeatureCollection}
					/>
					<MyGreenZoneAccordionContainer
						handleSubmit={handleSubmit_SaveConfig}
						PreSelected={PreSelected}
						Expanded={ControlPanelExpanded}
						handleExpand={() => setControlPanelExpanded(!ControlPanelExpanded)}
					/>
				</>
			}
			Child200 = {<SuccessScreen/>}
			ChildError = {
				<ErrorScreen
					handleClick = {() => SetSubmitStatusCode(null)}
				/>
			}
		/>
	)
}

export default MyGreenZoneSubdivideBody


export const MyPropertySubdivideEditBody = ({InputFeatureCollection, UpdateID, ParentUniqueID}) => {
	const dispatch = useDispatch();
	const [SubmitEnabled, setSubmitEnabled] = useState(false)
	const [SubmitStatusCode,SetSubmitStatusCode] = useState(null)
	const status_code = useSelector(state => state.MyGreenZone.status_code);
	const AllParentsFeatureCollection = useSelector(state => state.MyGreenZone.FeatureCollection);
	const isBoundryContained = useSelector(state => state.GLDraw.isBoundryContained);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);

	useEffect(() => {
		let ParentIndex = AllParentsFeatureCollection.features.findIndex(({ properties }) => properties.unique_id === ParentUniqueID)
		if (ParentIndex >= 0){
			console.log('found parent index, setting now\nindex: ' + ParentIndex)
			dispatch(GLDrawActions.UpdateBoundryPolygonFeature(AllParentsFeatureCollection.features[ParentIndex]))
		}
	}, [AllParentsFeatureCollection,ParentUniqueID]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSubmitEnabled(false);
		if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)) {
			if (isBoundryContained){
				setSubmitEnabled(true);
			};
		};
	},[PointsPolyFeatureCollection,isBoundryContained]);

	const handleSubmit = async () => {
		SetSubmitStatusCode(100)
		const response = await MyAPIFetcher.post(`alpha/test/${UpdateID}`,PointsPolyFeatureCollection);
		AppDebugger.log('Update property');
		AppDebugger.dir(PointsPolyFeatureCollection);
		if (response !== null) {
			dispatch(MyPropertyActions.DoUpdate());
			SetSubmitStatusCode(200);
		} else {
			SetSubmitStatusCode(404);
		}
	}

	return (
		<APICallStatusCodeSwitcher
			StatusCode = {SubmitStatusCode}
			ChildNull = {
				<>
					<GLDrawPageContent
						StatusCode = {status_code}
						handleSubmit = {handleSubmit}
						SubmitEnabled = {SubmitEnabled}
						MapChildren = {
							<>
								<MyPropertyMapExisting/>
								<MyGreenZoneMapSelected ShowLayer={false} Fly2Selected={true}/>
							</>
						}
						InputFeatureCollection={InputFeatureCollection}
					/>
				</>
			}
			Child200 = {<SuccessScreen/>}
			ChildError = {
				<ErrorScreen
					handleClick = {() => SetSubmitStatusCode(null)}
				/>
			}
		/>
	)
}

export const BlueZoneSubdivideEditBody = ({InputFeatureCollection, UpdateID, ParentUniqueID}) => {
	const dispatch = useDispatch();
	const [SubmitEnabled, setSubmitEnabled] = useState(false)
	const [SubmitStatusCode,SetSubmitStatusCode] = useState(null)
	const status_code = useSelector(state => state.MyGreenZone.status_code);
	const AllParentsFeatureCollection = useSelector(state => state.MyGreenZone.FeatureCollection);
	const isBoundryContained = useSelector(state => state.GLDraw.isBoundryContained);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);

	useEffect(() => {
		let ParentIndex = AllParentsFeatureCollection.features.findIndex(({ properties }) => properties.unique_id === ParentUniqueID)
		if (ParentIndex >= 0){
			console.log('found parent index, setting now\nindex: ' + ParentIndex)
			dispatch(GLDrawActions.UpdateBoundryPolygonFeature(AllParentsFeatureCollection.features[ParentIndex]))
		}
	}, [AllParentsFeatureCollection,ParentUniqueID]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSubmitEnabled(false);
		if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)) {
			if (isBoundryContained){
				setSubmitEnabled(true);
			};
		};
	},[PointsPolyFeatureCollection,isBoundryContained]);

	const handleSubmit = async () => {
		SetSubmitStatusCode(100)
		const response = await MyAPIFetcher.post(`alpha/test/${UpdateID}`,PointsPolyFeatureCollection);
		AppDebugger.log('Update child green');
		AppDebugger.dir(PointsPolyFeatureCollection);
		if (response !== null) {
			dispatch(BlueZoneActions.DoUpdate());
			SetSubmitStatusCode(200);
		} else {
			SetSubmitStatusCode(404);
		}
	}

	return (
		<APICallStatusCodeSwitcher
			StatusCode = {SubmitStatusCode}
			ChildNull = {
				<>
					<GLDrawPageContent
						StatusCode = {status_code}
						handleSubmit = {handleSubmit}
						SubmitEnabled = {SubmitEnabled}
						MapChildren = {
							<>
								<MyPropertyMapExisting/>
								<MyGreenZoneMapSelected ShowLayer={false} Fly2Selected={true}/>
							</>
						}
						InputFeatureCollection={InputFeatureCollection}
					/>
				</>
			}
			Child200 = {<SuccessScreen/>}
			ChildError = {
				<ErrorScreen
					handleClick = {() => SetSubmitStatusCode(null)}
				/>
			}
		/>
	)
}