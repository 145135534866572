// Standard React Components
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Material UI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// General Functions
import t from '../../Libs/localization';

// App components
import RemoveDialog from './RemoveDialog';
import { GetFeatureFromFeatureCollectionByIndex, GetUniqueIDFromFeatureProperties } from '../../Map/mapUtil';

const ContextMenuView = ({ content, editPath, viewPath, navigatePath }) => {
	const history = useHistory();

	const [selectedId, setSelectedId] = useState(null);
	const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
	const [removeDialogShown, setRemoveDialogShown] = useState(false);
	const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
	//const adminEnabled = useSelector(state => state.session.user && state.session.user.administrator);
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);

	const menuShow = (anchorId, itemId) => {
		setSelectedAnchorEl(anchorId);
		setSelectedId(itemId);
	}

	const menuHide = () => {
		setSelectedAnchorEl(null);
	}

	const handleInfo = () => {
		history.push(`${viewPath}/${selectedId}`);
	}

	const handleNavigate = () => {
		history.push(`${navigatePath}/${selectedId}`);
	}

	const handleEdit = () => {
		const UniqueID = GetUniqueIDFromFeatureProperties(GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,selectedId));
		history.push(`${editPath}/${UniqueID}`);
		menuHide();
	}

	const handleRemove = () => {
		setRemoveDialogShown(true);
		menuHide();
	}

	const handleRemoveResult = () => {
		setRemoveDialogShown(false);
		setUpdateTimestamp(Date.now());
	}

	const Content = content;
	return (
		<>
			<Content updateTimestamp={updateTimestamp} onMenuClick={menuShow} />
			<Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
				<MenuItem onClick={handleInfo}>{t('sharedInfoTitle')}</MenuItem>
				<MenuItem onClick={handleNavigate}>{t('str_ce1q7gwg95')}</MenuItem>
				<MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
				{false && <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>}
			</Menu>
			<RemoveDialog open={removeDialogShown} itemId={selectedId} onResult={handleRemoveResult} />
		</>
	);
}

export default ContextMenuView;
