// Standard React components
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Material UI components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// App components
import { GetFeatureFromFeatureCollectionByIndex, GetUniqueIDFromFeatureProperties } from '../../Map/mapUtil';

// General Functions
import t from '../../Libs/localization';

const ContextMenu = ({ content, infoPath, assignPath, editPath }) => {
	const history = useHistory();
	const [selectedId, setSelectedId] = useState(null);
	const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
	const ThisFeatureCollection = useSelector(state => state.BlueZone.FeatureCollection);

	const menuShow = (anchorId, itemId) => {
		setSelectedAnchorEl(anchorId);
		setSelectedId(itemId);
	}

	const menuHide = () => {
		setSelectedAnchorEl(null);
	}

	const handleAssign = () => {
		history.push(`${assignPath}/${selectedId}`);
	}

	const handleInfo = () => {
		history.push(`${infoPath}/${selectedId}`);
	}

	const handleEdit = () => {
		const UniqueID = GetUniqueIDFromFeatureProperties(GetFeatureFromFeatureCollectionByIndex(ThisFeatureCollection,selectedId));
		history.push(`${editPath}/${UniqueID}`);
		menuHide();
	}

	const Content = content;
	return (
		<>
			<Content onMenuClick={menuShow} />
			<Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
				<MenuItem onClick={handleInfo}>{t('sharedInfoTitle')}</MenuItem>
				<MenuItem onClick={handleAssign}>{t('sharedGreenZones_Assign')}</MenuItem>
				<MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
			</Menu>
		</>
	);
}

export default ContextMenu;
