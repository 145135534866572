// Standard React Components
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Redux
import { useDispatch } from 'react-redux';
import { MyPropertyActions } from '../../ReduxStore';

// Material UI Components
import {
	Accordion, AccordionSummary, AccordionDetails, Typography
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';
import t from '../../Libs/localization';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import SubmitForm from '../../Components/FormControls/SubmitForm';
import PropertySelector from '../../Components/Selectors/Property';
import LessorSelector from '../../Components/Selectors/Lessor';
import LesseeSelector from '../../Components/Selectors/Lessee';
import { useAppStyles } from '../../Libs/AppStyleClasses';
import { CloneObj } from '../../Libs/EasyFnc';

const AddNewBody = () => {
	const classes = useAppStyles();
	const dispatch = useDispatch()
	const [item, setItem] = useState();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null);

	useEffect(() => {
		setItem({
			name:null,
			start_date: moment().subtract(1, 'hour'),
			end_date: moment().add(1, 'year'),
			property_unique_id:null,
			lessor_unique_id:null,
			lessee_unique_id:null,
			enabled: true,
		})
	}, [])

	const handleSubmit = async () => {
		let PostObj = CloneObj(item);
		PostObj.start_date = moment(PostObj.start_date).format("X");
		PostObj.end_date = moment(PostObj.end_date).format("X");
		SetSubmitStatusCode(100);
		const response = await MyAPIFetcher.post('contract/add',PostObj);
		if (response[0]) {
			SetSubmitStatusCode(200);
			AppDebugger.log('New contract added');
			dispatch(MyPropertyActions.DoUpdate())
		} else {
			SetSubmitStatusCode(404);
			AppDebugger.log('Failed to add new contract');
		}
	};

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<SubmitForm handleSubmit={handleSubmit}>
						{item &&
							<>
								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('str_p0ohrp4q70')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<TextField
											margin="normal"
											value={item.name || ''}
											onChange={event => setItem({...item, name: event.target.value})}
											label={t('sharedName')}
											variant="filled" />
										<TextField
											margin="normal"
											variant="filled"
											label={t('sharedDateStart')}
											type="datetime-local"
											value={item.start_date.format(moment.HTML5_FMT.DATETIME_LOCAL)}
											onChange={event => setItem({...item, start_date: moment(event.target.value,moment.HTML5_FMT.DATETIME_LOCAL)})}
											fullWidth />
										<TextField
											margin="normal"
											variant="filled"
											label={t('sharedDateEnd')}
											type="datetime-local"
											value={item.end_date.format(moment.HTML5_FMT.DATETIME_LOCAL)}
											onChange={event => setItem({...item, end_date: moment(event.target.value,moment.HTML5_FMT.DATETIME_LOCAL)})}
											fullWidth />
										<PropertySelector
											ItemLabel={t('str_fwwk86o1hr')}
											handleChange={(value)=>{setItem({...item, property_unique_id: value})}}
										/>
										<LessorSelector
											ItemLabel={t('str_enayrj5kfq')}
											handleChange={(value)=>{setItem({...item, lessor_unique_id: value})}}
										/>
										<LesseeSelector
											ItemLabel={t('str_acb6ronib1')}
											handleChange={(value)=>{setItem({...item, lessee_unique_id: value})}}
										/>
									</AccordionDetails>
								</Accordion>
							</>
						}
					</SubmitForm>
				}
				Child200 = {<SuccessScreen
					buttonText = {t('str_2kixehhoqu')}
					buttonPath = {'/contract/multiview'}
				/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
	);
}

export default AddNewBody


