/* eslint-disable no-unused-vars */

// Standard React components
import React, { useState } from 'react';

// Redux
import { PlacesSearchActions } from '../../ReduxStore';
import { useDispatch, useSelector } from 'react-redux';

// Material UI components
import {
  makeStyles, TextField, IconButton,Fab
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import BackspaceIcon from '@material-ui/icons/Backspace';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';

// Sidebars
import DefaultSidebar from './DefaultSidebar';

// Lists
import PlacesSearchList from '../../Components/Lists/PlacesSearchList';
import MyPropertyList from '../Lists/MyPropertyList';
import MyGreenZoneList from '../../Components/Lists/MyGreenZoneList';
import BlueZoneList from '../../Components/Lists/BlueZoneList';
import AlphaZoneList from '../../Components/Lists/AlphaZoneList';

// General Functions
import t from '../../Libs/localization';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  navItem: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '0.75rem',
		fontWeight: 'normal',
  },
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}));

export const PlacesSearchSidebar = ({isCollapsed, handleDoCollapse}) => {
	const dispatch = useDispatch();
	const [ MyTextField, setMyTextField ] = useState('');

	const handleClick_DoPlacesSearch = () => {
    if (MyTextField === ""){
      dispatch(PlacesSearchActions.UpdateSearchQuery(null));
    } else {
      dispatch(PlacesSearchActions.UpdateSearchQuery(MyTextField));
    }
  }
  const handleClick_ClearPlacesSearch = () => {
    setMyTextField('');
    dispatch(PlacesSearchActions.UpdateSearchQuery(null));
  }

	return (
	<>
		<DefaultSidebar
			isCollapsed = { isCollapsed }
			handleDoCollapse = { handleDoCollapse }
			SidebarHeader = { t('sidebar_PlacesSearch_Header') }
			TopToolbar = {
				<>
					<TextField
							fullWidth
							type="text"
							name="PlacesSearch"
							value={MyTextField}
							onChange={(event) => setMyTextField(event.target.value)}
							placeholder="Search Query"
							variant="filled"
						/>
						<IconButton onClick={handleClick_DoPlacesSearch}>
							<SearchIcon />
						</IconButton>
						<IconButton onClick={handleClick_ClearPlacesSearch}>
							<BackspaceIcon />
					</IconButton>
				</>
			}
			ItemList = { < PlacesSearchList /> }
		/>
	</>
	)
}

export const MyPropertySidebar = ({isCollapsed, handleDoCollapse}) => {
	const classes = useStyles();
	const [ MyTextField, setMyTextField ] = useState('');
	return (
	<>
		<DefaultSidebar
			isCollapsed = { isCollapsed }
			handleDoCollapse = { handleDoCollapse }
			SidebarHeader = { t('sidebar_MyProperty_header') }
			/*
			TopToolbar = {
				<>
					<TextField
							fullWidth
							name="MyProperty_TextField"
							value={MyTextField}
							autoComplete="MyProperty_TextField"
							autoFocus
							onChange={(event) => setMyTextField(event.target.value)}
							placeholder="Filter Query"
							variant="filled"
						/>
						<IconButton onClick={() => {}}>
							<SearchIcon />
						</IconButton>
						<IconButton onClick={() => {}}>
							<BackspaceIcon />
					</IconButton>
				</>
			}
			*/
			ItemList = { < MyPropertyList /> }
			/*
			BottomToolbar = {
			  <>
					<IconButton
						classes={{ label: classes.navItem }}
						onClick={() => {}}
					>
						<ControlCameraIcon />
						{t('sidebar_MyProperty_botttom_btn1')}
					</IconButton>
					<IconButton
						classes={{ label: classes.navItem }}
						onClick={() => {}}
					>
						<ControlCameraIcon />
						Do Update
					</IconButton>
			  </>
			}
			*/
		/>
	</>
	)
}

export const BlueZoneSidebar = ({isCollapsed, handleDoCollapse}) => {
	return (
		<>
			<DefaultSidebar
				isCollapsed = { isCollapsed }
				handleDoCollapse = { handleDoCollapse }
				SidebarHeader = { t('sidebar_BlueZone_header') }
				ItemList = {<BlueZoneList/>}
			/>
		</>
	)
}

export const MyGreenZoneSidebar = ({isCollapsed, handleDoCollapse}) => {
	const [ MyTextField, setMyTextField ] = useState('');
	return (
	<>
		<DefaultSidebar
			isCollapsed = { isCollapsed }
			handleDoCollapse = { handleDoCollapse }
			SidebarHeader = { t('sidebar_MyGreenZone_header') }
			/*
			TopToolbar = {
				<>
					<TextField
							fullWidth
							name="MyGreenZone_TextField"
							value={MyTextField}
							autoComplete="MyGreenZone_TextField"
							autoFocus
							onChange={(event) => setMyTextField(event.target.value)}
							placeholder="Filter Query"
							variant="filled"
						/>
						<IconButton onClick={() => {}}>
							<SearchIcon />
						</IconButton>
						<IconButton onClick={() => {}}>
							<BackspaceIcon />
					</IconButton>
				</>
			}
			*/
			ItemList = { < MyGreenZoneList /> }
		/>
	</>
	)
}

export const AlphaZoneSidebar = ({isCollapsed, handleDoCollapse}) => {
	const classes = useStyles();
	const history = useHistory();
	const access_level = useSelector(state => state.UserConfig.access_level);
	const addPath = "/alpha/global/add"

	const handleAdd = () => {
		history.push(addPath);
	}

	return (
	<>
		<DefaultSidebar
			isCollapsed = { isCollapsed }
			handleDoCollapse = { handleDoCollapse }
			SidebarHeader = { t('sidebar_AlphaZone_header') }
			ItemList = { < AlphaZoneList /> }
			BottomToolbar = {access_level === 20 ?
				<>
					<IconButton
						classes={{ label: classes.navItem }}
						onClick={handleAdd}
					>
						<AddIcon />
						{t('sidebar_alpha_botttom_btn1')}
					</IconButton>
			  </>
			: null}
		/>
	</>
	)
}

/* eslint-enable no-unused-vars */