import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
	UserConfigActions, tokenActions
} from '../ReduxStore';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../reactHelper';
import { useCookies } from 'react-cookie';
import { AppDebugger, MyAPIFetcher } from '../App'
import { useEffect } from 'react';

const displayNotifications = events => {
	if ("Notification" in window) {
		if (Notification.permission === "granted") {
			for (const event of events) {
				const notification = new Notification(`Event: ${event.type}`);
				setTimeout(notification.close.bind(notification), 4 * 1000);
			}
		} else if (Notification.permission !== "denied") {
			Notification.requestPermission(permission => {
				if (permission === "granted") {
					displayNotifications(events);
				}
			});
		}
	}
};

const AuthController = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [cookies, setCookie, removeCookie] = useCookies(['access_token','refresh_token']);
	const authenticated  = useSelector(state => !!state.token.access_token);
	const token = useSelector(state => state.token);

	useEffect(() => {
		const LogoutFnc = () => {
			dispatch(tokenActions.updateToken(null));
			history.push('/logout');
		}

		MyAPIFetcher.SetLogoutFnc(LogoutFnc);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// eslint-disable-next-line no-unused-vars
	const connectSocket = () => {
		const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
		const socket = new WebSocket(protocol + '//' + window.location.host + '/api/socket');

		socket.onclose = () => {
			setTimeout(() => connectSocket(), 60 * 1000);
		};

		socket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			/*
			if (data.ChildProp) {
				dispatch(ChildPropsActions.update(data.ChildProps));
			}
			if (data.ChildPropPoints) {
				dispatch(ChildPropsPointsActions.update(data.ChildPropsPoints));
			}
			*/
			if (data.events) {
				displayNotifications(data.events);
			}
		};
	}

	useEffectAsync(async () => {
		if (authenticated) {
			const response = await MyAPIFetcher.GetAPI('users/config');
			if (response !== null) {
				dispatch(UserConfigActions.updateUser(response));
			} else {
				AppDebugger.log('Failed to get user info')
			}
		}
	}, [authenticated]);

	useEffectAsync(async () => {
		if (authenticated) {
			AppDebugger.log('User is authenticated using token');
			//AppDebugger.log('User is authenticated, access token is: ' + token.access_token);
			//AppDebugger.log('Full token dump: ' + JSON.stringify(token))
			var cookie_options = {path: '/', sameSite: true}
			//if (token.access_token_expire != null) { cookie_options['expires'] = new Date(token.access_token_expire)}
			const today = new Date()
			const FutureDate = new Date(today)
			FutureDate.setDate(FutureDate.getDate() + 1)

			cookie_options['expires'] = FutureDate;
			setCookie('access_token', token.access_token, cookie_options);

			//cookie_options = {path: '/', sameSite: true}
			//if (token.refresh_token_expire != null) {	cookie_options['expires'] = new Date(token.refresh_token_expire)}
			setCookie('refresh_token', token.refresh_token, cookie_options);
		} else {
			var isSetCookieToken = false;
			if (cookies.access_token) {
				if (cookies.access_token !== "" || cookies.access_token !== null){
					isSetCookieToken = true;
				}
			}
			if (isSetCookieToken) {
				MyAPIFetcher.SetTokens({
					"access_token" : cookies.access_token,
					"refresh_token" : cookies.refresh_token,
					"access_token_expire" : null,
					"refresh_token_expire": null
				})
				const isValidToken = await MyAPIFetcher.CheckTokenValid()
				//const isValidToken = await CheckTokenValid(cookies.access_token)
				if (isValidToken){
					AppDebugger.log('Valid token found in cookie')
					dispatch(tokenActions.updateToken({
						"access_token" : cookies.access_token,
						"refresh_token" : cookies.refresh_token,
						"access_token_expire" : null,
						"refresh_token_expire": null
					}));
					//dispatch(tokenActions.updateAccessToken(cookies.token));
				}else {
					AppDebugger.log('Invald token found in cookie, removing token cookie')
					removeCookie("access_token");
					//dispatch(tokenActions.updateAccessToken(null));
					dispatch(tokenActions.updateToken(null));
					MyAPIFetcher.ClearTokens();
					//history.push('/login');
					history.push('/');
				}
			}
			else {
				AppDebugger.log('No token found in cookie, redirecting to login')
				//history.push('/login');
				history.push('/');
				//setCookie('token', 'something', { path: '/' });
			}
		}
	}, [authenticated]);

	return null;
}

export default connect()(AuthController);
