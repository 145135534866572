// Standard React components
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { GLDrawActions } from '../../ReduxStore';

// Map & Map Features
import {
	PolyFeatureInPolyFeature, FeatureCollectionHaveFeatures, PointFeatureInPolyFeature
} from '../mapUtil'

// Toolbars, Dialogs & Components
import AlertDialog from '../../Components/Dialogs/ConfirmDialogs';

// General Functions
import t from '../../Libs/localization';
import { AppDebugger } from '../../App';
import * as EasyFnc from '../../Libs/EasyFnc';

const BoundryCheckAlert = () => {
	const dispatch = useDispatch();
	const DrawnPointsFeatureCollection = useSelector(state => state.GLDraw.DrawnPointsFeatureCollection);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);
	const BoundryPolygonFeature = useSelector(state => state.GLDraw.BoundryPolygonFeature);
	const [PointAlertOpen, setPointAlertOpen] = useState(false);
	const [PolyAlertOpen, setPolyAlertOpen] = useState(false);
	const [PointContained, setPointContained] = useState(null);
	const [PolyContained, setPolyContained] = useState(null);


	useEffect(() => {
		var isContained = true;
		if (!EasyFnc.CheckNullOrUndefined(BoundryPolygonFeature)){
			if (FeatureCollectionHaveFeatures(DrawnPointsFeatureCollection)){
				if (!PointFeatureInPolyFeature(DrawnPointsFeatureCollection,BoundryPolygonFeature)){
					isContained = false
					AppDebugger.log('Point out of bound, alerting');
				}
			}
		}
		setPointContained(isContained)
	}, [BoundryPolygonFeature,DrawnPointsFeatureCollection])

	useEffect(() => {
		var isContained = true;
		if (!EasyFnc.CheckNullOrUndefined(BoundryPolygonFeature)){
			if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)){
				if (!PolyFeatureInPolyFeature(BoundryPolygonFeature,PointsPolyFeatureCollection.features[0])){
					isContained = false
					AppDebugger.log('Polygon out of bound, alerting');
				}
			}
		}
		setPolyContained(isContained)
	}, [BoundryPolygonFeature,PointsPolyFeatureCollection])

	useEffect(() => {
		if (PointContained & PolyContained){
			dispatch(GLDrawActions.setBoundryContained(true));
		} else {
			dispatch(GLDrawActions.setBoundryContained(false));
			if (PolyContained === false){
				setPolyAlertOpen(true);
			} else if (PointContained === false){
				setPointAlertOpen(true);
			}
		}
	}, [PointContained,PolyContained]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<AlertDialog handleSubmit={() => {setPointAlertOpen(false)}} isOpen={PointAlertOpen}
				AlertConfig={{
					"DialogTitle":t('str_7gzupb8h6y'),
					"DialogContentText":t('str_o36e1q3ma7'),
					"buttons":[
						{"title":t('AlertDialog_Shared_OK'),"response":null,"autoFocus":true},
					]
				}}
			/>
			<AlertDialog handleSubmit={() => {setPolyAlertOpen(false)}} isOpen={PolyAlertOpen}
				AlertConfig={{
					"DialogTitle":t('str_hgqpkol028'),
					"DialogContentText":t('str_gpzqzm3p05'),
					"buttons":[
						{"title":t('AlertDialog_Shared_OK'),"response":null,"autoFocus":true},
					]
				}}
			/>
		</div>
	);
}

export default BoundryCheckAlert;
