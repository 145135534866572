// Standard React components
import React, { Fragment, useState, useEffect } from 'react';

import t from '../../Libs/localization';
import {
	Typography, ListItem, ListItemText, ListItemSecondaryAction, List, Divider
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useEffectAsync } from '../../reactHelper';
import { useSelector } from 'react-redux';

// Map & Map Features
import {
	FeatureCollectionHaveFeatures, PolyFeatureArea
} from '../../Map/mapUtil';

// App components
import { NewPaper, NewPaperInner } from '../../Components/PageContent/Shared';
import { DocumentList } from "../../Components/PageContent/FileExplorer";

// General Functions
import { formatNumber } from '../../Libs/formatter';
import { formatPosition } from '../../Libs/formatter';
import { prefixString } from '../../Libs/stringUtils';
import { AppDebugger } from '../../App';

const PropertiesListItem = ({ThisKey,value1,value2}) => {
	return (
		<Fragment key={ThisKey}>
			<ListItem>
				<ListItemText
					primary={value1}
				/>
				<ListItemSecondaryAction>
					<Typography variant="body2">
						{value2}
					</Typography>
				</ListItemSecondaryAction>
			</ListItem>
		</Fragment>
	)
}

export const BlueZoneBody = () => {
	const { id } = useParams();

	const [item, setItem] = useState();
	const [ThisFeature, setThisFeature] = useState();
	const [ThisFeatureCalculatedValues, setThisFeatureCalculatedValues] = useState({});
	const [HaveDocuments, setHaveDocuments] = useState(false);

	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);

	const formatKey = (key) => {
		return t(prefixString('position', key)) || `${t('sharedAttribute')} "${key}"`;
	};

	const attributesList = () => {
		const combinedList = {...item, ...item.attributes};
		return Object.entries(combinedList).filter(([_, value]) => typeof value !== 'object');
	}

	useEffectAsync(() => {
		if (id) {
			if (FeatureCollectionHaveFeatures(ThisFeatureCollection)) {
				console.log(ThisFeatureCollection.features[id])
				setItem(ThisFeatureCollection.features[id].properties);
				setThisFeature(ThisFeatureCollection.features[id]);
				if ('documents' in ThisFeatureCollection.features[id]){
					setHaveDocuments(true);
				}
			}
			else
			{
				setItem({});
				setThisFeature({});
			}
		} else {
			setItem({});
			setThisFeature({});
		}
	}, [ThisFeatureCollection]);

	useEffect(() => {
		if (ThisFeature){
			var CalcuatedValuesObj = {}
			CalcuatedValuesObj.area = formatNumber(PolyFeatureArea(ThisFeature));
			CalcuatedValuesObj.boundry_length = '10m';
			CalcuatedValuesObj.number_of_coordinates = ThisFeature.geometry.coordinates[0].length;
			setThisFeatureCalculatedValues(CalcuatedValuesObj);
		}
	}, [ThisFeature])

	return (
		<>
			{ ThisFeature &&
				<NewPaper>
					<NewPaperInner>
						<h2>Child Green Zone Info</h2>
						<h3>Basic Info</h3>
						<List>
							<PropertiesListItem
								ThisKey="name"
								value1="Property Name"
								value2={ThisFeature.properties.name}
							/>
							<PropertiesListItem
								ThisKey="unique_id"
								value1="Unique ID"
								value2={ThisFeature.properties.unique_id}
							/>
						</List>

					</NewPaperInner>
					<NewPaperInner>
						<h3>Geographic Info</h3>
						<List>
							<PropertiesListItem
								ThisKey="area"
								value1="Size"
								value2={ThisFeatureCalculatedValues.area}
							/>
							<PropertiesListItem
								ThisKey="length"
								value1="Boundry Length"
								value2={ThisFeatureCalculatedValues.boundry_length}
							/>
							<Divider />
							<PropertiesListItem
								ThisKey="polypoints"
								value1="Number of boundry points"
								value2={ThisFeatureCalculatedValues.number_of_coordinates}
							/>

						</List>
					</NewPaperInner>
				</NewPaper>
			}
			<NewPaper>
				{ HaveDocuments &&
					<DocumentList
						DocumentIDsList = {ThisFeature.documents}
					/>
				}
			</NewPaper>
			{ AppDebugger.isEnabled() &&
				<NewPaper>
					<NewPaperInner>
						<h2>All info</h2>
						{item &&
							<List>
								{attributesList().map(([key, value], index, list) => (
									<Fragment key={key}>
										<ListItem>
											<ListItemText
												primary={formatKey(key)}
												/>
											<ListItemSecondaryAction>
												<Typography variant="body2">
													{formatPosition(value, key)}
												</Typography>
											</ListItemSecondaryAction>
										</ListItem>
										{index < list.length - 1 ? <Divider /> : null}
									</Fragment>
								))}
							</List>
						}
					</NewPaperInner>
				</NewPaper>
			}
		</>
	);
}