// Standard React Components
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';

// Material UI Components
import {
	Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';

const LesseeSelector = ({ItemLabel, handleChange, preSelectValue, isDisabled}) => {
	const [LessorsObj,setLessorsObj] = useState([]);
	const [SelectedValue, setSelectedValue] = useState(preSelectValue || "");

	useEffectAsync(async () => {
		const response = await MyAPIFetcher.get('lessee/me/all');
		if (response[0]) {
			if (response[1].data){
				setLessorsObj(response[1].data.payload);
			}
		} else {
			AppDebugger.log('Failed to get Contact')
		}
	}, []);

	return (
		<FormControl
			variant="filled"
			margin="normal"
			fullWidth
		>
			<InputLabel>{ItemLabel}</InputLabel>
			<Select
				disabled={isDisabled}
				value={SelectedValue}
				onChange={event => {
					//setItem({...item, category: event.target.value})
					setSelectedValue(event.target.value)
					handleChange(event.target.value)
				}}
			>
				{LessorsObj.map((item, index) => (
					<MenuItem key={index} value={item.unique_id}>{item.name}</MenuItem>
				))}

			</Select>
		</FormControl>
	)
}

export default LesseeSelector