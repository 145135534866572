// Standard React components
import React from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { NavigateActions } from '../../ReduxStore';

// Material UI components
import { useTheme } from '@material-ui/core/styles';
import {
	Box, Container, makeStyles, Grid, Button
} from '@material-ui/core';

// General Functions
import t from '../../Libs/localization';
import { formatDistanceMetric } from '../../Libs/formatter';

const useStyles = makeStyles(theme => ({
	NavigateBottomBox: {
		position: 'fixed',
		bottom: 0,
		width: "100vw",
		height: '80px',
		zIndex: 1101,
		backgroundColor: 'primary.main',
		color: 'primary.contrastText',
		overflow: 'hidden',
	},
	NavigateBottomContainer: {
		height: "100%",
		width: "100%",
		paddingLeft: '20px',
		paddingRight: '20px',
		paddingTop: '5px',
		paddingBottom: '5px',
		textAlign: 'center'
	},
	space_reserver: {
		bottom: "0",
		width: "100%",
		height: '80px',
		backgroundColor: 'red',
	},
	NextPointButton: {
		color: 'red'
	}
}));

export const NavigateBottom = ({InputValues}) => {
	const dispatch = useDispatch()
	const theme = useTheme();
	const classes = useStyles(theme);

	const handleClickNextPoint = () => {
		dispatch(NavigateActions.NextTarget());
	}

	return (
	<Box
		className={classes.NavigateBottomBox}
		bgcolor="primary.main"
		color='primary.contrastText'
	>
			<Container
				className={classes.NavigateBottomContainer}
				align = 'center'
			>
				{InputValues.ValidGeolocate
				? <>
					{InputValues.ValidTarget
					? <>
					<Grid container spacing={2} justifyContent="flex-end" align = 'center'>
						<Grid item xs={3} sm={3} >
						{InputValues.Accuracy
								? <p>Accuracy: {formatDistanceMetric(InputValues.Accuracy)}</p>
								: <p></p>
							}
						</Grid>
						<Grid item xs={3} sm={3}>	{InputValues.Distance
								? <p>Distance: {formatDistanceMetric(InputValues.Distance)}</p>
								: <p></p>
							}</Grid>
						<Grid item xs={6} sm={3}>
							<Button
								bgcolor="red"
								variant='contained'
								color='secondary'
								fullWidth
								onClick={handleClickNextPoint}
							>
								Next point
							</Button>
						</Grid>
					</Grid>
					</>
					: <>
						<p>{t('str_9vgsvsiquh')}</p>
					</>}
					</>
				: <>
					<p>{t('str_uwc5p9xue5')}</p>
				</>}

			</Container>
	</Box>
	)
}

export const NavigateBottomReserveSpace = () => {
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<div className={classes.space_reserver}></div>
	)
}