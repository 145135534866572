// Standard React components
import React, { useState, useEffect} from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { AlphaZoneActions, GLDrawActions, MyPropertyActions, BlueZoneActions } from '../../ReduxStore';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import GLDrawPageContent from '../../Components/PageContent/GLDraw';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App'

// Map & Map Features
import { MyGreenZoneMapSelected } from '../../Map/Layers/MyGreenZoneMap';
import { MyPropertyMapExisting } from '../../Map/Layers/MyPropertyMap';
import {
	FeatureCollectionHaveFeatures,
	GetFeatureFromFeatureCollectionByIndex,
	Features2FeatureCollection,
	CreatePointFeatureFromPolygonFeature
} from '../../Map/mapUtil';

export const EditAlphaZone = ({InputFeatureCollection, UpdateID}) => {
	const dispatch = useDispatch();
	const [SubmitEnabled, setSubmitEnabled] = useState(false);
	const [SubmitStatusCode,SetSubmitStatusCode] = useState();
	const status_code = useSelector(state => state.AlphaZone.status_code);
	const isBoundryContained = useSelector(state => state.GLDraw.isBoundryContained);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);
	const [CacheInputFeatureCollection, setCacheInputFeatureCollection] = useState()

	useEffect(() => {
		dispatch(GLDrawActions.UpdateBoundryPolygonFeature(null));
		return () => {
			setCacheInputFeatureCollection();
			setSubmitEnabled(false);
			SetSubmitStatusCode();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSubmitEnabled(false);
		if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)) {
			if (isBoundryContained){
				setSubmitEnabled(true);
			};
		};
	},[PointsPolyFeatureCollection,isBoundryContained]);

	const handleSubmit = async () => {
		SetSubmitStatusCode(100);
		let ThisFeature = GetFeatureFromFeatureCollectionByIndex(PointsPolyFeatureCollection,0);
		if (ThisFeature){
			const response = await MyAPIFetcher.patch(`alpha/manage/geometry/${UpdateID}`,ThisFeature.geometry);
			if (response[0]) {
				dispatch(AlphaZoneActions.DoUpdate());
				SetSubmitStatusCode(200);
				AppDebugger.log('Edit Alpha Zone Submitted');
				AppDebugger.dir(PointsPolyFeatureCollection);
				setCacheInputFeatureCollection();
			} else {
				SetSubmitStatusCode(404);
				let PointFeatures = CreatePointFeatureFromPolygonFeature(ThisFeature);
				setCacheInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
		} else {
			SetSubmitStatusCode(404);
		}
	}

	return (
		<APICallStatusCodeSwitcher
			StatusCode = {SubmitStatusCode}
			ChildNull = {
				<>
					<GLDrawPageContent
						StatusCode = {status_code}
						handleSubmit = {handleSubmit}
						SubmitEnabled = {SubmitEnabled}
						InputFeatureCollection={CacheInputFeatureCollection || InputFeatureCollection}
					/>
				</>
			}
			Child200 = {<SuccessScreen/>}
			ChildError = {
				<ErrorScreen
					handleClick = {() => SetSubmitStatusCode(null)}
				/>
			}
		/>
	)
}

export const EditBlueZone = ({InputFeatureCollection, UpdateID}) => {
	const dispatch = useDispatch();
	const [SubmitEnabled, setSubmitEnabled] = useState(false);
	const [SubmitStatusCode,SetSubmitStatusCode] = useState();
	const status_code = useSelector(state => state.BlueZone.status_code);
	const isBoundryContained = useSelector(state => state.GLDraw.isBoundryContained);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);
	const [CacheInputFeatureCollection, setCacheInputFeatureCollection] = useState()

	useEffect(() => {
		dispatch(GLDrawActions.UpdateBoundryPolygonFeature(null));
		return () => {
			setCacheInputFeatureCollection();
			setSubmitEnabled(false);
			SetSubmitStatusCode();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSubmitEnabled(false);
		if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)) {
			if (isBoundryContained){
				setSubmitEnabled(true);
			};
		};
	},[PointsPolyFeatureCollection,isBoundryContained]);

	const handleSubmit = async () => {
		SetSubmitStatusCode(100);
		let ThisFeature = GetFeatureFromFeatureCollectionByIndex(PointsPolyFeatureCollection,0);
		if (ThisFeature){
			const response = await MyAPIFetcher.patch(`blue/manage/geometry/${UpdateID}`,ThisFeature.geometry);
			if (response[0]) {
				dispatch(BlueZoneActions.DoUpdate());
				SetSubmitStatusCode(200);
				AppDebugger.log('Edit Blue Zone Submitted');
				AppDebugger.dir(PointsPolyFeatureCollection);
				setCacheInputFeatureCollection();
			} else {
				SetSubmitStatusCode(404);
				let PointFeatures = CreatePointFeatureFromPolygonFeature(ThisFeature);
				setCacheInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
		} else {
			SetSubmitStatusCode(404);
		}
	}

	return (
		<APICallStatusCodeSwitcher
			StatusCode = {SubmitStatusCode}
			ChildNull = {
				<>
					<GLDrawPageContent
						StatusCode = {status_code}
						handleSubmit = {handleSubmit}
						SubmitEnabled = {SubmitEnabled}
						InputFeatureCollection={CacheInputFeatureCollection || InputFeatureCollection}
					/>
				</>
			}
			Child200 = {<SuccessScreen/>}
			ChildError = {
				<ErrorScreen
					handleClick = {() => SetSubmitStatusCode(null)}
				/>
			}
		/>
	)
}

export const EditMyProperty = ({InputFeatureCollection, UpdateID, ParentUniqueID}) => {
	const dispatch = useDispatch();
	const [SubmitEnabled, setSubmitEnabled] = useState(false)
	const [SubmitStatusCode,SetSubmitStatusCode] = useState(null)
	const status_code = useSelector(state => state.MyGreenZone.status_code);
	const AllParentsFeatureCollection = useSelector(state => state.MyGreenZone.FeatureCollection);
	const isBoundryContained = useSelector(state => state.GLDraw.isBoundryContained);
	const PointsPolyFeatureCollection = useSelector(state => state.GLDraw.PointsPolyFeatureCollection);
	const [CacheInputFeatureCollection, setCacheInputFeatureCollection] = useState()

	useEffect(() => {
		let ParentIndex = AllParentsFeatureCollection.features.findIndex(({ properties }) => properties.unique_id === ParentUniqueID)
		if (ParentIndex >= 0){
			console.log('found parent index, setting now\nindex: ' + ParentIndex)
			dispatch(GLDrawActions.UpdateBoundryPolygonFeature(AllParentsFeatureCollection.features[ParentIndex]))
		}
	}, [AllParentsFeatureCollection,ParentUniqueID]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setSubmitEnabled(false);
		if (FeatureCollectionHaveFeatures(PointsPolyFeatureCollection)) {
			if (isBoundryContained){
				setSubmitEnabled(true);
			};
		};
	},[PointsPolyFeatureCollection,isBoundryContained]);

	useEffect(() => {
		return () => {
			setCacheInputFeatureCollection();
			setSubmitEnabled(false);
			SetSubmitStatusCode();
			dispatch(GLDrawActions.UpdateBoundryPolygonFeature(null));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSubmit = async () => {
		SetSubmitStatusCode(100);
		let ThisFeature = GetFeatureFromFeatureCollectionByIndex(PointsPolyFeatureCollection,0);
		if (ThisFeature){
			const response = await MyAPIFetcher.patch(`prop/manage/geometry/${UpdateID}`,ThisFeature.geometry);
			if (response[0]) {
				dispatch(MyPropertyActions.DoUpdate());
				SetSubmitStatusCode(200);
				AppDebugger.log('Edit My Prop Submitted');
				AppDebugger.dir(PointsPolyFeatureCollection);
				setCacheInputFeatureCollection();
			} else {
				SetSubmitStatusCode(404);
				let PointFeatures = CreatePointFeatureFromPolygonFeature(ThisFeature);
				setCacheInputFeatureCollection(Features2FeatureCollection(PointFeatures));
			}
		} else {
			SetSubmitStatusCode(404);
		}
	}

	return (
		<APICallStatusCodeSwitcher
			StatusCode = {SubmitStatusCode}
			ChildNull = {
				<>
					<GLDrawPageContent
						StatusCode = {status_code}
						handleSubmit = {handleSubmit}
						SubmitEnabled = {SubmitEnabled}
						MapChildren = {
							<>
								<MyPropertyMapExisting/>
								<MyGreenZoneMapSelected ShowLayer={false} Fly2Selected={true}/>
							</>
						}
						InputFeatureCollection={CacheInputFeatureCollection || InputFeatureCollection}
					/>
				</>
			}
			Child200 = {<SuccessScreen/>}
			ChildError = {
				<ErrorScreen
					handleClick = {() => SetSubmitStatusCode(null)}
				/>
			}
		/>
	)
}