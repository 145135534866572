// Standard React components
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContainerDimensions from 'react-container-dimensions';

// Redux
import { useSelector } from 'react-redux';

// Material UI components
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PageviewIcon from '@material-ui/icons/Pageview';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

// Components
import {
	PlacesSearchSidebar,
	MyPropertySidebar,
	BlueZoneSidebar,
	MyGreenZoneSidebar,
	AlphaZoneSidebar
} from '../../Components/Sidebars/ExtendedSidebars';
import SidebarToggleButton from '../../Components/Buttons/SidebarToggleButton';

// Map & Map Features
import Map from '../../Map/Map';
//import AccuracyMap from '../../Map/AccuracyMap';
import CurrentLocationMap from '../../Map/CurrentLocationMap';
import PlacesSearchMap from '../../Map/Layers/PlacesSearchMap';
import { MyPropertyMapAll, MyPropertyMapSelected } from '../../Map/Layers/MyPropertyMap';
import { MyGreenZoneMapAll, MyGreenZoneMapSelected } from '../../Map/Layers/MyGreenZoneMap';
import { BlueZoneMapAll, BlueZoneMapSelected } from '../../Map/Layers/BlueZoneMap';
import { AlphaZoneMapAll, AlphaZoneMapSelected } from '../../Map/Layers/AlphaZoneMap';
// eslint-disable-next-line no-unused-vars
import { CADLayersMap } from '../../Map/Layers/CADLayersMap';

// General Functions
import t from '../../Libs/localization';
import { useAppStyles } from '../../Libs/AppStyleClasses';

// Controllers
import SearchController from '../../Controllers/SearchController';

/* eslint-disable no-unused-vars */
const MainPage = () => {
	const classes = useAppStyles();
	const theme = useTheme();
	const { page } = useParams();

	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
	let isDesktop = (isTablet | isPhone) ? false : true;

	const access_level = useSelector(state => state.UserConfig.access_level);

	const PlacesSearchSelectedID = useSelector(state => state.PlacesSearch.selectedId);
	const MyPropertySelectedID = useSelector(state => state.MyProperty.selectedId);
	const MyGreenZoneSelectedID = useSelector(state => state.MyGreenZone.selectedId);
	const BlueZoneSelectedID = useSelector(state => state.BlueZone.selectedId);
	const AlphaZoneSelectedID = useSelector(state => state.AlphaZone.selectedId);

	const [MenuOption_PlacesSearch,setMenuOption_PlacesSearch] = useState(true);
	const [MenuOption_MyProperty,setMenuOption_MyProperty] = useState(true);
	const [MenuOption_BlueZone,setMenuOption_BlueZone] = useState(true);
	const [MenuOption_MyGreenZone,setMenuOption_MyGreenZone] = useState(true);
	const [MenuOption_AlphaZone,setMenuOption_AlphaZone] = useState(true);

	const [collapsed_PlacesSearch, setCollapsed_PlacesSearch] = useState(true);
	const [collapsed_MyProperty, setCollapsed_MyProperty] = useState(true);
	const [collapsed_BlueZone, setCollapsed_BlueZone] = useState(true);
	const [collapsed_MyGreenZone, setCollapsed_MyGreenZone] = useState(true);
	const [collapsed_AlphaZone, setCollapsed_AlphaZone] = useState(true);

	// eslint-disable-next-line no-unused-vars
	const [Fly2Selected_MyProperty, setFly2Selected_MyProperty] = useState(true);

	const [ShowLayer_MyProperty, setShowLayer_MyProperty] = useState(false);
	const [ShowLayer_BlueZone, setShowLayer_BlueZone] = useState(false);
	const [ShowLayer_MyGreenZone, setShowLayer_MyGreenZone] = useState(false);
	const [ShowLayer_AlphaZone, setShowLayer_AlphaZone] = useState(false);

	useEffect(() => {
		if (!collapsed_PlacesSearch){
			setShowLayer_MyProperty(false)
			setShowLayer_BlueZone(false)
			setShowLayer_MyGreenZone(false)
			setShowLayer_AlphaZone(false)
		} else if (!collapsed_MyProperty){
			setShowLayer_MyProperty(true)
			setShowLayer_BlueZone(false)
			setShowLayer_MyGreenZone(false)
			setShowLayer_AlphaZone(false)
		} else if (!collapsed_BlueZone){
			setShowLayer_MyProperty(false)
			setShowLayer_BlueZone(true)
			setShowLayer_MyGreenZone(false)
			setShowLayer_AlphaZone(false)
		} else if (!collapsed_MyGreenZone){
			setShowLayer_MyProperty(false)
			setShowLayer_BlueZone(false)
			setShowLayer_MyGreenZone(true)
			setShowLayer_AlphaZone(false)
		}	else if (!collapsed_AlphaZone){
			setShowLayer_MyProperty(false)
			setShowLayer_BlueZone(false)
			setShowLayer_MyGreenZone(false)
			setShowLayer_AlphaZone(true)
		}
	}, [
		collapsed_PlacesSearch,
		collapsed_MyProperty,
		collapsed_BlueZone,
		collapsed_MyGreenZone,
		collapsed_AlphaZone,
	]);

	// Collapse sidebar for tablets and phones
	/*
	useEffect(() => {
		setCollapsed(isTablet);
		setCollapsed_AlphaZone(isTablet);
	}, [isTablet]);
	*/

	useEffect(() => { (!isDesktop) && (PlacesSearchSelectedID !== null) && setCollapsed_PlacesSearch(true)}, [isDesktop,PlacesSearchSelectedID]);
	useEffect(() => { (!isDesktop) && (MyPropertySelectedID !== null) && setCollapsed_MyProperty(true)}, [isDesktop,MyPropertySelectedID]);
	useEffect(() => { (!isDesktop) && (MyGreenZoneSelectedID !== null) && setCollapsed_MyGreenZone(true)}, [isDesktop,MyGreenZoneSelectedID]);
	useEffect(() => { (!isDesktop) && (BlueZoneSelectedID !== null) && setCollapsed_BlueZone(true)}, [isDesktop,BlueZoneSelectedID]);
	useEffect(() => { (!isDesktop) && (AlphaZoneSelectedID !== null) && setCollapsed_AlphaZone(true)}, [isDesktop,AlphaZoneSelectedID]);

	/*
	useEffect(() => {
		if (page !== null && page !== undefined ){
			console.log('page: ' + page)
			setMenuOption_PlacesSearch(false)
			setMenuOption_MyProperty(false)
			setMenuOption_BlueZone(false)
			setMenuOption_MyGreenZone(false)
			setMenuOption_AlphaZone(false)

			setCollapsed_PlacesSearch(true);
			setCollapsed_MyProperty(true);
			setCollapsed_BlueZone(true);
			setCollapsed_MyGreenZone(true);
			setMenuOption_AlphaZone(true);
			if (page === "search"){
				setCollapsed_PlacesSearch(false);
				setMenuOption_PlacesSearch(true);
			} else if (page === "my_property"){
				setCollapsed_MyProperty(false);
				setMenuOption_MyProperty(true);
			} else if (page === "blue"){
				setCollapsed_BlueZone(false);
				setMenuOption_BlueZone(true);
			} else if (page === "my_green"){
				setCollapsed_MyGreenZone(false);
				setMenuOption_MyGreenZone(true);
			} else if (page === "alpha"){
				setMenuOption_AlphaZone(false);
				setCollapsed_AlphaZone(true);
			}
		}
	}, [page]);
	*/

	return (
		<>
			<SearchController />
			<div className={classes.mapContainer}>
				<ContainerDimensions>
					<Map>
							<CurrentLocationMap />
							{/*<AccuracyMap />*/}
							<PlacesSearchMap enabled={true} Fly2Selected={true}/>
							<MyPropertyMapSelected ShowLayer={ShowLayer_MyProperty} Fly2Selected={true}/>
							<MyPropertyMapAll ShowLayer={ShowLayer_MyProperty}/>
							<MyGreenZoneMapSelected ShowLayer={ShowLayer_MyGreenZone} Fly2Selected={true}/>
							<MyGreenZoneMapAll ShowLayer={ShowLayer_MyGreenZone}/>
							<BlueZoneMapSelected ShowLayer={ShowLayer_BlueZone} Fly2Selected={true}/>
							<BlueZoneMapAll ShowLayer={ShowLayer_BlueZone}/>
							<AlphaZoneMapSelected ShowLayer={ShowLayer_AlphaZone} Fly2Selected={true}/>
							<AlphaZoneMapAll ShowLayer={ShowLayer_AlphaZone}/>
							{/* <CADLayersMap enabled={!collapsed_AlphaZone}/> */}
					</Map>
				</ContainerDimensions>
			</div>

			<div className={classes.sidebarToggleContainer}>
				{ access_level > 0 && <>
					{ MenuOption_PlacesSearch &&
						<SidebarToggleButton
							handleClick = { () => setCollapsed_PlacesSearch(!collapsed_PlacesSearch) }
							ThisIcon = { <PageviewIcon /> }
							ThisHeader = { t('sharedPlacesSearch') }
						/>
					}
					{ MenuOption_MyProperty &&
						<SidebarToggleButton
							handleClick = {() => {
								setCollapsed_MyProperty(!collapsed_MyProperty)
							}}
							ThisIcon = { <AccountBoxIcon /> }
							ThisHeader = { t('sharedProperty') }
						/>
					}
					{ access_level >= 5 &&
						<SidebarToggleButton
							handleClick = { () => setCollapsed_BlueZone(!collapsed_BlueZone) }
							ThisIcon = { <PhotoSizeSelectSmallIcon /> }
							ThisHeader = { t('sharedBlueZone') }
						/>
					}
					{ MenuOption_MyGreenZone &&
						<SidebarToggleButton
							handleClick = { () => setCollapsed_MyGreenZone(!collapsed_MyGreenZone) }
							ThisIcon = { <PictureInPictureIcon /> }
							ThisHeader = { t('sharedMyGreen') }
						/>
					}
					{ access_level === 20 &&
						<SidebarToggleButton
							handleClick = { () => setCollapsed_AlphaZone(!collapsed_AlphaZone) }
							ThisIcon = { <AccountBalanceIcon /> }
							ThisHeader = { t('sharedAlpha') }
						/>
					}
				</>}
			</div>
			<div>
				{ access_level > 0 && <>
					< PlacesSearchSidebar
						isCollapsed={collapsed_PlacesSearch}
						handleDoCollapse={() => {
							setCollapsed_PlacesSearch(true);
						}}
					/>
					<MyPropertySidebar
						isCollapsed={collapsed_MyProperty}
						handleDoCollapse={() => {
							setCollapsed_MyProperty(true)
						}}
					/>
				</>}
				{ access_level >= 5 &&
					<BlueZoneSidebar
						isCollapsed={collapsed_BlueZone}
						handleDoCollapse={() => {
							setCollapsed_BlueZone(true)
						}}
					/>
				}
				<MyGreenZoneSidebar
					isCollapsed={collapsed_MyGreenZone}
					handleDoCollapse={() => {
						setCollapsed_MyGreenZone(true)
					}}
				/>
				{ access_level === 20 &&
					<AlphaZoneSidebar
						isCollapsed={collapsed_AlphaZone}
						handleDoCollapse={() => {
							setCollapsed_AlphaZone(true)
						}}
					/>
				}
			</div>
		</>
	);
};

export default MainPage;