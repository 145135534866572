// Standard React components
import { useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { GLDrawActions } from '../../ReduxStore';

// Map & Map Features
import { map } from '../Map';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FeatureCollectionHaveFeatures } from '../mapUtil'
import MultiPointMode from './MultiPointMode';

// General Functions
import { AppDebugger } from '../../App';
import * as EasyFnc from '../../Libs/EasyFnc';

export const draw = new MapboxDraw({
	displayControlsDefault: false,
	controls: {
		point: false,
		line_string: false,
		polygon: false,
		trash: false,
	},
	modes: Object.assign({
    draw_multi_point: MultiPointMode,
  }, MapboxDraw.modes),
});

const AddPolygonPointsMap = ({ InputFeatureCollection }) => {
	const dispatch = useDispatch();

	// Dispatch current draw FeatureCollection to store
	const UpdateDrawFeatures = (e) => {
		const data = draw.getAll();
		dispatch(GLDrawActions.UpdateDrawnPoints(data));
	}

	// Dispatch point selected on map to store
	const handleDrawSelectionChange = (e) => {
		if (FeatureCollectionHaveFeatures(e)){
			dispatch(GLDrawActions.selectByID(e.features[0]['id']));
		}
	}

	// Fix for bug in mapbox gl draw
	// ERROR: Unable to preventDefault inside passive event listener invocation.
	// https://github.com/mapbox/mapbox-gl-draw/issues/1019
		const handleMapTouchEnd = (e) => {
			const SelectedFeatureCollection = draw.getSelected()
			if (FeatureCollectionHaveFeatures(SelectedFeatureCollection)){
				AppDebugger.log('Updating UpdateDrawnPoints because of Touch End')
				UpdateDrawFeatures()
			}
		}
	// End of Bug Fix

	// Register components and Add InputFeatureCollection to draw
	useEffect(() => {
		map.addControl(draw, 'bottom-right');
		map.on('draw.create', UpdateDrawFeatures);
		map.on('draw.delete', UpdateDrawFeatures);
		map.on('draw.update', UpdateDrawFeatures);
		map.on('draw.selectionchange', handleDrawSelectionChange);
		map.on('touchend',handleMapTouchEnd)

		if (!EasyFnc.CheckUndefined(InputFeatureCollection)){
			if (FeatureCollectionHaveFeatures(InputFeatureCollection)) {
				AppDebugger.log("Input Feature collenction recieved, drawing it on map.");
				draw.add(InputFeatureCollection);
				map.fire('draw.update');
				dispatch(GLDrawActions.select(0));
			} else {
				AppDebugger.log('Empty Input Feature Collection received.')
			}
		} else {
			AppDebugger.log('No Input Feature Collection received.')
		}

		return () => {
			map.removeControl(draw);
			map.off('draw.create', UpdateDrawFeatures);
			map.off('draw.delete', UpdateDrawFeatures);
			map.off('draw.update', UpdateDrawFeatures);
			map.off('draw.selectionchange', handleDrawSelectionChange);
			map.off('touchend',handleMapTouchEnd)
			dispatch(GLDrawActions.ClearAll());
		}
	}, [InputFeatureCollection]);  // eslint-disable-line react-hooks/exhaustive-deps
	return null;
}

export default AddPolygonPointsMap;
