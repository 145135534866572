// Standard React components
import React from 'react';

// Material UI components
import {
	Container, Paper
} from '@material-ui/core';

// General Functions
import { useAppStyles } from '../../Libs/AppStyleClasses'

export const NewPaper = ({children}) => {
	const classes = useAppStyles()
	return (
		<Container maxWidth='md' className={classes.PaperContainer}>
			<Paper>
				{children}
			</Paper>
		</Container>
	)
}

export const NewPaperInner = ({children}) => {
	const classes = useAppStyles()
	return (
		<div className={classes.PaperInner}>
			{children}
		</div>
	)
}
