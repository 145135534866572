import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'PlacesSearch',
  initialState: {
    search_query: null,
    selectedId: null,
    status_code: null,
    FeatureCollection: {
        "type":"FeatureCollection",
        "totalFeatures":0,
        "features":[]
    }
  },
  reducers: {
    UpdateSearchQuery(state,action){
        state.search_query = action.payload
    },
    UpdateStatusCode(state,action){
        state.status_code = action.payload
    },
    refresh(state, action) {
        state.FeatureCollection = action.payload;
    },
    update(state, action) {
      action.payload.forEach(item => state.items[item['id']] = item);
    },
    select(state, action) {
      state.selectedId = action.payload;
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
    ClearAll(state){
        state.status_code = null
        state.selectedId = null
        state.FeatureCollection = {
            "type":"FeatureCollection",
            "totalFeatures":0,
            "features":[]
        }
    }
  }
});

export { actions as PlacesSearchActions };
export { reducer as PlacesSearchReducer };
