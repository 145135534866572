// Standard React components
import React from 'react';

import { PrimaryPageWrapper } from "..";
import { EditUsingIndex as AlphaZoneEditUsingIndex, EditUsingId as AlphaZoneEditUsingId } from './AlphaZone';
import { EditUsingIndex as BlueZoneEditUsingIndex, EditUsingId as BlueZoneEditUsingId } from './BlueZone';
import { EditUsingIndex as MyPropertyEditUsingIndex, EditUsingId as MyPropertyEditUsingId } from './MyProperty';

export const AlphaZoneId = () => {
	return (
		<PrimaryPageWrapper>
			<AlphaZoneEditUsingId/>
		</PrimaryPageWrapper>
	);
}

export const MyPropertyId = () => {
	return (
		<PrimaryPageWrapper>
			<MyPropertyEditUsingId/>
		</PrimaryPageWrapper>
	);
}

export const BlueZoneId = () => {
	return (
		<PrimaryPageWrapper>
			<BlueZoneEditUsingId/>
		</PrimaryPageWrapper>
	);
}

export const AlphaZoneIndex = () => {
	return (
		<PrimaryPageWrapper>
			<AlphaZoneEditUsingIndex/>
		</PrimaryPageWrapper>
	);
}

export const MyPropertyIndex = () => {
	return (
		<PrimaryPageWrapper>
			<MyPropertyEditUsingIndex/>
		</PrimaryPageWrapper>
	);
}

export const BlueZoneIndex = () => {
	return (
		<PrimaryPageWrapper>
			<BlueZoneEditUsingIndex/>
		</PrimaryPageWrapper>
	);
}