// Standard React components
import React, { useState, useEffect} from 'react';
import ContainerDimensions from 'react-container-dimensions';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { GLDrawActions } from '../../ReduxStore';

// Material UI components
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormControl, InputLabel, Select, MenuItem, IconButton, FormLabel } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditLocationIcon from '@material-ui/icons/EditLocation';

// App Components
import StatusCodeSwitcher from './StatusCodeSwitcher';
import {
	DrawButtonAdd, DrawButtonSelect, DrawButtonDelete, DrawButtonSubmit, DrawButtonCancel
} from '../../Map/Draw/Buttons';
import DefaultSidebar from '../Sidebars/DefaultSidebar';
import SidebarToggleButton from '../Buttons/SidebarToggleButton';
import SubdividePointsList from '../Lists/SubdividePointsList';
import {AddCoordinatesManuallyDialog, AddAddCoordinatesGPSDialog} from '../Dialogs/CoordinateDialogs';

// General Functions
import t from '../../Libs/localization';
import { useAppStyles } from '../../Libs/AppStyleClasses'
import { AppDebugger } from '../../App'

// Map & Map Features
import Map, { map } from '../../Map/Map';
import CurrentLocationMap from '../../Map/CurrentLocationMap';
import AddPolygonPointsMap, { draw } from '../../Map/Draw/AddPolygonPointsMap';
import BoundryCheckAlert from '../../Map/Draw/BoundryCheck';
import {
	DrawnFeaturesMapPolyFromPoints, DrawnFeaturesMapAllPoints, DrawnFeaturesMapSelectedPoints, BoundryPolygonFeatureMap
} from '../../Map/Layers/DrawnFeaturesMap';
//import { AlphaZoneMapSelected } from '../../Map/Layers/AlphaZoneMap';
import {
	CreateFeatureFromPointCoords
} from '../../Map/mapUtil';
import { FormatVolumeMetric } from '../../Libs/formatter';

export const GLDrawPageContent = ({
	StatusCode, handleSubmit, SubmitEnabled, MapChildren, InputFeatureCollection
}) => {
	const dispatch = useDispatch();
	const classes = useAppStyles();
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
	const isDesktop = (isTablet | isPhone) ? false : true;
	const [SidebarCollapsed_Edit, setSidebarCollapsed_Edit] = useState(true);

	const [AddMode,setAddMode] = useState(0);
	const [AddCoordinatesDialogIsOpen, setAddCoordinatesDialogIsOpen] = useState(false);
	const [AddAddCoordinatesGPSDialogIsOpen, setAddAddCoordinatesGPSDialogIsOpen] = useState(false);
	const [AddMarkerIndex,setAddMarkerIndex] = useState(null);
	const [AddMarkerCoords,setAddMarkerCoords] = useState(null);

	const [SelectedPointsFly2Selected,setSelectedPointsFly2Selected] = useState(true);
	const ThisFeatureArea = useSelector(state => state.GLDraw.FeatureArea);

	useEffect(() => {
		if (AddMarkerIndex !== null){
			if (AddMarkerCoords !== null){
				setAddCoordinatesDialogIsOpen(false);
				setAddAddCoordinatesGPSDialogIsOpen(false);
				let NewFeature = CreateFeatureFromPointCoords(AddMarkerCoords[0],AddMarkerCoords[1]);
				var NewFeatureID = draw.add(NewFeature);
				map.fire('draw.update');

				setAddMarkerIndex(null);
				setAddMarkerCoords(null);

				if (!isDesktop) {
					setSidebarCollapsed_Edit(true);
				};

				setTimeout(() => {dispatch(GLDrawActions.selectByID(NewFeatureID[0]))}, 100);
			}
		}
	}, [AddMarkerIndex,AddMarkerCoords,isDesktop]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleAddRequested = (val = -1) => {
		setAddMarkerIndex(val);
		switch(AddMode) {
			case 1:
				setAddCoordinatesDialogIsOpen(true);
				break;
			case 2:
				setAddAddCoordinatesGPSDialogIsOpen(true);
				break;
			default:
				setSidebarCollapsed_Edit(true);
				draw.changeMode('draw_point');
		}
	}

	const handleRemove = (index) => {
		let data = draw.getAll();
		let DeleteID = data.features[index]['id'];
		AppDebugger.log('Remove point ' + index + ' with id: ' + DeleteID);
		draw.delete(DeleteID);
		map.fire('draw.update');
	}

	const handleDrawModeChange = (e) => {
		if (e.mode === 'draw_point'){
			setSelectedPointsFly2Selected(false);
		} else {
			setSelectedPointsFly2Selected(true);
		};
	};

	useEffect(() => {
		map.on('draw.modechange', handleDrawModeChange);
		return () => {
			map.off('draw.modechange', handleDrawModeChange);
		}
	}, [])

	return (
		<StatusCodeSwitcher
			StatusCode = {StatusCode}
			Child200 = {
				<>
					<div className={classes.mapContainer}>
						<ContainerDimensions>
							<Map>
								<CurrentLocationMap />
								<BoundryPolygonFeatureMap/>
								{ MapChildren }
								<DrawnFeaturesMapPolyFromPoints/>
								<DrawnFeaturesMapAllPoints/>
								<DrawnFeaturesMapSelectedPoints Fly2Selected={SelectedPointsFly2Selected}/>
								<AddPolygonPointsMap
									InputFeatureCollection={InputFeatureCollection}
								/>
							</Map>
						</ContainerDimensions>
					</div>
					<div className={classes.sidebarToggleContainer}>
						<DrawButtonAdd/>
						<SidebarToggleButton
							handleClick = { () => setSidebarCollapsed_Edit(!SidebarCollapsed_Edit) }
							ThisIcon = { <EditLocationIcon /> }
							ThisHeader = { t('sharedDrawEdit') }
						/>
						<DrawButtonSelect/>
						<DrawButtonDelete/>
						<DrawButtonSubmit
							enabled={SubmitEnabled}
							handleClick={handleSubmit}
						/>
						<DrawButtonCancel/>
					</div>
					<div>
						<DefaultSidebar
							isCollapsed={SidebarCollapsed_Edit}
							handleDoCollapse={() => {
								setSidebarCollapsed_Edit(true)
							}}
							SidebarHeader = { t('sharedDrawEdit') }
							TopToolbar = {
								<>
									<FormControl variant="filled" fullWidth>
										<InputLabel>{t('str_jsrhyu0hfy')}</InputLabel>
										<Select
											value={AddMode}
											onChange={event => setAddMode(event.target.value)}
										>
											<MenuItem key={0} value={0}>{t('str_f1616mu5gl')}</MenuItem>
											<MenuItem key={1} value={1}>{t('str_vqt7srkask')}</MenuItem>
											<MenuItem key={2} value={2}>{t('str_k6hzeywbh8')}</MenuItem>
										</Select>
									</FormControl>
									{ThisFeatureArea &&
										<FormControl variant="filled" fullWidth>
											<FormLabel
												margin="normal"
											>
												{t('sharedArea')}: {FormatVolumeMetric(ThisFeatureArea)}
											</FormLabel>
										</FormControl>
									}

								</>
							}
							ItemList = {
								<SubdividePointsList
									handleAdd={handleAddRequested}
									handleRemove={handleRemove}
							/> }
							BottomToolbar = {
								<>
									<IconButton
										classes={{ label: classes.navItem }}
										onClick={() => {handleAddRequested()}}
									>
										<AddIcon />
										{t('str_wg6y48tumm')}
									</IconButton>
								</>
							}
						/>
					</div>
					<div>
						<AddCoordinatesManuallyDialog
							handleSubmit={setAddMarkerCoords}
							handleCancel={() => setAddCoordinatesDialogIsOpen(false)}
							isOpen={AddCoordinatesDialogIsOpen}
						/>
						<AddAddCoordinatesGPSDialog
							handleSubmit={setAddMarkerCoords}
							handleCancel={() => setAddAddCoordinatesGPSDialogIsOpen(false)}
							isOpen={AddAddCoordinatesGPSDialogIsOpen}
						/>
						<BoundryCheckAlert
							handleContainedCheck={() => {}}
						/>
					</div>
				</>
			}
		/>
	);
};

export default GLDrawPageContent