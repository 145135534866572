import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Drawer,
	List,
	ListSubheader,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import BugReportIcon from '@material-ui/icons/BugReport';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import GavelIcon from '@material-ui/icons/Gavel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ComponentStyles from './Styles';

import t from '../../Libs/localization';

import {DevelopmentMenu} from './DevelopmentMenu'

const MainToolbar = ({ToggleDebugger, PageMenuButtonIcon, PageMenuContent, showBack}) => {
	const [drawer, setDrawer] = useState(false);
	const [DevDrawer, setDevDrawer] = useState(false);
	const [PageDrawer, setPageDrawer] = useState(false);

	const classes = ComponentStyles();
	const history = useHistory();
	//const adminEnabled = useSelector(state => state.token.user && state.token.user.administrator);
	const access_level = useSelector(state => state.UserConfig.access_level);
	const isDev = useSelector(state => state.AppConfig.isDev);

	const openDrawer = () => { setDrawer(true) }
	const closeDrawer = () => { setDrawer(false) }
	const openDevDrawer = () => { setDevDrawer(true) }
	const closeDevDrawer = () => { setDevDrawer(false) }
	const openPageDrawer = () => { setPageDrawer(true) }
	const closePageDrawer = () => { setPageDrawer(false) }

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar>
					<IconButton
						className={classes.menuButton}
						color="inherit"
						onClick={openDrawer}>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" color="inherit" className={classes.flex}>
						eTitle
					</Typography>
					{isDev &&
						<>
							<IconButton
								className={classes.iconButton}
								color="inherit"
								onClick={ToggleDebugger}>
								<BugReportIcon />
							</IconButton>
							<IconButton
								className={classes.iconButton}
								color="inherit"
								onClick={openDevDrawer}
							>
								<DeveloperModeIcon />
							</IconButton>
						</>
					}

					{showBack &&
						<IconButton
							className={classes.iconButton}
							color="inherit"
							onClick={() => history.goBack()}
						>
							<ArrowBackIcon />
						</IconButton>
					}
					{PageMenuButtonIcon &&
						<IconButton
							className={classes.iconButton}
							color="inherit"
							onClick={openPageDrawer}
						>
							{PageMenuButtonIcon}
						</IconButton>
					}

					{access_level === 0 ?
						<Button
							className={classes.LoginButton}
							onClick={() => history.push('/login')}
							variant='contained'
						>
							{t('loginLogin')}
						</Button>
					:
						<IconButton
							className={classes.iconButton}
							color="inherit"
							onClick={() => history.push('/user/edit/me')}
						>
							<AccountCircleIcon />
						</IconButton>
					}
				</Toolbar>
			</AppBar>
			<Drawer open={drawer} onClose={closeDrawer}>
				<div
					tabIndex={0}
					className={classes.list}
					role="button"
					onClick={closeDrawer}
					onKeyDown={closeDrawer}
				>
					{access_level === 0
					? <>
						<PublicMenu/>
					</>
					: <>
						<SecureMenu/>
						{access_level === 10 && (<AccessLevel10Menu/>)}
						{access_level === 15 && (<AccessLevel15Menu/>)}
						{access_level === 20 && (<AccessLevel20Menu/>)}
					</>
					}

					<Divider />
					<List>
					{access_level > 0 ?
						<ListItem button onClick={() => history.push('/logout')}>
							<ListItemIcon>
								<ExitToAppIcon />
							</ListItemIcon>
							<ListItemText primary={t('loginLogout')} />
						</ListItem>
					:
						<ListItem button onClick={() => history.push('/login')}>
							<ListItemIcon>
								<ExitToAppIcon />
							</ListItemIcon>
							<ListItemText primary={t('loginLogin')} />
						</ListItem>
					}
					</List>
				</div>
			</Drawer>
			<Drawer open={DevDrawer} onClose={closeDevDrawer} anchor="right">
				<div
					tabIndex={0}
					className={classes.list}
					role="button"
					onClick={closeDevDrawer}
					onKeyDown={closeDevDrawer}
				>
					<DevelopmentMenu/>
				</div>
			</Drawer>
			<Drawer open={PageDrawer} onClose={closePageDrawer} anchor="right">
				<div
					tabIndex={0}
					className={classes.list}
					role="button"
					onClick={closePageDrawer}
					onKeyDown={closePageDrawer}
				>
					{PageMenuContent && PageMenuContent}
				</div>
			</Drawer>
		</>
	);
}

const AccessLevel10Menu = () => {
	const history = useHistory();
	return (
		<>
			<Divider />
			<List
				subheader={
					<ListSubheader>
						{t('accessLevel_10')}
					</ListSubheader>
				}>
				<ListItem button onClick={() => history.push('/user/multiview')}>
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary={t('settingsUsers')} />
				</ListItem>
			</List>
		</>
	)
}

const AccessLevel15Menu = () => {
	const history = useHistory();
	return (
		<>
			<Divider />
			<List
				subheader={
					<ListSubheader>
						{t('accessLevel_15')}
					</ListSubheader>
				}>
				<ListItem button onClick={() => history.push('/user/multiview')}>
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary={t('settingsUsers')} />
				</ListItem>
			</List>
		</>
	)
}

const AccessLevel20Menu = () => {
	const history = useHistory();
	return (
		<>
			<Divider />
			<List
				subheader={
					<ListSubheader>
						{t('menu_subheader_alpha')}
					</ListSubheader>
				}>
				<ListItem button onClick={() => history.push('/alpha/global/add')}>
					<ListItemIcon>
						<AddBoxIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu_alpha_add')} />
				</ListItem>
			</List>
			<Divider />
			<List
				subheader={
					<ListSubheader>
						{t('accessLevel_20')}
					</ListSubheader>
				}>
				<ListItem button onClick={() => history.push('/user/multiview')}>
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary={t('settingsUsers')} />
				</ListItem>
			</List>
		</>
	)
}

const SecureMenu = () => {
	const history = useHistory();
	const userId = useSelector(state => state.UserConfig.user && state.UserConfig.user.id);
	return(<>
		<List>
			<ListItem button onClick={() => history.push('/')}>
				<ListItemIcon>
					<HomeIcon />
				</ListItemIcon>
				<ListItemText primary={t('homeTitle')} />
			</ListItem>
		</List>
		<Divider />
		<List
			subheader={
				<ListSubheader>
					{t('sharedMyGreen')}
				</ListSubheader>
			}>
		</List>
		<ListItem button onClick={() => history.push('/my_green/subdivide')}>
			<ListItemIcon>
				<CallSplitIcon />
			</ListItemIcon>
			<ListItemText primary={t('sharedSubdivide')} />
		</ListItem>

		<Divider />
		<List
			subheader={
				<ListSubheader>
					{t('sharedContracts')}
				</ListSubheader>
			}>
		</List>
		<ListItem button onClick={() => history.push('/contract/multiview/')}>
			<ListItemIcon>
				<InsertDriveFileIcon />
			</ListItemIcon>
			<ListItemText primary={t('str_2kixehhoqu')} />
		</ListItem>
		<ListItem button onClick={() => history.push('/lessee/multiview/')}>
			<ListItemIcon>
				<PeopleIcon />
			</ListItemIcon>
			<ListItemText primary={t('str_88tl0i755t')} />
		</ListItem>
		<ListItem button onClick={() => history.push('/lessor/multiview/')}>
			<ListItemIcon>
				<GavelIcon />
			</ListItemIcon>
			<ListItemText primary={t('str_81tdwxfy0z')} />
		</ListItem>

		<Divider />
		<List
			subheader={
				<ListSubheader>
					{t('settingsTitle')}
				</ListSubheader>
			}>
			<ListItem button disabled={!userId} onClick={() => history.push(`/user/edit/me`)}>
				<ListItemIcon>
					<PersonIcon />
				</ListItemIcon>
				<ListItemText primary={t('settingsUser')} />
			</ListItem>
		</List>
	</>)
}

const PublicMenu = () => {
	const history = useHistory();
	return(<>
		<List>
			<ListItem button onClick={() => history.push('/')}>
				<ListItemIcon>
					<HomeIcon />
				</ListItemIcon>
				<ListItemText primary={t('homeTitle')} />
			</ListItem>
		</List>
	</>)
}

export default MainToolbar;
