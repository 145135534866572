// Standard React components
import { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Map Components
import { FeatureCollectionLayer, DefaultFeatureCollectionLayerOptions } from './FeatureCollectionLayer';

export const MyGreenZoneMapAll = ({ShowLayer}) => {
	const ThisFeatureCollection = useSelector(state => state.MyGreenZone.FeatureCollection);
	const id = 'MyGreenZone';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

	return null;
}

export const MyGreenZoneMapSelected = ({ShowLayer, Fly2Selected, Fly2SelectedOptions}) => {
	const ThisFeatureCollection = useSelector(state => state.MyGreenZone.FeatureCollection);
	const [ThisFeature,setThisFeature] = useState([])
	const selectedId = useSelector(state => state.MyGreenZone.selectedId);
	const id = 'MyGreenZone_Selected';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.polygon.paint = {
		'fill-color':'#3bd0cb',
		'fill-outline-color':'#3bd0cb',
		'fill-opacity':0.5,
	}
	if (Fly2Selected){
		FCOptions.Fly2Feature.enabled = true;
	}

	if (Fly2SelectedOptions){
		Object.keys(Fly2SelectedOptions).map(key => (
			FCOptions.Fly2Feature[key] = Fly2SelectedOptions[key]
		));
	}

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeature,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

	useEffect(() => {
		if (selectedId !== null){
			setThisFeature([ThisFeatureCollection.features[selectedId]]);
		};
	}, [ThisFeatureCollection,selectedId]);
	return null;
}