// Standard React
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// Redux
import { useDispatch } from 'react-redux';
import { tokenActions, AppConfigActions } from '../../ReduxStore';

// Material UI
import {
	Grid,
	makeStyles,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	Button,
	Link,
	Avatar,
	CssBaseline,
	TextField,
	FormControlLabel,
	Checkbox,
	Box,
	Paper,
	Typography
 } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

// General Functions
import t from '../../Libs/localization'
import { AppDebugger, MyAPIFetcher } from '../../App'

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https:/etitle.africa/">
			{t('sharedProductName')}
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(https://images.unsplash.com/photo-1600213270195-a360581dad95?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1248&q=80)',
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function SignInSide() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [cookies, setCookie] = useCookies(['username']);

	const [failed, setFailed] = useState(false);
	const [email, setEmail] = useState(cookies.username || "");
	const [password, setPassword] = useState("");
	const [RememberMe, setRememberMe] = useState(false);
	const [Language,SetLanguage] = useState("en")

	const Languages = {
		"en": "English"
	}

	const handleEmailChange = (event) => {
		if (event.target.value === 'enabledev'){
			setEmail("");
			AppDebugger.EnableDebugger();
			dispatch(AppConfigActions.enableDev());
		} else if (event.target.value === 'disabledev'){
			setEmail("");
			AppDebugger.DisableDebugger();
			dispatch(AppConfigActions.disableDev());
		} else {
			setEmail(event.target.value);
		}
	}

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	}

	const handleLogin = async (event) => {
		event.preventDefault();
		const PostObj = {
			"user": {
				"username": "",
				"email": email,
				"password": password
			},
			"grant_type": "password",
			"refresh_token": ""
		}
		const response = await MyAPIFetcher.DoLogin(PostObj)
		if (response) {
			//dispatch(tokenActions.updateAccessToken(data.payload.access_token));
			dispatch(tokenActions.updateToken(response.payload));
			MyAPIFetcher.SetTokens(response.payload)
			if (RememberMe){
				var cookie_options = {
					path: '/',
					sameSite: true,
					maxAge : 2.628e6
				}
				setCookie('username', email, cookie_options);
			}
			history.push('/');
		} else {
			setFailed(true);
			setPassword('');
		}
	}

	const handleSpecialKey = (event) => {
		if (event.keyCode === 13 && email && password) {
			handleLogin(event);
		}
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						{t('loginLogin')}
					</Typography>
					{Object.keys(Languages).length > 1 &&
						<FormControl variant="filled" fullWidth>
							<InputLabel>{t('loginLanguage')}</InputLabel>
							<Select
								value={Language}
								onChange={(e) => SetLanguage(e.target.value)}
							>
								{Object.keys(Languages).map((index) => (
									<MenuItem value={index}>{Languages[index]}</MenuItem>
								))}

							</Select>
						</FormControl>
					}
					<form className={classes.form} noValidate>
						<TextField
							required
							fullWidth
							error={failed}
							label={t('username')}
							name='email'
							value={email}
							autoComplete='email'
							autoFocus
							onChange={handleEmailChange}
							onKeyUp={handleSpecialKey}
							helperText={failed && 'Invalid username or password'}
							variant='filled' />
						<TextField
							required
							fullWidth
							error={failed}
							label={t('userPassword')}
							name='password'
							value={password}
							type='password'
							autoComplete='current-password'
							onChange={handlePasswordChange}
							onKeyUp={handleSpecialKey}
							variant='filled'
						/>
						<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label="Remember me"
							onChange={(event) => {setRememberMe(event.target.checked)}}
							checked={RememberMe}
						/>
						<Button
							onClick={handleLogin}
							onKeyUp={handleEmailChange}
							variant='contained'
							color='secondary'
							disabled={!email || !password}
							fullWidth
						>
							{t('loginLogin')}
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2">
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								<Link href="#" variant="body2">
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
						<Box mt={5}>
							<Button
								onClick={() => history.push('/home')}
								variant='contained'
								color='primary'
								fullWidth
							>
								{t('str_qlkgolx3q0')}
							</Button>
						</Box>
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
}