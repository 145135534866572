import af from '../../l10n/af.json';
import en from '../../l10n/en.json';


const supportedLanguages = {
  'af': { data: af, name: 'Afrikaans' },
  'en': { data: en, name: 'English' }
};

const languages = window.navigator.languages !== undefined ? window.navigator.languages.slice() : [];
let language = window.navigator.userLanguage || window.navigator.language;
languages.push(language);
languages.push(language.substring(0, 2));
languages.push('en');
for (let i = 0; i < languages.length; i++) {
  language = languages[i].replace('-', '_');
  if (language in supportedLanguages) {
    break;
  }
  if (language.length > 2) {
    language = languages[i].substring(0, 2);
    if (language in supportedLanguages) {
      break;
    }
  }
}

const selectedLanguage = supportedLanguages[language];

export const findStringKeys = (predicate) => {
  return Object.keys(selectedLanguage.data).filter(predicate);
}

export default key => {
  return selectedLanguage.data[key];
};
