// Standard React
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Material UI
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// General Functions
import t from '../../Libs/localization';

const PopupDialog = ({ open, endpoint, itemId, onResult }) => {
	const history = useHistory();
	const access_level = useSelector(state => state.UserConfig.access_level);

	const handleSubdivide_Property = () => {
		history.push(`${endpoint}?t=property&z=${itemId-1}`);
	}
	const handleSubdivide_Blue = () => {
		history.push(`${endpoint}?t=blue&z=${itemId-1}`);
	}

	return (
		<>
		{ access_level >= 5
		?
			<Dialog
				open={open}
				onClose={() => { onResult(false) }}>
				<DialogContent>
					<DialogContentText>{t('sharedSubdivideType')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleSubdivide_Property}>{t('sharedProperty')}</Button>
					<Button autoFocus onClick={handleSubdivide_Blue}>{t('sharedBlueZone')}</Button>
				</DialogActions>
			</Dialog>
		: <>
			{open && handleSubdivide_Property()}
		</>
		}
		</>
	);
};

const ContextMenu = ({ content, infoPath, subdividePath }) => {
	const history = useHistory();
	const [selectedId, setSelectedId] = useState(null);
	const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
	const [subdivideDialogShown, setSubdivideDialogShown] = useState(false);

	const menuShow = (anchorId, itemId) => {
		setSelectedAnchorEl(anchorId);
		setSelectedId(itemId);
	}

	const menuHide = () => {
		setSelectedAnchorEl(null);
	}

	const handleInfo = () => {
    history.push(`${infoPath}/${selectedId}`);
		menuHide();
  }

	const handleSubdivide = () => {
		setSubdivideDialogShown(true);
		menuHide();
	}

	const handleSubdivideResult = () => {
		setSubdivideDialogShown(false);
		//setUpdateTimestamp(Date.now());
	}

	const Content = content;

	return (
		<>
			<Content onMenuClick={menuShow} />
			<Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
				<MenuItem onClick={handleInfo}>{t('sharedInfoTitle')}</MenuItem>
				<MenuItem onClick={handleSubdivide}>{t('sharedSubdivide')}</MenuItem>
			</Menu>
			<PopupDialog open={subdivideDialogShown} endpoint={subdividePath} itemId={selectedId} onResult={handleSubdivideResult} />
		</>
	);
}

export default ContextMenu;