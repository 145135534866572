// Standard React components
import { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Map Components
import { FeatureCollectionLayer, DefaultFeatureCollectionLayerOptions } from './FeatureCollectionLayer';

export const BlueZoneMapAll = ({ShowLayer}) => {
	const ThisFeatureCollection = useSelector(state => state.BlueZone.FeatureCollection);
	const id = 'BlueZone';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

	return null;
}

export const BlueZoneMapSelected = ({ShowLayer, Fly2Selected, Fly2SelectedOptions}) => {
	const ThisFeatureCollection = useSelector(state => state.BlueZone.FeatureCollection);
	const [ThisFeature,setThisFeature] = useState([])
	const selectedId = useSelector(state => state.BlueZone.selectedId);
	const id = 'BlueZone_Selected';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.polygon.paint = {
		'fill-color':'#3b5bd0',
		'fill-outline-color':'#3b5bd0',
		'fill-opacity':0.5,
	}
	if (Fly2Selected){
		FCOptions.Fly2Feature.enabled = true;
	}

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeature,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

	useEffect(() => {
		if (selectedId !== null){
			setThisFeature([ThisFeatureCollection.features[selectedId]]);
		};
	}, [ThisFeatureCollection,selectedId]);
	return null;
}