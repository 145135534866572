import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

// General Functions
import t from '../../Libs/localization';
import { isSetNotBlank } from '../../Libs/EasyFnc';

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(2),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'space-evenly',
		'& > *': {
			flexBasis: '33%',
		},
	},
}));

export const BlankForm = ({ ContainerChildren, FormChildren }) => {
	const classes = useStyles();

	return (
		<>
			<Container maxWidth='xs' className={classes.container}>
				{ContainerChildren}
				<FormControl fullWidth margin='normal'>
					{FormChildren}
				</FormControl>
			</Container>
		</>
	);
}


const SubmitForm = ({ children, handleSubmit, CancelButtonText, SubmitButtonText }) => {
	const history = useHistory();
	const classes = useStyles();

	return (
		<>
			<BlankForm
				ContainerChildren={children}
				FormChildren={
					<div className={classes.buttons}>
						<Button type='button' color='primary' variant='outlined' onClick={() => history.goBack()}>
							{ isSetNotBlank(CancelButtonText) ? CancelButtonText : t('sharedCancel')}
						</Button>
						<Button type='button' color='primary' variant='contained' onClick={handleSubmit}>
							{ isSetNotBlank(SubmitButtonText) ? SubmitButtonText : t('sharedSubmit')}
						</Button>
					</div>
				}
			/>
		</>
	);
}

export default SubmitForm;
