import { createSlice } from '@reduxjs/toolkit';
import {AppDebugger} from '../App';

const { reducer, actions } = createSlice({
	name: 'AlphaZone',
	initialState: {
		updateTimestamp: null,
		updateRequestTimestamp: null,
		selectedId: null,
		status_code: null,
		FeatureCollection: {
				"type":"FeatureCollection",
				"totalFeatures":0,
				"features":[]
		}
	},
	reducers: {
		DoUpdate(state){
			AppDebugger.log('Store[AlphaZone]: DoUpdate')
			state.updateRequestTimestamp = new Date().getTime()
		},
		UpdateTimestamp(state,action){
			state.updateTimestamp = action.payload;
		},
		UpdateStatusCode(state,action){
			state.status_code = action.payload;
		},
		refresh(state, action) {
			if ('features' in action.payload) {
				state.FeatureCollection = action.payload;
				AppDebugger.log('FeatureCollection updated: AlphaZone')
			}
		},
		update(state, action) {
			action.payload.forEach(item => state.items[item['id']] = item);
		},
		select(state, action) {
			//AppDebugger.log('Store[AlphaZone]: select called')
			state.selectedId = action.payload;
		},
		remove(state, action) {
			delete state.items[action.payload];
		},
		ClearAll(state){
			console.log('Store[AlphaZone]: ClearAll')
				state.status_code = null
				state.selectedId = null
				state.FeatureCollection = {
						"type":"FeatureCollection",
						"totalFeatures":0,
						"features":[]
				}
		}
	}
});

export { actions as AlphaZoneActions };
export { reducer as AlphaZoneReducer };
