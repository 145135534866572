// Standard React components
import React, {useState} from 'react';

// App Components
import MainToolbar from '../Components/Navigation/PrimaryDrawer';

// General Functions
import { useAppStyles } from '../Libs/AppStyleClasses'

// Pages
import MainPage from './MapPages/Page_Main';
import OnScreenDebugger from '../Components/PageContent/OnScreenDebugger';

import * as Global from './Global';
import * as AuthPages from './Authentication';
import * as AdminPages from './Admin';
import * as MapPages from './MapPages';
import * as InfoPages from './InfoPages';
import * as UserManagementPages from './UserManagement';
import * as GreenUser from './GreenUser';
import * as LesseePages from './Lessee';
import * as LessorPages from './Lessor';
import * as ContractPages from './Contract';
import * as FileManagerPages from './FileManager'
import * as ZonePropertiesEditPages from './ZonePropertiesEdit'
import { useSelector } from 'react-redux';

import { MenuButton,MenuContent } from '../Components/Navigation/BlankPageMenu';

export {
	Global,
	AdminPages,
	MapPages,
	InfoPages,
	UserManagementPages,
	GreenUser,
	LesseePages,
	LessorPages,
	ContractPages,
	FileManagerPages,
	ZonePropertiesEditPages
};

export const PrimaryPageWrapper = ({children, PageMenuButtonIcon, PageMenuButton,PageMenuContent, showBack}) => {
	const classes = useAppStyles();
	const [ShowDebugger, setShowDebugger] = useState(false)

	return (
		<div className={classes.root}>
			<MainToolbar
				ToggleDebugger={() => setShowDebugger(!ShowDebugger)}
				PageMenuButtonIcon={PageMenuButtonIcon || null}
				PageMenuContent={PageMenuContent || null}
				showBack={showBack || false}
			/>
			<div className={classes.PageContent}>
			{ ShowDebugger
			?	<OnScreenDebugger/>
			: <>{children}</>
			}
			</div>
		</div>
	);
}

export const _blank = () => {
	return(
		<PrimaryPageWrapper
			PageMenuButton={MenuButton}
			PageMenuContent={MenuContent}
		>
			<p>This is a blank page</p>
		</PrimaryPageWrapper>
	)
}

export const IndexPage = () => {
	const ShowWelcome = useSelector(state => state.AppConfig.show_welcome_screen);
	return(<>
		{ShowWelcome ? <Global.WelcomeScreen/> : <HomePage/>}
	</>)
}

export const HomePage = () => {
	return(
		<PrimaryPageWrapper>
			<MainPage/>
		</PrimaryPageWrapper>
	)
}

export const Logout = () => {
	return(
		<PrimaryPageWrapper>
			<AuthPages.Logout/>
		</PrimaryPageWrapper>
	)
}

