// Standard React
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';

// Redux
import { Provider } from 'react-redux';
import store from './ReduxStore';

// Styles
import 'typeface-roboto'
import 'mapbox-gl/dist/mapbox-gl.css';

// App Components
import App from './App';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <CookiesProvider>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </CookiesProvider>
), document.getElementById('root'));

//if (process.env.NODE_ENV || !process.env.NODE_ENV === 'development' ) { serviceWorker.register(); }
