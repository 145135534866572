// Standard React components
import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Map Components
import mapboxgl from 'mapbox-gl';
import { map } from '../Map';
import { Fly2Point } from '../mapUtil';
import { FeatureCollectionLayer, DefaultFeatureCollectionLayerOptions } from './FeatureCollectionLayer';

// Material UI components
import {
  Button,
} from '@material-ui/core';

import { MyPropertyActions } from '../../ReduxStore';

import t from '../../Libs/localization'

export const MyPropertyMapAll = ({ShowLayer}) => {
	const dispatch = useDispatch();
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	const id = 'MyProperty_All';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

	const ids = [
		id + '-polygon',
		id + '-line',
		id + '-point'
	];

	const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
	const onMouseLeave = () => map.getCanvas().style.cursor = '';

	const onClickCallback = useCallback(event => {
		const feature = event.features[0];
		const UniqueID = feature.properties.unique_id
		ThisFeatureCollection.features.forEach((item, index) => {
			if (item.properties.unique_id === UniqueID){
				dispatch(MyPropertyActions.select(index))
			}
		})
  	}, [ThisFeatureCollection]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		ids.forEach((item) => {
			if (item === (id + '-polygon')){
				map.on('mouseenter', item, onMouseEnter);
				map.on('mouseleave', item, onMouseLeave);
			}
			map.on('click', item, onClickCallback);
		})

	}, [ids,onClickCallback]);

  return null;
}

var MyPopup = new mapboxgl.Popup({
	offset: 2,
	anchor: 'top'
})
const MyMarker = new mapboxgl.Marker({id:'MyPropertyMarker'})
var currentMarkers=[];

const HideCurrentMarkers = () => {
	if (currentMarkers!==null) {
		for (var i = currentMarkers.length - 1; i >= 0; i--) {
			currentMarkers[i].remove();
		}
	}
}

const ShowCurrentMarkers = () => {
	if (currentMarkers!==null) {
		for (var i = currentMarkers.length - 1; i >= 0; i--) {
			currentMarkers[i].addTo(map);
		}
	}
}

export const MyPropertyMapSelected = ({ShowLayer, Fly2Selected, Fly2SelectedOptions}) => {
	const history = useHistory();
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	const [ThisFeature,setThisFeature] = useState([])
	const selectedId = useSelector(state => state.MyProperty.selectedId);
	const id = 'MyProperty_Selected';

	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.polygon.paint = {
		'fill-color':'#3bd0cb',
		'fill-outline-color':'#3bd0cb',
		'fill-opacity':0.5,
	}
	if (Fly2Selected){
		FCOptions.Fly2Feature.enabled = true;
	}

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeature,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

	useEffect(() => {
		if (map.getSource(id) && selectedId !== null){
			if (ThisFeatureCollection.features.length > 0){
				const ThisFeature = ThisFeatureCollection.features[selectedId];
				setThisFeature([ThisFeature]);
				let coordinates = Fly2Point(ThisFeature)
				const placeholder = document.createElement('div');
				ReactDOM.render(
					<div>
						<b>{ThisFeature.properties.name}</b><br/>
						<b>{t('sharedUniqueId')}: </b>{ThisFeature.properties.unique_id}<br/>
						{ThisFeature.properties.description}<br/>
						<br/>
						<Button
							variant="contained"
							onClick={() => history.push(`/prop/me/info/${selectedId}`)}
							disableElevation
						>
							{t('sharedShowDetails')}
						</Button>
						<br />
						<Button
							variant="contained"
							onClick={() => history.push(`/prop/me/navigate/point/${selectedId}`)}
							disableElevation
						>
							{t('str_ce1q7gwg95')}
						</Button>
					</div>,
					placeholder
				);

				MyPopup.setDOMContent(placeholder)
				MyMarker.setLngLat(coordinates);
				MyMarker.setPopup(MyPopup);
				MyMarker.addTo(map);
				MyMarker.togglePopup();
				currentMarkers.push(MyMarker);
			}
		}
	}, [ThisFeatureCollection,selectedId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		HideCurrentMarkers()

		return () => {
			HideCurrentMarkers()
		}
	}, []);

	useEffect(() => {
		if (ShowLayer){
			ShowCurrentMarkers()
		} else {
			HideCurrentMarkers()
		}
	}, [ShowLayer]);
  return null;
}

export const MyPropertyMapExisting = ({ShowLayer}) => {
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	const id = 'MyProperty_Existing';
	let FCOptions = DefaultFeatureCollectionLayerOptions()

	FCOptions.layers.polygon.paint = {
		'fill-color':'#e0392d',
		'fill-outline-color':'#e02d6f',
		'fill-opacity':0.5,
	}

	FeatureCollectionLayer({
		SourceID:id,
		Features:ThisFeatureCollection.features,
		ShowLayer:ShowLayer,
		Options:FCOptions
	})

  return null;
}