import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
	name: 'AppConfig',
	initialState: {
		status_code: null,
		show_welcome_screen: true,
		project_json: null,
		map_defaults: {
			bbox: null,
			style: null,
		},
		map_styles: null,
		api_keys: {
			mapbox: null,
			jc2: null,
		},
		isDev: false,
		geolocate: {
			isValid: false,
			timestamp: null,
			coords: {
				accuracy: null,
				altitude: null,
				altitudeAccuracy: null,
				heading: null,
				latitude: null,
				longitude: null,
			}
		}
	},
	reducers: {
		setStatusCode(state,action) {
			state.status_code = action.payload;
		},
		setShowWelcomeScreen(state,action) {
			state.show_welcome_screen = action.payload;
		},
		updateProjectJSON(state, action) {
			state.project_json = action.payload
			for (var key in action.payload.config.map_defaults){
				if (key === 'bbox') { state.map_defaults.bbox = action.payload.config.map_defaults.bbox}
				else if (key === 'style') { state.map_defaults.style = action.payload.config.map_defaults.style}
			}
			state.map_styles = action.payload.config.map_styles

			for (var key2 in action.payload.api_keys){
				if (key2 === 'mapbox') { state.api_keys.mapbox = action.payload.api_keys.mapbox}
			}
		},
		enableDev(state){
			state.isDev = true
		},
		disableDev(state){
			state.isDev = false
		},
		updateGeoLocation(state,action) {
			state.geolocate.timestamp = action.payload.timestamp || null
			state.geolocate.coords.accuracy = action.payload.coords.accuracy || null
			state.geolocate.coords.altitude = action.payload.coords.altitude || null
			state.geolocate.coords.altitudeAccuracy = action.payload.coords.altitudeAccuracy || null
			state.geolocate.coords.heading = action.payload.coords.heading || null
			state.geolocate.coords.latitude = action.payload.coords.latitude || null
			state.geolocate.coords.longitude = action.payload.coords.longitude || null

			if (state.geolocate.coords.latitude !== null & state.geolocate.coords.longitude !== null){
				state.geolocate.isValid = true;
			} else {
				state.geolocate.isValid = false
			}
		}
	},
});

export { actions as AppConfigActions };
export { reducer as AppConfigReducer };
