import {
	makeStyles
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const MyStyles = makeStyles((theme) => ({
	flex: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	list: {
		width: 250
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	LoginButton: {
		background: theme.palette.common.green,
		color: 'white',
		margin: 5
	},
	iconButton: {
		marginLeft: 5,
		marginRight: 5
	},
}));

const ComponentStyles = () => {
	const theme = useTheme();
	const classes = MyStyles(theme);
	return classes
}

export default ComponentStyles