// Standard React components
import React from 'react';

// App Components
import { LoadingScreen, SubmittingScreen } from './ProcessingRequestScreens';
import { ErrorScreen, SuccessScreen } from './RequestProcessedScreens';

// General Functions
import t from '../../Libs/localization';
import { useAppStyles } from '../../Libs/AppStyleClasses'
import { CheckNullOrUndefined } from '../../Libs/EasyFnc'

const StatusCodeSwitcher = ({
	StatusCode,
	ChildNull,
	Child100,
	Child200,
	Child500,
	ChildError
}) => {
	const classes = useAppStyles()
  return (
		<div className={classes.PageContent}>
			{ StatusCode === null | StatusCode === undefined
				?	(CheckNullOrUndefined(ChildNull)
					? <LoadingScreen/>
					: (<>{ChildNull}</>)
				)
				: StatusCode === 100 ? (
					(CheckNullOrUndefined(Child100)
						? <LoadingScreen/>
						: (<>{Child100}</>)
					)
				)
				: StatusCode === 200
				? (
					<>
					{Child200}
					</>
				)
				: StatusCode === 500
				? (
					<>
					{Child500}
					</>
				)
				: (CheckNullOrUndefined(ChildError) ? (
					<div>
						<h3>{t('errorTitle')}</h3>
					</div>
				) : (<>{ChildError}</>))
			}
		</div>
  );
}

export const APICallStatusCodeSwitcher = ({StatusCode, ChildNull, Child100, Child200, ChildError}) => {
	return (
		<StatusCodeSwitcher
			StatusCode = {StatusCode}
			ChildNull = {ChildNull || <></>}
			Child100 = {Child100 || <SubmittingScreen/>}
			Child200 = {Child200 || <SuccessScreen/>}
			ChildError = {ChildError || <ErrorScreen/>}
		/>
	)
}

export default StatusCodeSwitcher