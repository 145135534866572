// Standard React Components
import React, { useState, useEffect } from 'react';

// Material UI Components
import {
	Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../../Legacy/attributes/EditAttributesView';

// General Functions
import { MyAPIFetcher, AppDebugger } from '../../App';
import t from '../../Libs/localization';

// App Components
import { APICallStatusCodeSwitcher } from '../../Components/PageContent/StatusCodeSwitcher';
import { SuccessScreen,ErrorScreen } from '../../Components/PageContent/RequestProcessedScreens';
import SubmitForm from '../../Components/FormControls/SubmitForm';
import CountrySelector from '../../Components/Selectors/Country';

// Other
import userAttributes from '../../Legacy/attributes/userAttributes';
import { useAppStyles } from '../../Libs/AppStyleClasses';

const AddNewBody = () => {
	const classes = useAppStyles();
	const [item, setItem] = useState();
	const [SubmitStatusCode, SetSubmitStatusCode] = useState(null)

	useEffect(() => {
		setItem({
			individual:{
				first_name:null,
				last_name:null,
				citizenship_country: 'za',
				id_number:null,
				passport_number:null,
			},
			company:{
				registered_name:null,
				reg_number:null,
				registration_country: 'za',
			},

			email:null,
			telephone:null,
			enabled: true,
			category:'individual'
		})
	}, [])

	const handleSubmit = async () => {
		let PostObj = item;
		if (PostObj.category === 'individual'){
			delete PostObj.company;
		}
		if (PostObj.category === 'company'){
			delete PostObj.individual;
		}
		SetSubmitStatusCode(100);
		const response = await MyAPIFetcher.post('lessee/add',PostObj);
		if (response[0]) {
			SetSubmitStatusCode(200);
			AppDebugger.log('New lessee added');
		} else {
			SetSubmitStatusCode(404);
			AppDebugger.log('Failed to add new lessee');
		}
	};

	return (
		<APICallStatusCodeSwitcher
				StatusCode = {SubmitStatusCode}
				ChildNull = {
					<SubmitForm handleSubmit={handleSubmit}>
						{item &&
							<>
								<Accordion defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="subtitle1">
											{t('str_p0ohrp4q70')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.FormAccordionDetails}>
										<FormControl variant="filled" fullWidth>
											<InputLabel>{t('str_p0ohrp4q70')}</InputLabel>
											<Select
												value={item.category}
												onChange={event => setItem({...item, category: event.target.value})}
											>
												<MenuItem key={0} value="individual">{t('sharedIndividual')}</MenuItem>
												<MenuItem key={1} value="company">{t('sharedCompany')}</MenuItem>
											</Select>
										</FormControl>
									</AccordionDetails>
								</Accordion>

								{item.category === 'individual' && <>
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('str_sobtu027yy')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<TextField
												margin="normal"
												value={item.individual.first_name || ''}
												onChange={event => setItem({...item, individual:{ ...item.individual, first_name: event.target.value}})}
												label={t('sharedNameFirst')}
												variant="filled" />
											<TextField
												margin="normal"
												value={item.individual.last_name || ''}
												onChange={event => setItem({...item, individual:{ ...item.individual, last_name: event.target.value}})}
												label={t('sharedNameLast')}
												variant="filled" />
											<TextField
												margin="normal"
												value={item.email || ''}
												onChange={event => setItem({...item, email: event.target.value})}
												label={t('userEmail')}
												variant="filled" />
											<TextField
												margin="normal"
												value={item.telephone || ''}
												onChange={event => setItem({...item, telephone: event.target.value})}
												label={t('userTelNum')}
												variant="filled" />
											<CountrySelector
												ItemLabel={t('str_lwhd9du1ab')}
												handleChange={()=>{}}
											/>
											<TextField
												margin="normal"
												value={item.individual.passport_number || ''}
												onChange={event => setItem({...item, individual:{ ...item.individual, passport_number: event.target.value}})}
												label={t('userID')}
												variant="filled" />
											<TextField
												margin="normal"
												value={item.individual.id_number || ''}
												onChange={event => setItem({...item, individual:{ ...item.individual, id_number: event.target.value}})}
												label={t('userPassport')}
												variant="filled" />
										</AccordionDetails>
									</Accordion>
								</>}
								{item.category === 'company' && <>
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('str_j0lqqihgqe')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<TextField
												margin="normal"
												value={item.company.registered_name || ''}
												onChange={event => setItem({...item, company:{ ...item.company, registered_name: event.target.value}})}
												label={t('str_ivk8tq9bih')}
												variant="filled" />
											<TextField
												margin="normal"
												value={item.company.reg_number || ''}
												onChange={event => setItem({...item, company:{ ...item.company, reg_number: event.target.value}})}
												label={t('str_285573olkl')}
												variant="filled" />
											<CountrySelector
												ItemLabel={t('str_lxoe16rryb')}
												handleChange={()=>{}}
											/>
											<TextField
												margin="normal"
												value={item.email || ''}
												onChange={event => setItem({...item, email: event.target.value})}
												label={t('userEmail')}
												variant="filled" />
											<TextField
												margin="normal"
												value={item.telephone || ''}
												onChange={event => setItem({...item, telephone: event.target.value})}
												label={t('userTelNum')}
												variant="filled" />
										</AccordionDetails>
									</Accordion>
								</>}

								{ AppDebugger.isEnabled() &&
									<Accordion>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="subtitle1">
												{t('sharedAttributes')}
											</Typography>
										</AccordionSummary>
										<AccordionDetails className={classes.FormAccordionDetails}>
											<EditAttributesView
												attributes={item.attributes}
												setAttributes={attributes => setItem({...item, attributes})}
												definitions={userAttributes}
												/>
										</AccordionDetails>
									</Accordion>
								}
							</>
						}
					</SubmitForm>
				}
				Child200 = {<SuccessScreen
					buttonText = {t('str_88tl0i755t')}
					buttonPath = {'/lessee/multiview'}
				/>}
				ChildError = {
					<ErrorScreen
						handleClick = {() => SetSubmitStatusCode(null)}
					/>
				}
			/>
	);
}

export default AddNewBody