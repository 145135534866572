// Standard React Components
import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Material UI Components
import {
	Select, MenuItem, InputLabel, FormControl
} from '@material-ui/core';

const PropertySelector = ({ItemLabel, handleChange, preSelectValue, isDisabled}) => {
	const ThisFeatureCollection = useSelector(state => state.MyProperty.FeatureCollection);
	const [SelectedValue, setSelectedValue] = useState(preSelectValue || "");
	return (
		<FormControl
			variant="filled"
			margin="normal"
			fullWidth
		>
			<InputLabel>{ItemLabel}</InputLabel>
			<Select
				disabled={isDisabled}
				value={SelectedValue}
				onChange={event => {
					//setItem({...item, category: event.target.value})
					setSelectedValue(event.target.value)
					handleChange(event.target.value)
				}}
			>
				{ThisFeatureCollection.features.map((item, index) => (
					<MenuItem key={index} value={item.properties.unique_id}>{item.properties.name}</MenuItem>
				))}

			</Select>
		</FormControl>
	)
}

export default PropertySelector