
// Standard React components
import React from 'react';
import { useHistory } from 'react-router-dom';

// Pages
import { PrimaryPageWrapper } from '..'
import AddUserBody from "./AddUserBody";
import EditUserBody from "./EditUserBody";
import ViewAllBody from "./ViewAllBody";

// App Components
import { FloatingAddButton } from '../../Components/Buttons/FloatingButtons';


export const EditUser = () => {
  return (
    <PrimaryPageWrapper>
      <EditUserBody/>
		</PrimaryPageWrapper>
  );
}

export const AddUser = () => {
  return (
    <PrimaryPageWrapper>
      <AddUserBody/>
		</PrimaryPageWrapper>
  );
}

export const ViewAllUsers = () => {
  const history = useHistory();

  const handleAdd = () => {
    history.push('/user/add/');
  };

  return (
    <PrimaryPageWrapper>
      <ViewAllBody/>
      <FloatingAddButton
        handleClick={handleAdd}
      />
		</PrimaryPageWrapper>
  );
}