import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { AppConfigReducer as AppConfig } from './AppConfig';
import { tokenReducer as token } from './token';
import { PlacesSearchReducer as PlacesSearch } from './PlacesSearch';
import { MyPropertyReducer as MyProperty } from './MyProperty';
import { MyGreenZoneReducer as MyGreenZone } from './MyGreenZone';
import { BlueZoneReducer as BlueZone } from './BlueZone';
import { UserConfigReducer as UserConfig } from './UserConfig';
import { GLDrawReducer as GLDraw } from './GLDraw';
import { NavigateReducer as Navigate } from './Navigate';

import { AlphaZoneReducer as AlphaZone } from './AlphaZone';
import { ParentPropsReducer as ParentProps } from './ParentProps';
import { ParentPropsAreasReducer as ParentPropsAreas } from './ParentPropsAreas';

import { ChildPropsReducer as ChildProps } from './ChildProps';
import { ChildPropAreasReducer as ChildPropAreas } from './ChildPropAreas';
import { ChildPropsPointsReducer as ChildPropsPoints } from './ChildPropsPoints';

import { groupsReducer as groups } from './groups';
import { driversReducer as drivers } from './drivers';
import { maintenancesReducer as maintenances } from './maintenances';

const reducer = combineReducers({
  AppConfig,
  token,
  AlphaZone,
  PlacesSearch,
  MyProperty,
  MyGreenZone,
  BlueZone,
  ParentProps,
  ParentPropsAreas,
  ChildProps,
  ChildPropAreas,
  ChildPropsPoints,
  UserConfig,
  GLDraw,
  Navigate,
  groups,
  drivers,
  maintenances,
});

export { AppConfigActions } from './AppConfig';
export { tokenActions } from './token';
export { GLDrawActions } from './GLDraw';
export { NavigateActions } from './Navigate';

export { PlacesSearchActions } from './PlacesSearch';
export { MyPropertyActions } from './MyProperty';
export { MyGreenZoneActions } from './MyGreenZone';
export { BlueZoneActions } from './BlueZone';
export { AlphaZoneActions } from './AlphaZone';

export { ParentPropsActions } from './ParentProps';
export { ParentPropsAreasActions } from './ParentPropsAreas';
export { ChildPropsActions } from './ChildProps';
export { ChildPropAreasActions } from './ChildPropAreas';
export { ChildPropsPointsActions } from './ChildPropsPoints';

export { UserConfigActions } from './UserConfig';
export { groupsActions } from './groups';
export { driversActions } from './drivers';
export { maintenancesActions } from './maintenances';

export default configureStore({ reducer });
