import {  useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import {
	MyPropertyActions, MyGreenZoneActions, BlueZoneActions, AlphaZoneActions
} from '../ReduxStore';
import { useEffectAsync } from '../reactHelper';
import { AppDebugger, MyAPIFetcher } from '../App'

const CachingController = () => {
	const authenticated  = useSelector(state => !!state.token.access_token);
	const dispatch = useDispatch();

	const doUpdate_MyProperty = useSelector(state => state.MyProperty.updateRequestTimestamp);
	useEffectAsync(async () => {
		if (authenticated){
			AppDebugger.log('Store Updated: MyProperty');
			dispatch(MyPropertyActions.UpdateStatusCode(100));
			const response = await MyAPIFetcher.GetAPI('prop/me/geojson');
			if (response !== null) {
				dispatch(MyPropertyActions.refresh(response));
				dispatch(MyPropertyActions.UpdateStatusCode(200));
			} else {
				AppDebugger.log('Failed to get my prop GeoJSON')
				dispatch(MyPropertyActions.UpdateStatusCode(400));
			}
		}
	}, [authenticated,doUpdate_MyProperty]);

	const doUpdate_MyGreenZone = useSelector(state => state.MyGreenZone.updateRequestTimestamp);
	useEffectAsync(async () => {
		if (authenticated){
			AppDebugger.log('Store Updated: MyGreenZone');
			dispatch(MyGreenZoneActions.UpdateStatusCode(100));
			const response = await MyAPIFetcher.GetAPI('green/me/geojson');
			if (response !== null) {
				dispatch(MyGreenZoneActions.refresh(response));
				dispatch(MyGreenZoneActions.UpdateStatusCode(200));
			} else {
				AppDebugger.log('Failed to get my green zone GeoJSON')
				dispatch(MyGreenZoneActions.UpdateStatusCode(400));
			}
		}
	}, [authenticated,doUpdate_MyGreenZone]);

	const doUpdate_BlueZone = useSelector(state => state.BlueZone.updateRequestTimestamp);
	useEffectAsync(async () => {
		if (authenticated){
			AppDebugger.log('Store Updated: BlueZone');
			dispatch(BlueZoneActions.UpdateStatusCode(100));
			const response = await MyAPIFetcher.get('blue/me/geojson');
			if (response[0]) {
				dispatch(BlueZoneActions.refresh(MyAPIFetcher.GetPayloadFromResp(response[1])));
				dispatch(BlueZoneActions.UpdateStatusCode(200));
			} else {
				AppDebugger.log('Failed to get my green zone GeoJSON')
				dispatch(BlueZoneActions.UpdateStatusCode(400));
			}
		}
	}, [authenticated,doUpdate_BlueZone]);

	const doUpdate_AlphaZone = useSelector(state => state.AlphaZone.updateRequestTimestamp);
	useEffectAsync(async () => {
		if (authenticated){
			AppDebugger.log('Store Updated: AlphaZone');
			dispatch(AlphaZoneActions.UpdateStatusCode(100));
			const response = await MyAPIFetcher.GetAPI('alpha/all/geojson');
			if (response !== null) {
				dispatch(AlphaZoneActions.refresh(response));
				dispatch(AlphaZoneActions.UpdateStatusCode(200));
			} else {
				AppDebugger.log('Failed to get Alpha GeoJSON')
				dispatch(AlphaZoneActions.UpdateStatusCode(400));
			}
		}
	}, [authenticated,doUpdate_AlphaZone]);

	return null;
}

export default connect()(CachingController);
