// Standard React components
import { useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Map Components
import { map } from '../Map';
import { Fly2Extend } from '../mapUtil';


const PlacesSearchMap = ({enabled, Fly2Selected}) => {
	const ThisFeatureCollection = useSelector(state => state.PlacesSearch.FeatureCollection);
	const selectedId = useSelector(state => state.PlacesSearch.selectedId);
	const id = 'PlacesSearch';

	useEffect(() => {
		if (map && selectedId !== null){
			if (ThisFeatureCollection.features.length > 0){
				const bbox = Fly2Extend(ThisFeatureCollection.features[selectedId])
				//let coordinates = Fly2Point(ThisFeatureCollection.features[selectedId])
				if (bbox && Fly2Selected) { map.fitBounds(bbox, {padding: 20}); }
				//if (bbox && Fly2Selected) { map.flyTo({ center: [SelectedPoint[0], SelectedPoint[1]], zoom: 12 }); }
			}
		}
	}, [ThisFeatureCollection,selectedId,Fly2Selected]);

	useEffect(() => {
			map.addSource(id, {
			'type': 'geojson',
			'data': {
				type: 'FeatureCollection',
				features: []
			}
		});
		map.addLayer({
			'source': id,
			'id': id + '-fill',
			'type': 'fill',
			'filter': [
				'all',
				['==', '$type', 'Polygon'],
			],
			'paint': {
				'fill-color':'#66D03B',
				'fill-outline-color':'#66D03B',
				'fill-opacity':0.1,
			},
		});
		map.addLayer({
			'source': id,
			'id': id + '-line',
			'type': 'line',
			'paint': {
				'line-color': '#66D03B',
				'line-width': 2,
			},
		});
		map.addLayer({
			'source': id,
			'id': id + '-point',
			'type': 'circle',
			'paint': {
				'circle-radius': 6,
				'circle-color': '#B42222'
			},
			'filter': ['==', '$type', 'Point']
		});

		return () => {
			if (map.getLayer(id + '-fill')) { map.removeLayer(id + '-fill');}
			if (map.getLayer(id + '-line')) { map.removeLayer(id + '-line');}
			if (map.getLayer(id + '-point')) { map.removeLayer(id + '-point');}

			if (map.getSource(id)) { map.removeSource(id); }
		};
	}, []);

	useEffect(() => {
		//console.log(map.getStyle().layers)
		map.getSource(id).setData({
			type: 'FeatureCollection',
			features: ThisFeatureCollection.features
		});
	}, [ThisFeatureCollection]);

	useEffect(() => {
		const IDs = [
			id + '-fill',
			id + '-line',
			id + '-point'
		]
		for (var i in IDs) {
			if (map.getLayer(IDs[i])){
				if (enabled) {
					map.setLayoutProperty(
						IDs[i],
						"visibility",
						"visible"
					)
				} else {
					map.setLayoutProperty(
						IDs[i],
						"visibility",
						"none"
					)
				}
			}
		}
	}, [enabled]);

  return null;
}

export default PlacesSearchMap;
