import {
	makeStyles
} from '@material-ui/core';

export const useAppStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		//height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	PageContent: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow:'auto'
	},
	mapContainer: {
		flexGrow: 1,
	},
	sidebar: {
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		left: 0,
		top: 60,
		margin: theme.spacing(1.5),
		width: theme.dimensions.drawerWidthDesktop,
		bottom: theme.spacing(2),
		zIndex: 1101,
		transition: 'transform .5s ease',
		backgroundColor: 'white',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			margin: 0,
		},
	},
	sidebarInner_bottom :{
		bottom: 0,
		left: '0px',
		width: '100%',
		position: 'absolute',
	},
	center :{
		margin: 'auto',
		width: '50%',
	},
	sidebarCollapsed: {
		transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
		marginLeft: 0,
		[theme.breakpoints.down('md')]: {
			transform: 'translateX(-100vw)',
		},
	},
	paper: {
		zIndex: 1,
	},
	toolbar: {
		display: 'flex',
		padding: theme.spacing(0, 1),
		'& > *': {
			margin: theme.spacing(0, 1),
		},
	},
	ItemsList: {
		flex: 1,
		maxHeight: '60vh',
		overflowY: 'auto'
	},
	navItem: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '0.75rem',
		fontWeight: 'normal',
	},
	sidebarToggleContainer: {
		position: 'absolute',
		left: theme.spacing(1),
		top: theme.spacing(6),
		borderRadius: '0px',
		minWidth: 0,
		[theme.breakpoints.down('md')]: {
			left: 0,
		},
	},
	sidebarToggle: {
		position: 'relative',
		left: theme.spacing(1.5),
		top: theme.spacing(3),
		borderRadius: '0px',
		minWidth: 0,
		[theme.breakpoints.down('md')]: {
			left: 0,
		},
	},
	sidebarToggleText: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.only('xs')]: {
			display: 'none',
		},
	},
	sidebarToggleBg: {
		backgroundColor: 'white',
		color: '#777777',
		'&:hover': {
			backgroundColor: 'white',
		},
	},
	list: {
    maxHeight: '100%',
    overflow: 'auto',
  },
	listMessageDiv:{
		margin: 20
	},
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
	controlPanel: {
		position: 'absolute',
		bottom: theme.spacing(3),
		left: '50%',
		transform: 'translateX(-50%)',
		backgroundColor: 'primary'
  },
  controlContent: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
  },
  configForm: {
		display: 'flex',
		flexDirection: 'column',
  },
	FormAccordionDetails : {
		flexDirection: 'column',
	},
	PaperContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	PaperInner: {
		paddingTop: "10px",
		paddingBottom: "20px",
		paddingLeft: "15px",
		paddingRight: "15px",
		textAlign: 'center'
	},
	FloatingButtonBack: {
		zIndex: 999,
		position: 'absolute',
		top: "40px",
		right: "30px"
	},
	FloatingButtonAdd: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
