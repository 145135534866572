// Standard React
import React from "react";
//import { useTimer } from 'react-timer-hook';

// Redux
import { useSelector } from "react-redux";
import { NewPaper, NewPaperInner } from "../../Components/PageContent/Shared";

// App components
import StatusCodeSwitcher from "../../Components/PageContent/StatusCodeSwitcher";

export const AppLoading = () => {
	const StatusCode = useSelector(state => state.AppConfig.status_code);

	return (
		<StatusCodeSwitcher
			StatusCode={StatusCode}
			Child200={
				<NewPaper>
					<NewPaperInner>
						<p>Starting app</p>
					</NewPaperInner>
				</NewPaper>
			}
			Child500={
				<NewPaper>
					<NewPaperInner>
						<h3>Maintenance in progress</h3>
						<p>App is currently unavailable, please try again later</p>
					</NewPaperInner>
				</NewPaper>
			}
			ChildError={
				<NewPaper>
					<NewPaperInner>
						<p>App failed to load, please check internet connection and reload the app</p>
					</NewPaperInner>
				</NewPaper>
			}
		/>
	)
}