import { useDispatch, useSelector } from 'react-redux';
import { PlacesSearchActions } from '../ReduxStore';
import { useEffectAsync } from '../reactHelper';
import {AppDebugger} from '../App';

// eslint-disable-next-line no-unused-vars
const SearchController = () => {

	const dispatch = useDispatch();
	const search_query  = useSelector(state => state.PlacesSearch.search_query);

	useEffectAsync(async () => {
		if (search_query !== null){
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(
					{
					"srs": "EPSG:4326",
					"ReturnGeometry": true,
					"OutputFormat": "GeoJSON",
					"AcceptFeatureTypes": [],
					"Address": search_query,
					"PostalCode": "",
					"CountryCode": "ZA",
					"ProviderList": [
						"nominatim",
						"arcgis"
					]
					}
				)
			};
			const url = process.env.REACT_APP_JC2GIS_URL + 'geocode/multi/';
			dispatch(PlacesSearchActions.UpdateStatusCode(100));
			fetch(url, requestOptions)
				.then(async response => {
					const isJson = response.headers.get('content-type')?.includes('application/json');
					const data = isJson && await response.json();

					// check for error response
					if (!response.ok) {
						// get error message from body or default to response status
						const error = (data && data.message) || response.status;
						return Promise.reject(error);
					}
					dispatch(PlacesSearchActions.refresh(data));
					dispatch(PlacesSearchActions.UpdateStatusCode(200));
				})
				.catch(error => {
					AppDebugger.log('Failed to get search results, \nError message: \n' + error.toString());
					dispatch(PlacesSearchActions.UpdateStatusCode(400));
				});
		} else {
			dispatch(PlacesSearchActions.ClearAll());
		}
	}, [search_query]);



	return null;
}

export default SearchController;
