const etitleWhite = '#FFF';
const etitlePurple = '#333366';
const etitleGreen = '#4CAF50';
const etitleRed = '#CC2222';
const etitleGray = '#888888';

export default {
  common: {
    purple: etitlePurple,
    green: etitleGreen,
    red: etitleRed,
    gray: etitleGray,
  },
  primary: {
    main: etitlePurple,
    contrastText: etitleWhite,
  },
  secondary: {
    main: etitleGreen,
    contrastText: etitleWhite,
  },
};
