// Standard React components
import React from 'react';
import { useHistory } from 'react-router-dom';

// App Components
import { FloatingAddButton } from '../../Components/Buttons/FloatingButtons';

// Pages
import { PrimaryPageWrapper } from '../'
import UsersView from "./UsersPage";
import EditCollectionView from '../../Legacy/EditCollectionView';

export const UsersPageBody = () => {
  const history = useHistory();

  const handleAdd = () => {
    history.push('/user/add/');
  };

  return (
    <PrimaryPageWrapper>
      <EditCollectionView content={UsersView} editPath="user/edit" endpoint="users/manage" />
      <FloatingAddButton
        handleClick={handleAdd}
      />
		</PrimaryPageWrapper>
  );
}