import { useState, useEffect } from 'react';
import t from '../../Libs/localization'
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { MyAPIFetcher, AppDebugger } from '../../App';

const RemoveDialog = ({ open, endpoint, itemId, onResult }) => {
	const [ReqFailed, setReqFailed] = useState(null)
	const [ReqFailedMsg, setReqFailedMsg] = useState(t('sharedRemoveFailed'))

	const handleRemove = async () => {
		const response = await MyAPIFetcher.delete(`${endpoint}/${itemId}`, { method: 'DELETE' });
		if (response.data.success) {
			onResult(true);
		} else {
			AppDebugger.log("RemoveDialog failed for endpoint: " + endpoint + " and itemId: " + itemId);
			console.log(response.status)
			response.status === 401 && setReqFailedMsg( t('statusCodes401') + ". " + response.data.detail );
			response.status === 403 && setReqFailedMsg( t('statusCodes403') + ". " + response.data.detail );
			setReqFailed(true);
		}
	};

	useEffect(() => {
		open && setReqFailed(false);
	}, [open])

	return (
		<Dialog
			open={open}
			onClose={() => { onResult(false)}}>
			{ReqFailed ? <>
				<DialogContent>
					<DialogContentText>{ReqFailedMsg}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus
						onClick={() => { onResult(false) }}
					>
						{t('sharedBack')}
					</Button>
				</DialogActions>
			</> : <>
				<DialogContent>
					<DialogContentText>{t('sharedRemoveConfirm')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleRemove}>{t('sharedRemove')}</Button>
					<Button autoFocus onClick={() => onResult(false)}>{t('sharedCancel')}</Button>
				</DialogActions>
			</>}
		</Dialog>
	);
};

export default RemoveDialog;
